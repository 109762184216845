import { JSONSchema } from '@apidevtools/json-schema-ref-parser';
import { Reducer } from 'react';
import { Property, DataObject } from '../types';
import { createObject, createProperty, createValidationRule } from '../utils';
import validationParse from '../utilsValidation';

export type Action =
    | { type: 'SET_VALIDATION_SCHEMA'; payload: any }
    | { type: 'PARSE_SCHEMA_TO_RULES'; payload?: any }
    | { type: 'PARSE_RULES_TO_SCHEMA'; payload?: never };

export type State = {
    objects: DataObject[];
    properties: Property[];
    validationSchema: JSONSchema;
    schema: any;
    lastValidSchema: any;
};

export const initialState: State = {
    objects: [],
    properties: [],
    validationSchema: {},
    schema: {},
    lastValidSchema: {},
};

const parseRequiredPropertiesToRules = (schema: any, lowercaseProperties: string[], state: any): State => {
    const newState = { ...state };
    schema.required.forEach((requiredField) => {
        if (!lowercaseProperties.includes(requiredField.toLowerCase())) {
            const newProperty = createProperty({
                propertyValue: requiredField,
                expand: false,
            });

            newProperty.validationRules = [
                createValidationRule({
                    propertyKey: newProperty.key,
                    validationRuleKey: 'required',
                    validationRuleValue: 'yes',
                }),
            ];
            newState.properties = [...newState.properties, newProperty];
        }
    });

    return newState;
};

const parseSchemaToRules = (state, action) => {
    let newState = {
        properties: [],
        objects: [],
        lastValidSchema: {},
    };

    const schema = action.payload;
    if (!schema.properties) {
        return {
            ...state,
            ...newState,
        };
    }
    newState.lastValidSchema = schema;
    const lowercaseProperties = Object.keys(schema.properties).map((property) => property.toLowerCase());

    if (schema.properties) {
        Object.keys(schema.properties).forEach((p) => {
            if (p === '') return;
            const newProperty = createProperty({
                propertyValue: p,
                expand: false,
            });

            newState.properties = [...newState.properties, newProperty];

            const currentSchemaProperty = schema.properties[p];

            if (currentSchemaProperty.type === 'object' && currentSchemaProperty.properties) {
                const newObject = createObject({
                    objectName: `${p} Object`,
                });
                newState.objects = [...newState.objects, newObject];
                newProperty.validationRules = [
                    createValidationRule({
                        propertyKey: newProperty.key,
                        validationRuleKey: 'nestedObject',
                        validationRuleValue: newObject.key,
                    }),
                ];

                Object.entries(currentSchemaProperty).forEach(([field, value]) => {
                    if (field === 'properties') {
                        Object.entries(value).forEach(([propKey, propValue]) => {
                            const fieldProperty = createProperty({
                                objectKey: newObject.key,
                                propertyValue: propKey,
                            });

                            newObject.properties = [...newObject.properties, fieldProperty];

                            Object.entries(propValue).forEach(([propFieldKey, propFieldValue]: [string, string]) => {
                                if (propFieldKey === 'description') {
                                    fieldProperty.description = propFieldValue;
                                } else {
                                    fieldProperty.validationRules = [
                                        ...fieldProperty.validationRules,
                                        createValidationRule({
                                            objectKey: newObject.key,
                                            propertyKey: fieldProperty.key,
                                            validationRuleKey: propFieldKey,
                                            validationRuleValue: propFieldValue,
                                        }),
                                    ];
                                }
                            });
                        });
                    }

                    if (field === 'required' && Array.isArray(value)) {
                        value.forEach((prop) => {
                            const currentFieldProp = newObject.properties.find((o) => o.propertyValue === prop);

                            currentFieldProp.validationRules = [
                                ...currentFieldProp.validationRules,
                                createValidationRule({
                                    objectKey: newObject.key,
                                    propertyKey: currentFieldProp.key,
                                    validationRuleKey: 'required',
                                    validationRuleValue: 'yes',
                                }),
                            ];
                        });
                    }
                });

                return;
            }

            Object.keys(schema.properties[p]).forEach((vr) => {
                if (vr === 'description') {
                    newProperty.description = schema.properties[p][vr];
                } else if (vr === 'contains') {
                    newProperty.validationRules = [
                        ...newProperty.validationRules,
                        createValidationRule({
                            propertyKey: newProperty.key,
                            validationRuleKey: vr,
                            validationRuleValue: JSON.stringify(schema.properties[p][vr]),
                        }),
                    ];
                } else {
                    newProperty.validationRules = [
                        ...newProperty.validationRules,
                        createValidationRule({
                            propertyKey: newProperty.key,
                            validationRuleKey: vr,
                            validationRuleValue: schema.properties[p][vr] === null ? 'null' : schema.properties[p][vr],
                        }),
                    ];
                }
            });

            if (schema.required?.includes(p)) {
                newProperty.validationRules = [
                    ...newProperty.validationRules,
                    createValidationRule({
                        propertyKey: newProperty.key,
                        validationRuleKey: 'required',
                        validationRuleValue: 'yes',
                    }),
                ];
            }
        });
    }

    if (schema.required) {
        newState = parseRequiredPropertiesToRules(schema, lowercaseProperties, newState);
    }

    return {
        ...state,
        ...newState,
    };
};

const parseRulesToSchema = (state) => {
    const parse = ({ properties }) => {
        const required = [];
        let props = {};
        properties.forEach((p: Property) => {
            if (p.propertyValue === '') return;
            let prop = Object.hasOwnProperty.call(props, p.propertyValue) ? props[p.propertyValue] : {};

            if (p.validationRules) {
                p.validationRules.forEach((v) => {
                    if (v.validationRuleKey === 'required') {
                        if (v.validationRuleValue === 'yes') {
                            required.push(p.propertyValue);
                        }
                    } else if (['nestedObject', 'array', 'enum', 'uniqueItems'].includes(v.validationRuleKey)) {
                        const object = state.objects.find((o: DataObject) => v.validationRuleValue === o.key);
                        prop = {
                            ...prop,
                            ...validationParse({
                                parse,
                                object,
                                property: prop,
                                validationRule: v,
                            })[v.validationRuleKey](),
                        };
                    } else if (v.validationRuleKey === 'examples') {
                        prop = {
                            ...prop,
                            [v.validationRuleKey]: Array.isArray(v.validationRuleValue)
                                ? v.validationRuleValue
                                : [v.validationRuleValue],
                        };
                    } else {
                        prop = {
                            ...prop,
                            [v.validationRuleKey]:
                                v.validationRuleValue === '' || Number.isNaN(Number(v.validationRuleValue))
                                    ? v.validationRuleValue
                                    : Number(v.validationRuleValue),
                        };
                    }
                });
            }

            if (p.description) {
                prop = {
                    ...prop,
                    description: p.description,
                };
            }

            props = {
                ...props,
                [p.propertyValue]: prop,
            };
        });

        return {
            properties: props,
            required,
        };
    };

    const schema = parse({ properties: state.properties });

    return {
        ...state,
        schema,
        lastValidSchema: schema,
    };
};

export const reducer: Reducer<State, Action> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VALIDATION_SCHEMA':
            return {
                ...state,
                validationSchema: action.payload,
            };
        case 'PARSE_SCHEMA_TO_RULES': {
            return parseSchemaToRules(state, action);
        }
        case 'PARSE_RULES_TO_SCHEMA': {
            return parseRulesToSchema(state);
        }
        default:
            return state;
    }
};
