import { modal } from './state/actions';

export enum ActionType {
    StreamMenu = 'streamMenu',
    Toolbar = 'toolbar',
}

export type ActionProps = {
    type?: ActionType;
};

export type ModalPayload = (typeof modal.actions)[number];

export enum SetParametersType {
    IMPORT = 'import',
    REPLACE = 'replace',
    UPDATE = 'update',
}
