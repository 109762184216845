import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Node } from '../../../../../dazzler-types';

const USAGE_ENUM = 'usage';
const USAGE_ACTIVE_RATING_ENUM = 'usageAr';

type Props = {
    node: Node;
    onUpdate: (Node: Node) => void;
};

function ZuoraRequestItem(props: Props) {
    const { node, onUpdate } = props;
    const endpoint = node.params.zuoraApiEndpoint ?? USAGE_ACTIVE_RATING_ENUM;

    const setEndpoint = (e: CheckboxChangeEvent) => {
        const { checked } = e.target;
        onUpdate({
            ...node,
            params: {
                ...node.params,
                zuoraApiEndpoint: checked ? USAGE_ACTIVE_RATING_ENUM : USAGE_ENUM,
            },
        });
    };

    return (
        <fieldset>
            <legend>
                <FormattedMessage id="function_config.generic.zuora.request_legend" />
            </legend>
            <div>
                <Form.Item>
                    <Checkbox
                        data-testid="zuora_req_item_checkbox"
                        onChange={setEndpoint}
                        checked={endpoint === USAGE_ACTIVE_RATING_ENUM}
                    >
                        <FormattedMessage id="function_config.generic.zuora.endpoint_label" />
                    </Checkbox>
                </Form.Item>
            </div>
        </fieldset>
    );
}

export default ZuoraRequestItem;
