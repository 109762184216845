import React, { MouseEventHandler } from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../resources';

const styles = (theme) => ({
    toolbarActionIcon: {
        backgroundColor: 'white',
        margin: 0,
        padding: '2px 8px 0px 8px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.brand02,
        color: theme.neutral04,
        borderRadius: 50,
        alignItems: 'center',
        '&button': {
            width: 32,
            height: 32,
            boxShadow: 'unset',
        },
        '&:disabled, &:disabled:hover': {
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
        '&:disabled *, &:disabled:hover *': {
            color: theme.drFontColorA04,
        },
        '&:hover *': {
            color: theme.brand05,
            borderColor: theme.brand05,
        },
    },
});

const useStyles = createUseStyles(styles);

type Props = {
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const RoundRefreshButton = (props: Props) => {
    const classes = useStyles();

    return (
        <Tooltip placement="top" title={<FormattedMessage id="logs.actionbar.refresh.label" />} arrowPointAtCenter>
            <Button className={classes.toolbarActionIcon} id="refresh-button" {...props}>
                <FontAwesomeIcon icon={icons.regular.faSyncAlt} />
            </Button>
        </Tooltip>
    );
};

export default RoundRefreshButton;
