import React from 'react';
import { createUseStyles } from 'react-jss';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { ValidationRule } from '../types';
import ValidationSelectObject from './ValidationSelectObject';
import ValidationSelectProperty from './ValidationSelectProperty';
import InputField from '../common/InputField';
import { useValidatorState } from '../context';

const styles = (theme) => ({
    validationRuleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: theme.marginXs,
    },
    validationRuleKey: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flex: 1,
        marginRight: theme.marginXs,
    },
    validationRuleValue: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flex: 3,
        marginRight: theme.marginXs,
    },
    dynamicDeleteButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'relative',
        top: 4,
        fontSize: theme.fontSizeLg,
        color: theme.neutral05,
        transition: 'all 300ms',
        marginLeft: theme.paddingXxs,
    },
    dynamicDeleteButtonFirstElementOnly: {
        top: theme.marginMd,
    },
});

const useStyles = createUseStyles(styles);

type Props = {
    isObject: boolean;
    isFirst: boolean;
    rule: ValidationRule;
    disabled?: boolean;
};

const ValidationRules = ({ rule, isFirst, isObject = false, disabled = false }: Props) => {
    const { dispatch } = useValidatorState();
    const classes = useStyles();
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `function_config.transforms.validator.${id}` });

    const deleteValidationRule = () => {
        dispatch({
            type: isObject ? 'DELETE_OBJECT_VALIDATION' : 'DELETE_PROPERTY_VALIDATION',
            payload: rule,
        });
    };

    return (
        <div className={classes.validationRuleWrapper}>
            <div className={classes.validationRuleKey}>
                {isFirst && `${i18n('key')}:`}
                {isObject ? (
                    <ValidationSelectObject rule={rule} disabled={disabled} />
                ) : (
                    <ValidationSelectProperty rule={rule} disabled={disabled} />
                )}
            </div>
            <div className={classes.validationRuleValue}>
                {isFirst && `${i18n('value')}:`}
                <InputField rule={rule} disabled={disabled} />
            </div>
            <div
                className={`${classes.dynamicDeleteButton} ${
                    isFirst ? classes.dynamicDeleteButtonFirstElementOnly : ''
                }`}
            >
                {!disabled && <MinusCircleOutlined onClick={deleteValidationRule} />}
            </div>
        </div>
    );
};

export default ValidationRules;
