import React from 'react';
import { Form, Divider } from '@digitalroute-internal/dazzlerjs-react-ui';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { isFeatureEnabled } from '../../../../../feature-toggles';
import { getNodeInputFieldsTreeData, StreamsPreviewState } from '../../../../../streams';
import ZuoraUsageRecordParameters from '../../parameters/writers/ZuoraUsageRecordParameters';
import ZuoraUsageRecordRequestField from '../../common/zuora/ZuoraUsageRecordRequestField';
import ZuoraUsageRecordRequiredField from '../../common/zuora/ZuoraUsageRecordRequiredField';
import ZuoraUsageRecordOptionalField from '../../common/zuora/ZuoraUsageRecordOptionalField';
import ZuoraUsageRecordOperationalSetting from '../../common/zuora/ZuoraUsageRecordOperationalSettings';
import ForwarderInterconnectSetting from '../../common/ForwarderInterconnectSetting';
import { ZuoraActiveRatingEnum } from '../../common/zuora/ZuoraEnums';
import type { ConfiguratorProps, FeatureToggleFn } from '../../../../../dazzler-types';

const I18N_PREFIX = 'function_config.writer.zuora_usage_record';

const styles = (theme) => ({
    bannerContainer: {
        paddingTop: theme.paddingXs,
        paddingLeft: theme.paddingXs,
        paddingBottom: theme.paddingXs,
        fontSize: theme.fontSizeLg,
    },
    formMargin: {
        marginLeft: theme.paddingXs,
        '& a': {
            textDecoration: 'underline',
        },
    },
    dividerOverride: {
        marginLeft: theme.paddingXs,
        marginTop: '16px !important',
        marginBottom: '16px !important',
    },
    legendMargin: {
        marginTop: theme.paddingMd,
        marginBottom: `${theme.paddingXs} !important`,
        marginleft: `${theme.paddingMd} !important`,
    },
    bottomFieldPadding: {
        paddingBottom: theme.paddingXs,
    },
    optionalFieldButtonPadding: {
        marginTop: theme.paddingXs,
        paddingBottom: theme.paddingXs,
        marginLeft: theme.paddingXs,
    },
    checkbox: {
        margin: `${theme.paddingXs} !important`,
        width: 'fit-content',
    },
    fontSizeContainer: {
        '& span': {
            fontSize: theme.fontSizeLg,
        },
        '& label': {
            fontSize: theme.fontSizeLg,
        },
        fontSize: theme.fontSizeLg,
    },
    retryContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .ant-form-item': {
            marginRight: 0,
            marginBottom: 0,
        },
    },
    retryDescription: {
        maxWidth: 340,
        marginLeft: theme.marginXs,
        color: theme.neutral04,
        fontSize: `${theme.fontSizeBase} !important`,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        '& label': {
            fontSize: theme.fontSizeLg,
        },
    },
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
    rightElement: {
        marginRight: theme.marginXs,
    },
    radioGroupContainer: {
        '& > div': {
            padding: 0,
        },
    },
});

const PATH_MAPPING = {
    [ZuoraActiveRatingEnum.DISABLED]: {
        properties: 'params.definitions.usageMappings.properties',
        required: 'params.definitions.usageMappings.required',
        fieldMappings: 'params.usageFieldMappings',
    },
    [ZuoraActiveRatingEnum.ENABLED]: {
        properties: 'params.definitions.usageArMappings.properties',
        required: 'params.definitions.usageArMappings.required',
        fieldMappings: 'params.usageArFieldMappings',
    },
};

const useStyles = createUseStyles(styles);

const ZuoraUsageRecordConfigurator = ({ functionType, stream, node, onUpdate }: ConfiguratorProps): JSX.Element => {
    const isFeatureToggleEnabled: FeatureToggleFn = useSelector(isFeatureEnabled);
    const isVersionControlPostPhase2Enabled: boolean = isFeatureToggleEnabled('versionControlPostPhase2');
    const sourceFields = useSelector((state: StreamsPreviewState) => getNodeInputFieldsTreeData(state, node, stream));
    const classes = useStyles();

    const intl = useIntl();
    const i18n = (id: string, value?: any) =>
        value
            ? intl.formatMessage({ id: `${I18N_PREFIX}.${id}` }, value)
            : intl.formatMessage({ id: `${I18N_PREFIX}.${id}` });

    const findValueInTreeData = (valueToFind: string, treeData): boolean => {
        const result = treeData.find((el) => {
            let foundInTreeData = el.value === valueToFind;
            if (!foundInTreeData && el.children.length >= 1) {
                foundInTreeData = findValueInTreeData(valueToFind, el.children);
            }
            return foundInTreeData;
        });
        return result !== undefined && Object.keys(result).length >= 1;
    };
    const addAddonDataTreeData = (valueToFind: string, treeData) => {
        const foundInTreeData = valueToFind === undefined ? true : findValueInTreeData(valueToFind, treeData);
        return foundInTreeData
            ? treeData
            : [
                  ...treeData,
                  {
                      children: [],
                      key: valueToFind,
                      title: valueToFind,
                      value: valueToFind,
                  },
              ];
    };

    return (
        <>
            {!isVersionControlPostPhase2Enabled && (
                <>
                    <ZuoraUsageRecordParameters functionType={functionType} node={node} onUpdate={onUpdate} />
                    <Divider overrideClass={classes.dividerOverride} />
                </>
            )}
            <Form layout="vertical">
                <ZuoraUsageRecordRequestField node={node} classes={classes} i18n={i18n} onUpdate={onUpdate} />
                <ZuoraUsageRecordRequiredField
                    functionType={functionType}
                    node={node}
                    onUpdate={onUpdate}
                    treeNodeData={sourceFields}
                    classes={classes}
                    i18n={i18n}
                    addAddonDataTreeData={addAddonDataTreeData}
                    pathMapping={PATH_MAPPING}
                />
                <ZuoraUsageRecordOptionalField
                    functionType={functionType}
                    node={node}
                    onUpdate={onUpdate}
                    treeNodeData={sourceFields}
                    classes={classes}
                    i18n={i18n}
                    addAddonDataTreeData={addAddonDataTreeData}
                    pathMapping={PATH_MAPPING}
                />
                <ZuoraUsageRecordOperationalSetting node={node} onUpdate={onUpdate} i18n={i18n} classes={classes} />
                <div className={classes.formMargin}>
                    <ForwarderInterconnectSetting
                        node={node}
                        onUpdate={onUpdate}
                        stream={stream}
                        defaultOn
                        dataTestIdPrefix="ZuoraUsageRecordInterconnectSetting"
                    />
                </div>
            </Form>
        </>
    );
};

export default ZuoraUsageRecordConfigurator;
