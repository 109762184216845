const getHelpText = (help, label) => {
    if (help) {
        return help;
    }
    if (label) {
        return `${label} is required`;
    }
    return undefined;
};

export default getHelpText;
