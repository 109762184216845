/* eslint-disable no-nested-ternary */
import { DEFAULT_COLUMNS } from '../dazzler-constants';
import type { Stream, ColumnsOrderState, CustomAuditColumnsOrder } from '../dazzler-types';

type UpdateProps = {
    state: ColumnsOrderState;
    streamId: Stream['id'];
    column: string;
    newOrder: number;
};

type FetchProps = {
    columnsOrder: CustomAuditColumnsOrder;
    lastOrder: number;
    meta: {
        streamId: Stream['id'];
    };
};

export const updateOrderReducer =
    ({ state, streamId, column, newOrder }: UpdateProps) =>
    (result: ColumnsOrderState, [currentColumnName, currentColumnOrder]: [string, number]): ColumnsOrderState => {
        if (column !== currentColumnName) {
            const isMovingBackwards = currentColumnOrder <= newOrder && currentColumnOrder > state[streamId][column];
            const isMovingForward = currentColumnOrder >= newOrder && currentColumnOrder < state[streamId][column];
            const adjustCurrentOrder = isMovingBackwards ? -1 : isMovingForward ? 1 : 0;

            return {
                ...result,
                [streamId]: {
                    ...result[streamId],
                    [currentColumnName]: currentColumnOrder + adjustCurrentOrder,
                },
            };
        }

        return {
            ...result,
            [streamId]: {
                ...result[streamId],
                [column]: newOrder,
            },
        };
    };

export const fetchColumnsReducer =
    ({ columnsOrder, lastOrder, meta }: FetchProps) =>
    (result: ColumnsOrderState, column: string, index: number): ColumnsOrderState => {
        const defaultColumn = DEFAULT_COLUMNS.some((col) => col.name === column);

        if (defaultColumn) {
            return {
                ...result,
                [meta.streamId]: {
                    ...columnsOrder,
                    ...result[meta.streamId],
                },
            };
        }
        if (!result[meta.streamId]?.[column] && !defaultColumn) {
            return {
                ...result,
                [meta.streamId]: {
                    ...columnsOrder,
                    [column]: index + lastOrder + 1,
                    ...result[meta.streamId],
                },
            };
        }

        return result;
    };
