import React, { ComponentType } from 'react';
import { compose } from 'redux';
import { WithStylesProps } from 'react-jss';
import { CloseCircleFilled } from '@ant-design/icons';
import { Input } from 'antd';
import _ from 'lodash';
import { withStyles } from '@digitalroute-internal/dazzlerjs-react-ui';
import { withReadOnly, ReadOnlyModeProps } from '../../../../stream-editor/hooks';
import type { Node } from '../../../../dazzler-types';

const styles = () => ({
    inputPasswordSuffix: {
        cursor: 'pointer !important',
    },
    inputPasswordSuffixNotAllowed: {
        cursor: 'not-allowed !important',
    },
});

type PublicProps = {
    clearTooltip?: string;
    multiline?: boolean;
    node: Node;
    onUpdate: (node: Node) => void;
    path: string;
    placeholder?: string;
    hideKeyReset?: boolean;
};

type Props = PublicProps & ReadOnlyModeProps & WithStylesProps<typeof styles>;

type State = {
    showInput: boolean;
    editBuffer: string;
};

class SecretInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { node, path } = this.props;

        const secret = _.get(node.params, path);

        if (!secret) {
            this.state = { showInput: true, editBuffer: '' };
        } else {
            this.state = { showInput: false, editBuffer: '' };
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.hideKeyReset && nextProps.showInput !== prevState.showInput) {
            return {
                showInput: nextProps.showInput,
                editBuffer: '',
            };
        }

        return null;
    }

    updateSecret = () => {
        const { node, onUpdate, path } = this.props;
        const { editBuffer } = this.state;

        if (editBuffer === '') {
            _.unset(node.params, path);
        } else {
            const secret = {
                secret: true,
                value: editBuffer,
            };

            _.set(node.params, path, secret);
        }

        onUpdate(node);
    };

    startEdit = () => {
        const { node, onUpdate, path, isReadOnly } = this.props;

        if (!isReadOnly) {
            _.unset(node.params, path);
            onUpdate(node);

            this.setState({ showInput: true });
        }
    };

    render() {
        const {
            classes,
            clearTooltip,
            multiline,
            node,
            path,
            placeholder,
            isReadOnly,
            hideKeyReset = false,
        } = this.props;
        const { showInput, editBuffer } = this.state;
        const secret = _.get(node.params, path) || {};

        const readSuffix = (
            <div title={clearTooltip}>
                <CloseCircleFilled
                    onClick={this.startEdit}
                    className={!isReadOnly ? classes.inputPasswordSuffix : classes.inputPasswordSuffixNotAllowed}
                />
            </div>
        );

        /* eslint-disable no-nested-ternary */
        return !showInput ? (
            <Input
                disabled
                type="password"
                suffix={!hideKeyReset && readSuffix}
                placeholder={placeholder}
                size="large"
                value={secret.maskedValue || secret.value}
            />
        ) : multiline ? (
            <Input.TextArea
                placeholder={placeholder}
                value={editBuffer}
                onChange={(ev) => this.setState({ editBuffer: ev.target.value })}
                onBlur={this.updateSecret}
                disabled={isReadOnly}
            />
        ) : (
            <Input.Password
                size="large"
                value={editBuffer}
                onChange={(ev) => {
                    this.setState({ editBuffer: ev.target.value });
                }}
                onPressEnter={this.updateSecret}
                onBlur={this.updateSecret}
                placeholder={placeholder}
                autoComplete="new-password"
                visibilityToggle
                disabled={isReadOnly}
            />
        );
    }
}

export default compose<ComponentType<PublicProps>>(withReadOnly, withStyles(styles))(SecretInput);
