// This file contains default function configs for reader functions.

import { readers } from '../../dazzler-constants';

const defaultConfig = {
    [readers.counter]: {
        max: 10,
    },
    [readers.azureBlobStorage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'azure',
        },
    },
    [readers.googleCloudStorage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'google',
        },
    },
    [readers.s3]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'aws',
        },
    },
    [readers.sftp]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'sftp',
        },
    },
};

export default defaultConfig;
