import React from 'react';
import { useValidatorState } from '../context';
import { ValidationRule } from '../types';
import ValidationSelect from './ValidationSelect';

type Props = {
    rule: ValidationRule;
    disabled?: boolean;
};

const ValidationSelectProperty = ({ rule, disabled = false }: Props) => {
    const { properties, dispatch } = useValidatorState();

    const onChange = (value: string) => {
        dispatch({
            type: 'UPDATE_PROPERTY_VALIDATION',
            payload: {
                rule,
                value,
                field: 'validationRuleKey',
            },
        });
    };

    return <ValidationSelect rule={rule} properties={properties} onChange={onChange} disabled={disabled} />;
};

export default ValidationSelectProperty;
