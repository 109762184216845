import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { SectionTitleComponent, IconWrapper } from '@digitalroute-internal/dazzlerjs-react-ui';
import ReactMarkdown from 'react-markdown';
import { set, get } from 'lodash';
import { useIntl } from 'react-intl';
import { getInterconnectableStreams, getActiveAccountId, fetchInterconnect } from '../../../../accounts';
import { icons } from '../../../../resources';

const styles = (theme) => ({
    wrapper: {
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        color: theme.neutral04,
        width: '90%',
        margin: '0 auto',
        '& svg': {
            fontSize: theme.fontSizeXl,
        },
    },
});
const useStyles = createUseStyles(styles);

const ForwarderInterconnectSetting = ({
    node,
    onUpdate,
    stream,
    defaultOn = true,
    dataTestIdPrefix = 'ForwarderInterconnectSetting',
}): JSX.Element => {
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `function_config.general.interconnect.${id}` });

    const classes = useStyles();

    const useInterconnect = get(node, 'params.useInterconnect', defaultOn);
    const [useInterconectValue, setUseInterconnect] = useState<boolean>(!!useInterconnect);

    const dispatch = useDispatch();
    const accountId = useSelector(getActiveAccountId) || '';
    const interconnectableStreams = useSelector(getInterconnectableStreams);

    const [selectableStreams, setSelectableStreams] = useState([]);
    const [selectedStreamNode, setSelectedStreamNode] = useState('');

    useEffect(() => {
        dispatch(fetchInterconnect.request(accountId, stream.solutionId));
    }, []);

    useEffect(() => {
        if (node.params.interconnectId && interconnectableStreams?.length) {
            const dazNode = interconnectableStreams.filter((s) => s.id === node.params.interconnectId).shift();

            if (dazNode) {
                const readableStreamName = `${dazNode.stream.name}/${dazNode.node.name}`;
                setSelectedStreamNode(readableStreamName);
            }
        }
    }, [node.params.interconnectId, interconnectableStreams]);

    const resetConfiguration = () => {
        const n = {
            ...node,
            params: {
                ...node.params,
                interconnectId: undefined,
            },
        };
        onUpdate(n);
        setSelectedStreamNode('');
    };

    const setUseInterconnectFunction = () => {
        const newValue = !useInterconectValue;
        setUseInterconnect(newValue);
        set(node.params, 'useInterconnect', newValue);
        if (!newValue) {
            resetConfiguration();
        } else {
            onUpdate(node);
        }
    };

    const setAllInterconnectableStreams = () => {
        const streams = interconnectableStreams.map((selectableStream) => {
            const readableStreamName = `${selectableStream.stream.name}/${selectableStream.node.name}`;
            return {
                title: readableStreamName,
                key: readableStreamName,
                value: selectableStream.id,
                label: readableStreamName,
                text: readableStreamName,
                interconnectId: selectableStream.id,
            };
        });
        setSelectableStreams(streams);
    };

    useEffect(() => {
        if (node.params.interconnectId && interconnectableStreams && !interconnectableStreams.length) {
            resetConfiguration();
        }

        if (node.params.interconnectId && interconnectableStreams?.length) {
            const res = interconnectableStreams.some((s) => s.id === node.params.interconnectId);
            if (!res) {
                resetConfiguration();
            }
        }

        if (interconnectableStreams?.length) {
            setAllInterconnectableStreams();
        }
    }, [interconnectableStreams]);

    const selectStream = (interconnectId) => {
        const selected = selectableStreams
            .filter((selectableStream) => selectableStream.value === interconnectId)
            .shift();

        if (selected) {
            const n = {
                ...node,
                params: {
                    ...node.params,
                    interconnectId: selected.interconnectId,
                },
            };
            onUpdate(n);
            setSelectedStreamNode(selected.key);
        }
    };

    const emptyState = () => (
        <div className={classes.wrapper}>
            <IconWrapper className={classes.wrapper} iconName={icons.duotone.faFolderOpen} onlyIcon />
            <div>{i18n('empty_title')}</div>
            <ReactMarkdown>{i18n('empty_text')}</ReactMarkdown>
        </div>
    );
    return (
        <SectionTitleComponent
            showSwitch
            showContent
            titleText={i18n('title')}
            descriptionText={i18n('description')}
            switchText={i18n('switch_text')}
            switchOnChange={setUseInterconnectFunction}
            switchChecked={useInterconnect}
            switchDataTestId={`${dataTestIdPrefix}-Switch`}
            selectTitle={i18n('select_title')}
            selectValue={selectedStreamNode}
            selectOptionData={selectableStreams}
            selectDataTestId={`${dataTestIdPrefix}-Select`}
            selectCustomEmptyRender={emptyState}
            selectOnChange={selectStream}
        />
    );
};

export default ForwarderInterconnectSetting;
