import { handleActions } from 'redux-actions';
import { modal } from '../actions';
import type { ModalPayload } from '../../types';

export type State = {
    [key in ModalPayload]: boolean;
};

export const initialState: State = modal.actions.reduce(
    (state, key) => ({
        ...state,
        [key]: false,
    }),
    {} as State,
);

export const reducer = handleActions<State, ModalPayload>(
    {
        [modal.toggleModal.toString()]: (state, { payload }): State => {
            const isPayloadArray = Array.isArray(payload);

            const newState = isPayloadArray
                ? payload.reduce(
                      (result, currentModal) => ({
                          ...result,
                          [currentModal]: !state[currentModal],
                      }),
                      {},
                  )
                : { [payload]: !state[payload] };

            return {
                ...initialState,
                ...newState,
            };
        },
    },
    initialState,
);
