import { api } from '../app/api';
import { overrideExisting, providesList } from '../app/utils';
import type { RootState } from '../app/store';

export interface Secret {
    accountId: string;
    id: string;
    name: string;
    description: string;
    type: string;
    fields: SecretField[];
    utilizingStreams: UtilizingStream[];
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    authentication?: string;
    deploymentErrors?: DeploymentError[];
}

export interface SecretType {
    type: string;
    version: number;
    variants: SecretVariant[];
}

export interface SecretVariantField {
    name: string;
    secret: boolean;
    required: boolean;
    multiline?: boolean;
    minLength?: number;
    maxLength?: number;
    regex?: string;
    select?: boolean;
    values?: string[];
}

interface SecretVariant {
    authentication?: string;
    fields: SecretVariantField[];
}

interface SecretField {
    name: string;
    secret: boolean;
    value?: string;
    encrypted?: boolean;
}

interface UtilizingStream {
    id: string;
    name: string;
    solutionId: string;
    solutionName: string;
    scheduled: boolean;
    isInSync: boolean;
}

interface DeploymentError {
    stream: { id: string; name: string };
    error: string;
}

interface SecretsResponse {
    secrets: Secret[];
}

type CreateSecretRequest = Pick<Secret, 'accountId' | 'type' | 'name' | 'fields'> &
    Partial<Pick<Secret, 'description' | 'authentication'>>;

export const secretWalletApi = api.injectEndpoints({
    overrideExisting: overrideExisting(),
    endpoints: (builder) => ({
        getSecretTypesByAccountId: builder.query<SecretType[], string>({
            query: (accountId) => `accounts/${accountId}/secret-types`,
        }),
        getSecretsWalletByAccountId: builder.query<Secret[], string>({
            query: (accountId) => `secrets?accountId=${accountId}`,
            transformResponse: (response: SecretsResponse) => response.secrets,
            providesTags: (result) => providesList(result, 'SecretWallet'),
        }),
        createSecret: builder.mutation<Secret, CreateSecretRequest>({
            query: ({ accountId, ...body }) => ({
                url: `accounts/${accountId}/secrets`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ accountId, ...rest }, { dispatch, queryFulfilled }) {
                const createResult = dispatch(
                    secretWalletApi.util.updateQueryData('getSecretsWalletByAccountId', accountId, (draft) => [
                        {
                            accountId,
                            utilizingStreams: [],
                            createdAt: new Date().getTime().toString(),
                            ...rest,
                        } as Secret,
                        ...draft,
                    ]),
                );
                try {
                    await queryFulfilled;
                } catch {
                    createResult.undo();
                }
            },
            invalidatesTags: [{ type: 'SecretWallet', id: 'LIST' }],
        }),
        updateSecret: builder.mutation<Secret, Secret>({
            query: (secret) => ({
                url: `secrets/${secret.id}`,
                method: 'PUT',
                body: secret,
            }),
            async onQueryStarted(secret, { dispatch, queryFulfilled }) {
                const updateResult = dispatch(
                    secretWalletApi.util.updateQueryData('getSecretsWalletByAccountId', secret.accountId, (draft) => {
                        const secretIndex = draft.findIndex((entry) => entry.id === secret.id);

                        if (secretIndex !== -1) {
                            draft.splice(secretIndex, 1, secret);
                        }

                        return draft;
                    }),
                );

                try {
                    const { data: responseSecret } = await queryFulfilled;
                    // Update the cache with the actual response data
                    dispatch(
                        secretWalletApi.util.updateQueryData(
                            'getSecretsWalletByAccountId',
                            secret.accountId,
                            (draft) => {
                                const secretIndex = draft.findIndex((entry) => entry.id === responseSecret.id);
                                if (secretIndex !== -1) {
                                    draft.splice(secretIndex, 1, responseSecret);
                                }
                            },
                        ),
                    );
                } catch {
                    updateResult.undo();
                }
            },
        }),
        deleteSecretById: builder.mutation<void, string>({
            query: (secretId) => ({
                url: `secrets/${secretId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(secretId, { dispatch, getState, queryFulfilled }) {
                const state = getState() as RootState;
                const deleteResult = dispatch(
                    secretWalletApi.util.updateQueryData(
                        'getSecretsWalletByAccountId',
                        state.accounts.activeAccount,
                        (draft) => draft.filter((secret) => secret.id !== secretId),
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetSecretTypesByAccountIdQuery,
    useGetSecretsWalletByAccountIdQuery,
    useCreateSecretMutation,
    useUpdateSecretMutation,
    useDeleteSecretByIdMutation,
} = secretWalletApi;

export const selectVariantAuthBySecretType =
    (type: string) =>
    (state: RootState): SecretVariant['authentication'][] => {
        const secretTypes = secretWalletApi.endpoints.getSecretTypesByAccountId.select(state.accounts.activeAccount)(
            state,
        );
        const existingType = secretTypes.data?.find((secretType) => type === secretType.type);

        if (existingType) {
            const authenticationTypes = existingType.variants.map(({ authentication }) => authentication);
            const uniqueAuthenticationTypes = new Set(authenticationTypes);

            return [...uniqueAuthenticationTypes];
        }

        return [];
    };

export const selectFieldsByAuthAndSecretType =
    (authType: string, secretType: string) =>
    (state: RootState): SecretVariant['fields'] => {
        const secretTypes = secretWalletApi.endpoints.getSecretTypesByAccountId.select(state.accounts.activeAccount)(
            state,
        );
        const existingSecretType = secretTypes.data?.find(({ type }) => type === secretType);

        if (existingSecretType) {
            const variant = existingSecretType.variants.find(({ authentication }) => authentication === authType);

            if (variant) {
                const fields = variant.fields.map((field) => field);

                return fields;
            }
        }

        return [];
    };

export const selectSecretById =
    (id: string) =>
    (state: RootState): Secret => {
        const wallet = secretWalletApi.endpoints.getSecretsWalletByAccountId.select(state.accounts.activeAccount)(
            state,
        );
        const secret = wallet.data?.find((walletEntity) => walletEntity.id === id);

        return secret;
    };

export const selectNumberOfSecrets = (state: RootState): number => {
    const wallet = secretWalletApi.endpoints.getSecretsWalletByAccountId.select(state.accounts.activeAccount)(state);

    return wallet.data?.length ?? 0;
};

export const selectSecrets =
    (type: string = '', filterText: string = '') =>
    (state: RootState): Secret[] => {
        const wallet = secretWalletApi.endpoints.getSecretsWalletByAccountId.select(state.accounts.activeAccount)(
            state,
        );

        if (type || filterText) {
            const filteredSecrets = wallet.data?.filter(
                (secret) =>
                    secret.name.toLowerCase().includes(filterText.toLowerCase()) &&
                    secret.type.toLowerCase().includes(type.toLowerCase()),
            );

            return filteredSecrets ?? [];
        }

        return wallet.data ?? [];
    };

export const selectUniqueSecretTypes = (state: RootState): string[] => {
    const secretTypes = secretWalletApi.endpoints.getSecretTypesByAccountId.select(state.accounts.activeAccount)(state);
    const types = secretTypes.data?.map(({ type }) => type);

    if (!types) {
        return [];
    }

    const uniqueTypes = new Set(types);

    return [...uniqueTypes];
};
