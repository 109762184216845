import React, { ChangeEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { Input, Select, Popover } from 'antd';
import { useIntl } from 'react-intl';
import { ValidationRule } from '../types';
import { defaultValues, getPropertyDescription } from '../utils';
import { useValidatorState } from '../context';

const i18n = (id: string, usePrefix = true) => {
    if (id !== '') {
        if (!usePrefix) {
            return useIntl().formatMessage({ id });
        }

        return useIntl().formatMessage({ id: `function_config.transforms.validator.${id}` });
    }

    return '';
};

const getMinMaxProps = ({ min, defaultValue }) => ({
    min,
    defaultValue,
    type: 'number',
    size: 'middle',
    placeholder: i18n('enter_positive_number_placeholder'),
    autoComplete: 'off',
});

const getInputProps = ({ min, defaultValue }) => ({
    const: { size: 'middle', placeholder: i18n('enter_value_placeholder'), autoComplete: 'off' },
    exclusiveMaximum: {
        type: 'number',
        size: 'middle',
        placeholder: i18n('enter_number_placeholder'),
        autoComplete: 'off',
    },
    exclusiveMinimum: {
        type: 'number',
        size: 'middle',
        placeholder: i18n('enter_number_placeholder'),
        autoComplete: 'off',
    },
    maximum: { type: 'number', size: 'middle', placeholder: i18n('enter_number_placeholder'), autoComplete: 'off' },
    minimum: { type: 'number', size: 'middle', placeholder: i18n('enter_number_placeholder'), autoComplete: 'off' },
    pattern: { size: 'middle', placeholder: i18n('enter_regular_expression_placeholder'), autoComplete: 'off' },
    contains: { size: 'middle', placeholder: i18n('enter_json_object'), autoComplete: 'off' },
    minItems: { ...getMinMaxProps({ min, defaultValue }) },
    maxItems: { ...getMinMaxProps({ min, defaultValue }) },
    minContains: { ...getMinMaxProps({ min, defaultValue }) },
    maxContains: { ...getMinMaxProps({ min, defaultValue }) },
    minLength: { ...getMinMaxProps({ min, defaultValue }) },
    maxLength: { ...getMinMaxProps({ min, defaultValue }) },
    minProperties: { ...getMinMaxProps({ min, defaultValue }) },
    maxProperties: { ...getMinMaxProps({ min, defaultValue }) },
    multipleOf: { ...getMinMaxProps({ min, defaultValue }) },
});

const styles = (theme) => ({
    selectOverwrite: {
        '&.ant-select, &.ant-input': {
            marginTop: theme.marginXs,
        },
    },
    block: {
        display: 'block',
    },
    popover: {
        maxWidth: 350,
        zIndex: 2000,
    },
});

const useStyles = createUseStyles(styles);

type Props = {
    rule: ValidationRule;
    disabled?: boolean;
};

const InputField = ({ rule, rule: { validationRuleKey, validationRuleValue, objectKey }, disabled = false }: Props) => {
    const { objects, validationSchema, dispatch } = useValidatorState();
    // @ts-ignore
    const types = validationSchema.properties.type.anyOf[0].enum;

    const filteredObjects = objects.filter(({ key, properties }) => {
        if (!properties.length || key === objectKey) {
            return key !== objectKey;
        }
        return !properties.some(({ validationRules }) => {
            if (!validationRules.length) {
                return false;
            }

            return validationRules.some(
                (v) => v.validationRuleKey === 'nestedObject' && v.validationRuleValue === objectKey,
            );
        });
    });

    const classes = useStyles();

    const onChange = (value: string) => {
        dispatch({
            type: objectKey ? 'UPDATE_OBJECT_VALIDATION' : 'UPDATE_PROPERTY_VALIDATION',
            payload: {
                rule,
                value,
                field: 'validationRuleValue',
            },
        });
    };

    const onInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        onChange(value);
    };

    const onSelectChange = (value: string) => {
        onChange(value);
    };

    if (validationRuleKey === 'enum') {
        return (
            <Select
                className={classes.selectOverwrite}
                mode="tags"
                placeholder={i18n('enum_placeholder')}
                notFoundContent=""
                value={validationRuleValue}
                onChange={onSelectChange}
                disabled={disabled}
            />
        );
    }

    if (validationRuleKey === 'type') {
        return (
            <Select
                className={classes.selectOverwrite}
                placeholder={i18n('select_type_placeholder')}
                optionLabelProp="label"
                value={validationRuleValue}
                onChange={onSelectChange}
                dropdownMatchSelectWidth={false}
                disabled={disabled}
            >
                {types.map((type) => (
                    <Select.Option key={type} value={type} label={type}>
                        <Popover
                            content={i18n(getPropertyDescription(type))}
                            placement="right"
                            mouseEnterDelay={0}
                            overlayClassName={classes.popover}
                        >
                            <strong className={classes.block}>{type}</strong>
                        </Popover>
                    </Select.Option>
                ))}
            </Select>
        );
    }

    if (['required', 'uniqueItems'].includes(validationRuleKey)) {
        return (
            <Select
                className={classes.selectOverwrite}
                placeholder={i18n('select_option_placeholder')}
                value={validationRuleValue || defaultValues[validationRuleKey]}
                defaultValue={defaultValues[validationRuleKey]}
                onChange={onSelectChange}
                disabled={disabled}
            >
                <Select.Option key="yes" value="yes">
                    {i18n('general.confirmation', false)}
                </Select.Option>
                <Select.Option key="no" value="no">
                    {i18n('general.deny', false)}
                </Select.Option>
            </Select>
        );
    }

    if (validationRuleKey === 'nestedObject') {
        // TODO: add validation 2 objects don't reference each other

        return (
            <Select
                className={classes.selectOverwrite}
                placeholder={i18n('select_object_placeholder')}
                notFoundContent={i18n('object_no_content')}
                value={validationRuleValue}
                onChange={onSelectChange}
                disabled={disabled}
            >
                {filteredObjects.map((object) => (
                    <Select.Option key={object.key} value={object.key}>
                        {object.objectName}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    return (
        <Input
            className={classes.selectOverwrite}
            value={validationRuleValue}
            disabled={disabled || !validationRuleKey}
            onChange={onInputChange}
            {...getInputProps({
                min: defaultValues[validationRuleKey],
                defaultValue: defaultValues[validationRuleKey],
            })[validationRuleKey]}
        />
    );
};

export default InputField;
