import { api } from '../app/api';
import { RootState } from '../app/store';
import { overrideExisting } from '../app/utils';
import { Role, Account } from '../packages/dazzler-types';

export enum GrantResourceType {
    ACCOUNT = 'account',
    ORGANIZATION = 'organization',
}
export interface Grant {
    role: string;
    resourceType: GrantResourceType;
    resourceId: Account['id'];
}

export interface User {
    id: string;
    roles: string[];
    grants: Grant[];
    updatedAt: string;
    createdAt: string;
    __v: number;
    firstName: string;
    lastName: string;
    useGravatar: boolean;
    createdBy?: string;
    limits?: string;
}

interface UpdateUserRequest {
    firstName: User['firstName'];
    lastName: User['lastName'];
}

export const userApi = api.injectEndpoints({
    overrideExisting: overrideExisting(),
    endpoints: (builder) => ({
        getUser: builder.query<User, void>({
            query: () => 'users/profile',
            providesTags: ['User'],
        }),
        updateUser: builder.mutation<User, UpdateUserRequest>({
            query: (body) => ({
                url: 'users/profile',
                method: 'PATCH',
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const updateResult = dispatch(
                    userApi.util.updateQueryData('getUser', undefined, (draft) => ({ ...draft, ...body })),
                );

                try {
                    await queryFulfilled;
                } catch {
                    updateResult.undo();
                }
            },
        }),
    }),
});

export const { endpoints, useGetUserQuery, useUpdateUserMutation } = userApi;

export const selectUser = (state: RootState) => endpoints.getUser.select(undefined)(state).data || undefined;

export const selectIsLoadingUser = (state: RootState) => endpoints.getUser.select(undefined)(state).isLoading || false;

export const selectIsAccountAdmin = (state: RootState) => {
    const accountId = state.accounts.activeAccount;
    const userGrants = endpoints.getUser.select(undefined)(state).data?.grants || [];
    return userGrants.some(
        (grant) =>
            grant.resourceType === GrantResourceType.ACCOUNT &&
            grant.resourceId === accountId &&
            grant.role === Role.admin,
    );
};

export const selectIsAccountUser = (state: RootState) => {
    const accountId = state.accounts.activeAccount;
    const userGrants = endpoints.getUser.select(undefined)(state).data?.grants || [];
    return userGrants.some(
        (grant) =>
            grant.resourceType === GrantResourceType.ACCOUNT &&
            grant.resourceId === accountId &&
            grant.role === Role.user,
    );
};

export const selectIsAccountGuest = (state: RootState) => {
    const accountId = state.accounts.activeAccount;
    const userGrants = endpoints.getUser.select(undefined)(state).data?.grants || [];
    return userGrants.some(
        (grant) =>
            grant.resourceType === GrantResourceType.ACCOUNT &&
            grant.resourceId === accountId &&
            (grant.role === Role.guest || grant.role === 'readOnly'),
    );
};
