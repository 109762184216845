import React, { useState, useEffect, ChangeEvent } from 'react';
import { Form, Input } from 'antd';
import { Divider } from '@digitalroute-internal/dazzlerjs-react-ui';
import { get } from 'lodash';
import { Node } from '../../../../../dazzler-types';
import TextInputItem from '../../configurator-fields/TextInputItem';
import { getDocUrl } from '../../../../../docs';

type Props = {
    node: Node;
    fileSizeLimit: number;
    shouldUseDescription?: boolean;
    classes: any;
    updateParams: (arg0: string, arg1: string) => void;
    onUpdate: (node: Node) => void;
    i18n: any;
};

const ZuoraFileForwarderFileInformationComponent = ({
    node,
    shouldUseDescription,
    classes,
    updateParams,
    onUpdate,
    i18n,
}: Props) => {
    const [description, setDescription] = useState('');
    const updateDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value: newDescription } = event.target;
        setDescription(newDescription);
        updateParams('description', newDescription);
    };

    useEffect(() => {
        const existingDescription = get(node.params, 'description', description);
        setDescription(existingDescription);
    }, [node.params, description]);

    const zuoraDescriptionMessage = () => (
        <p className={classes.fileDescriptionContainer}>
            {i18n('file_information_text')}
            <a href={getDocUrl('zuora')} target="_blank" rel="noreferrer">
                {i18n('learn_more')}
            </a>
        </p>
    );

    return (
        <>
            <TextInputItem
                label={i18n('file_name_label')}
                path="fileName"
                node={node}
                onUpdate={onUpdate}
                placeholder=""
                addonAfter=""
                maxLength={42}
                className={classes.noMarginBottom}
            />
            {zuoraDescriptionMessage()}
            {shouldUseDescription && (
                <Form.Item label={i18n('file_description_legend')}>
                    <Input.TextArea
                        placeholder=""
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        onChange={updateDescription}
                        value={description}
                    />
                </Form.Item>
            )}
            <div className={classes.formMargin}>
                <Divider />
            </div>
        </>
    );
};

export default ZuoraFileForwarderFileInformationComponent;
