import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { get, isEqual, set, unset } from 'lodash';
import { Form, Select } from 'antd';
import { ConfiguratorProps, FeatureToggleFn } from '../../../../../dazzler-types';
import { isFeatureEnabled } from '../../../../../feature-toggles';
import { TextInputItem } from '../../configurator-fields';
import GoTransverseQueryParametersItem from '../../common/goTransverse/GoTransverseQueryParametersItem';
import { GoTransverseQueryParameters } from '../../parameters/transforms';
import GoTransverseOperationalSettings from '../../common/goTransverse/GoTransverseOperationalSettings';

const I18N_PREFIX = 'function_config.transform.goTransverse';

type Endpoint = {
    name: string;
    url: string;
};
const GoTransverseQueryConfigurator = ({ node, onUpdate, functionType }: ConfiguratorProps) => {
    const isFeatureToggleEnabled: FeatureToggleFn = useSelector(isFeatureEnabled);
    const isVersionControlPostPhase2Enabled: boolean = isFeatureToggleEnabled('versionControlPostPhase2');
    const intl = useIntl();
    const i18n = (values: string) => intl.formatMessage({ id: `${I18N_PREFIX}.${values}` });
    const [endpoints, setEndpoints] = useState<Array<Endpoint>>([]);
    const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint>({
        name: '',
        url: '',
    });
    const [hasTableId, setHasTableId] = useState<boolean>(false);

    useEffect(() => {
        let selectedApiEndpoint = get(node.params, 'apiEndpoint', '');
        const tableId = get(node.params, 'tableId', '');
        const availableEndpoints = get(functionType, 'params.properties.endpoints.enum', []);

        setEndpoints(availableEndpoints);
        let endpoint = availableEndpoints[0];
        if (selectedApiEndpoint) {
            endpoint = availableEndpoints.find((t) => isEqual(t.url, selectedApiEndpoint));
        } else {
            selectedApiEndpoint = 'billing-accounts';
            set(node.params, 'apiEndpoint', 'billing-accounts');
            onUpdate(node);
        }
        setSelectedEndpoint(endpoint);
        if (tableId || selectedApiEndpoint.includes('{tableId')) {
            setHasTableId(true);
        }
    }, []);

    const updateSelectedEndpoint = (value: string) => {
        const updateEndpoint = endpoints.find((ep) => ep.name === value) || { name: '', url: '' };
        setHasTableId(updateEndpoint.url.includes('{tableId}'));
        setSelectedEndpoint(updateEndpoint);
        unset(node.params, 'queryParameters');
        if (node.params.tableId) {
            unset(node.params, 'tableId');
        }
        set(node.params, 'apiEndpoint', updateEndpoint.url);
        onUpdate(node);
    };
    return (
        <>
            {!isVersionControlPostPhase2Enabled && (
                <GoTransverseQueryParameters functionType={functionType} node={node} onUpdate={onUpdate} />
            )}
            <Form layout="vertical">
                <fieldset>
                    <legend>{i18n('api_legend')}</legend>
                    <Form.Item label={i18n('query_options_label')} required>
                        <Select
                            size="large"
                            showSearch
                            placeholder={i18n('tenant_placeholder')}
                            getPopupContainer={({ parentNode }) => parentNode}
                            onChange={(value) => updateSelectedEndpoint(value)}
                            value={selectedEndpoint?.name}
                        >
                            {endpoints.map((apiEndpoint) => (
                                <Select.Option value={apiEndpoint.name} key={apiEndpoint.name}>
                                    {apiEndpoint.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {hasTableId && (
                        <TextInputItem
                            label={i18n('table_id_label')}
                            path="tableId"
                            required
                            node={node}
                            onUpdate={onUpdate}
                        />
                    )}
                </fieldset>
                <fieldset>
                    <legend>{i18n('parameter_mapping_legend')}</legend>
                    <>
                        <GoTransverseQueryParametersItem
                            i18nPrefix={I18N_PREFIX}
                            node={node}
                            onUpdate={onUpdate}
                            functionType={functionType}
                        />
                    </>
                </fieldset>
                <fieldset>
                    <GoTransverseOperationalSettings node={node} onUpdate={onUpdate} i18nPrefix={I18N_PREFIX} />
                </fieldset>
            </Form>
        </>
    );
};
export default GoTransverseQueryConfigurator;
