import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import type { ConfiguratorProps } from '../../../../dazzler-types';
import TextInputItem from '../configurator-fields/TextInputItem';

const TEST_ID = 'salesforce_env_item';
const CUSTOM = 'Custom';
type Tenant = {
    name: string;
    url: string;
};

type Props = Required<Pick<ConfiguratorProps, 'node' | 'functionType' | 'onUpdate'>>;

function SalesforceEnvironmentItem({ node, functionType, onUpdate }: Props) {
    const [selectedTenant, setSelectedTenant] = useState<Tenant>({ name: '', url: '' });
    const [extendedTenants, setExtendedTenants] = useState<Array<Tenant>>([]);
    const intl = useIntl();

    useEffect(() => {
        const selectedSchemaTenant = get(node.params, 'tenant', selectedTenant.url);
        const availableTenants = get(functionType, 'params.definitions.availableTenants.enum', []);
        const completeListOfTenants: Array<Tenant> = availableTenants.concat({ name: CUSTOM, url: '' });
        // NB: If the selectedSchemaTenant URL doesn't exist in extendedTenants
        // the user has already specified a custom tenant URL
        let tenant = completeListOfTenants.filter((t) => t.url === selectedSchemaTenant)[0] || {
            name: CUSTOM,
            url: selectedSchemaTenant.url,
        };

        // NB: If user has entered an empty URL, we disregard it and use the empty state
        if (!selectedSchemaTenant) {
            tenant = { name: '', url: '' };
        }
        setExtendedTenants(completeListOfTenants);
        setSelectedTenant(tenant);
    }, []);

    const i18n = (id: string) => intl.formatMessage({ id: `function_config.transform.salesforce_usage_common.${id}` });

    const updateSelectedTenant = (endpoint: string) => {
        const filteredTenant = extendedTenants.filter((ep) => ep.name === endpoint)[0];
        const filteredSelectedTenant = filteredTenant || { name: '', url: '' };
        setSelectedTenant((state) => ({ ...state, ...filteredSelectedTenant }));

        const n = {
            ...node,
            params: {
                ...node.params,
                tenant: filteredSelectedTenant.url,
            },
        };

        onUpdate(n);
    };

    return (
        <fieldset>
            <legend>{i18n('tenant_legend')}</legend>
            <>
                <Form.Item label={i18n('tenant_label')} required>
                    <Select
                        size="large"
                        showSearch
                        placeholder={i18n('tenant_placeholder')}
                        getPopupContainer={({ parentNode }) => parentNode}
                        onChange={updateSelectedTenant}
                        value={selectedTenant.name}
                        data-testid={`${TEST_ID}__select-tenant`}
                    >
                        {extendedTenants.map((tenant) => (
                            <Select.Option value={tenant.name} key={tenant.name}>
                                {tenant.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedTenant.name && (
                    <TextInputItem
                        label={i18n('tenant_endpoint')}
                        path="tenant"
                        required
                        node={node}
                        onUpdate={onUpdate}
                        placeholder={i18n('tenant_endpoint_placeholder')}
                        disabled={selectedTenant.name !== CUSTOM}
                        defaultValue={selectedTenant.url}
                        data-testid={`${TEST_ID}__input-tenant`}
                    />
                )}
            </>
        </fieldset>
    );
}

export default SalesforceEnvironmentItem;
