import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { TextInputItem, SecretInput } from '../../../configurator-fields';
import type { BasicConfiguratorProps } from '../../../../../../dazzler-types';

type Props = BasicConfiguratorProps & {
    prefix: string;
};

const Database = ({ prefix, node, onUpdate }: Props) => {
    const intl = useIntl();
    const i18n = (id: string): string => intl.formatMessage({ id: `${prefix}.${id}` });

    return (
        <>
            <TextInputItem
                required
                placeholder={i18n('user')}
                label={i18n('user')}
                path="username"
                node={node}
                onUpdate={onUpdate}
            />
            <Form.Item required label={i18n('password')}>
                <SecretInput placeholder={i18n('password')} path="password" node={node} onUpdate={onUpdate} />
            </Form.Item>
        </>
    );
};

export default Database;
