import React from 'react';
import { formatDistanceToNow } from 'date-fns';

type Props = {
    timestamp: string | number | Date;
};

const DazFormattedRelativeTime = ({ timestamp }: Props) => {
    const date = Number.isNaN(Number(timestamp)) ? new Date(timestamp) : new Date(Number(timestamp));

    if (!timestamp || !date.getTime()) {
        return <>-</>;
    }

    return <>{formatDistanceToNow(date, { addSuffix: true })}</>;
};

export default DazFormattedRelativeTime;
