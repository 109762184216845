const SUMMARY_LOCAL = 'LogView_localStorage';

interface Options {
    timestamp: boolean;
    severity: boolean;
    origin: boolean;
    versionTag: boolean;
    executionId: boolean;
    replicaId: boolean;
    message: boolean;
}

export const defaultSummary: Options = {
    timestamp: true,
    severity: true,
    origin: true,
    versionTag: true,
    executionId: false,
    replicaId: false,
    message: true,
};

export const getDisplayOptions = (): Options => {
    const options = JSON.parse(localStorage.getItem(SUMMARY_LOCAL));
    return options || defaultSummary;
};

export const setDisplayOptions = (options: Options): void => {
    localStorage.setItem(SUMMARY_LOCAL, JSON.stringify(options));
};

export const resetDisplayOptions = (): void => {
    localStorage.removeItem(SUMMARY_LOCAL);
};
