import { api } from '../app/api';
import type { RootState } from '../app/store';
import { overrideExisting, providesList, providesListObject } from '../app/utils';
import type { Account, Stream } from '../packages/dazzler-types';

export interface NotificationConfig {
    id?: string;
    streamId?: string;
    cooldown: number;
    type: string;
    groupIds: string[];
    enabled: boolean;
}

export interface NotificationGroup {
    id: string;
    name: string;
    accountId: string;
    description: string;
    emails: string[];
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}

export type DeleteGroupParams = {
    accountId: string;
    groupId: string;
    groupName: string;
};

interface NotificationGroupBody {
    name: string;
    emails: string[];
    description?: string;
}

interface NotificationConfigsResponse {
    [streamId: string]: NotificationConfig[];
}

interface NotificationConfigRequest {
    body: NotificationConfig[];
    accountId: Account['id'];
    streamId: Stream['id'];
}

interface CreateNotificationGroupRequest {
    body: NotificationGroupBody;
    accountId: Account['id'];
}

interface UpdateNotificationGroupRequest {
    body: NotificationGroupBody;
    accountId: Account['id'];
    groupId: NotificationGroup['id'];
}

interface DeleteNotificationGroupRequest {
    accountId: Account['id'];
    groupId: NotificationGroup['id'];
}

export const notificationsApi = api.injectEndpoints({
    overrideExisting: overrideExisting(),
    endpoints: (builder) => ({
        getConfigsBySolutionId: builder.query<NotificationConfigsResponse, string>({
            query: (solutionId) => `solutions/${solutionId}/notification-configs`,
            providesTags: (result) => providesListObject(result, 'NotificationConfig'),
        }),
        updateConfig: builder.mutation<NotificationConfig, NotificationConfigRequest>({
            query: ({ accountId, streamId, body }) => ({
                url: `accounts/${accountId}/streams/${streamId}/notification-configs`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'NotificationConfig', id: 'LIST' }],
        }),
        getGroupsByAccountId: builder.query<NotificationGroup[], string>({
            query: (accountId) => `accounts/${accountId}/notification-groups`,
            providesTags: (result) => providesList(result, 'NotificationGroup'),
        }),
        createGroup: builder.mutation<NotificationGroup, CreateNotificationGroupRequest>({
            query: ({ accountId, body }) => ({
                url: `accounts/${accountId}/notification-groups`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ accountId, body }, { dispatch, queryFulfilled }) {
                const createResult = dispatch(
                    notificationsApi.util.updateQueryData('getGroupsByAccountId', accountId, (draft) => {
                        draft.push({
                            ...body,
                            createdAt: new Date().toISOString(),
                        } as NotificationGroup);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    createResult.undo();
                }
            },
            invalidatesTags: [{ type: 'NotificationGroup', id: 'LIST' }],
        }),
        updateGroup: builder.mutation<NotificationGroup, UpdateNotificationGroupRequest>({
            query: ({ accountId, groupId, body }) => ({
                url: `accounts/${accountId}/notification-groups/${groupId}`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ accountId, groupId, body }, { dispatch, queryFulfilled }) {
                const updateResult = dispatch(
                    notificationsApi.util.updateQueryData('getGroupsByAccountId', accountId, (draft) => {
                        const indexGroup = draft.findIndex(({ id }) => id === groupId);
                        draft[indexGroup] = {
                            ...draft[indexGroup],
                            ...body,
                        };
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    updateResult.undo();
                }
            },
        }),
        deleteGroup: builder.mutation<NotificationGroup, DeleteNotificationGroupRequest>({
            query: ({ accountId, groupId }) => ({
                url: `accounts/${accountId}/notification-groups/${groupId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ accountId, groupId }, { dispatch, queryFulfilled }) {
                const deleteResult = dispatch(
                    notificationsApi.util.updateQueryData('getGroupsByAccountId', accountId, (draft) =>
                        draft.filter((group) => group.id !== groupId),
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
    }),
});

export const {
    endpoints,
    useGetConfigsBySolutionIdQuery,
    useGetGroupsByAccountIdQuery,
    useUpdateConfigMutation,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    usePrefetch,
} = notificationsApi;

export const selectNotificationGroupNames = (state: RootState) => {
    const accountId = state.accounts.activeAccount;
    const data = endpoints.getGroupsByAccountId.select(accountId)(state).data || [];
    return data.map((group) => group.name);
};

export const selectNotificationConfigByStreamId = (streamId: Stream['id']) => (state: RootState) => {
    const solutionId = state.solutions.activeSolution.id;
    const data = endpoints.getConfigsBySolutionId.select(solutionId)(state).data || {};
    return data[streamId];
};
