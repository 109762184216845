import { Reducer } from 'react';
import {
    Property,
    ValidationRule,
    DataObject,
    CreateObjectProps,
    UpdateProperty,
    UpdatePropertyValidation,
} from '../types';
import { createObject, createProperty, createValidationRule, defaultValues } from '../utils';

export type Action =
    | { type: 'ADD_OBJECT'; payload?: CreateObjectProps }
    | { type: 'ADD_OBJECT_PROPERTY' | 'DELETE_OBJECT'; payload: DataObject['key'] }
    | { type: 'ADD_OBJECT_VALIDATION'; payload: Property }
    | { type: 'DELETE_OBJECT_PROPERTY'; payload: { objectKey: DataObject['key']; propertyKey: Property['key'] } }
    | { type: 'DELETE_OBJECT_VALIDATION'; payload: ValidationRule }
    | {
          type: 'UPDATE_OBJECT_PROPERTY';
          payload: { key: DataObject['key']; propertyKey: Property['key'] } & UpdateProperty;
      }
    | { type: 'UPDATE_OBJECT_VALIDATION'; payload: { rule: ValidationRule } & UpdatePropertyValidation }
    | { type: 'EXPAND_OBJECT'; payload: DataObject['key'][] }
    | { type: 'EXPAND_OBJECT_PROPERTY'; payload: { keys: Property['key'][]; objectKey: DataObject['key'] } }
    | { type: 'RENAME_OBJECT'; payload: { key: DataObject['key']; value: string } };

export type State = {
    objects: DataObject[];
};

export const initialState: State = {
    objects: [],
};

export const reducer: Reducer<State, Action> = (state = initialState, action): State => {
    switch (action.type) {
        case 'ADD_OBJECT':
            return {
                ...state,
                objects: [...state.objects, createObject()],
            };
        case 'ADD_OBJECT_PROPERTY': {
            const objects = [...state.objects];
            objects
                .find((obj) => obj.key === action.payload)
                .properties.push(createProperty({ objectKey: action.payload }));

            return { ...state, objects };
        }
        case 'ADD_OBJECT_VALIDATION': {
            const objects = [...state.objects];

            objects
                .find((object) => object.key === action.payload.objectKey)
                .properties.find((property) => property.key === action.payload.key)
                .validationRules.push(
                    createValidationRule({
                        objectKey: action.payload.objectKey,
                        propertyKey: action.payload.key,
                    }),
                );

            return { ...state, objects };
        }
        case 'DELETE_OBJECT':
            return {
                ...state,
                objects: state.objects.filter((object) => object.key !== action.payload),
            };
        case 'DELETE_OBJECT_PROPERTY': {
            const objects = [...state.objects];
            const objectIndex = objects.findIndex((object) => object.key === action.payload.objectKey);
            const properties = objects[objectIndex].properties.filter(
                (property) => property.key !== action.payload.propertyKey,
            );

            objects[objectIndex].properties = properties;

            return {
                ...state,
                objects,
            };
        }
        case 'DELETE_OBJECT_VALIDATION': {
            const objects = [...state.objects];
            const objectIndex = objects.findIndex((object) => object.key === action.payload.objectKey);
            const propertyIndex = objects[objectIndex].properties.findIndex(
                (property) => property.key === action.payload.propertyKey,
            );
            const updatedValidationRules = objects[objectIndex].properties[propertyIndex].validationRules.filter(
                (rule) => rule.key !== action.payload.key,
            );

            objects[objectIndex].properties[propertyIndex].validationRules = updatedValidationRules;

            return { ...state, objects };
        }
        case 'UPDATE_OBJECT_PROPERTY': {
            const objects = [...state.objects];
            const property = objects
                .find((object) => object.key === action.payload.key)
                .properties.find((prop) => prop.key === action.payload.propertyKey);

            property[action.payload.field as string] = action.payload.value;

            return { ...state, objects };
        }
        case 'UPDATE_OBJECT_VALIDATION': {
            const objects = [...state.objects];
            const validationRule = objects
                .find((object) => object.key === action.payload.rule.objectKey)
                .properties.find((property) => property.key === action.payload.rule.propertyKey)
                .validationRules.find((rule) => rule.key === action.payload.rule.key);

            validationRule[action.payload.field as string] = action.payload.value;

            if (action.payload.field === 'validationRuleKey') {
                validationRule.validationRuleValue = defaultValues[action.payload.value];
            }

            return { ...state, objects };
        }
        case 'EXPAND_OBJECT':
            return {
                ...state,
                objects: state.objects.map((object) => {
                    if (action.payload.includes(object.key)) {
                        return {
                            ...object,
                            expand: true,
                        };
                    }

                    return {
                        ...object,
                        expand: false,
                    };
                }),
            };
        case 'EXPAND_OBJECT_PROPERTY': {
            const objects = [...state.objects];
            const object = objects.find((obj) => obj.key === action.payload.objectKey);
            object.properties = object.properties.map((property) => ({
                ...property,
                expand: action.payload.keys.includes(property.key),
            }));

            return { ...state, objects };
        }
        case 'RENAME_OBJECT': {
            const objects = [...state.objects];
            const object = objects.find((obj) => obj.key === action.payload.key);

            object.objectName = action.payload.value;

            return { ...state, objects };
        }
        default:
            return state;
    }
};
