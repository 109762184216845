import React, { useState, memo, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Button, Card, Modal, Tooltip } from 'antd';
import { createUseStyles } from 'react-jss';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../../../packages/dazzler-types';
import { BasicModal, ModalMode } from '../../../../packages/components';
import { icons } from '../../../../packages/resources';
import { fetchWrapper } from '../../../../packages/helpers';
import { isFeatureEnabled } from '../../../../packages/feature-toggles';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
    Solution,
    useDeleteSolutionByIdMutation,
    useGetSolutionsByAccountIdQuery,
    useUpdateSolutionMutation,
} from '../../../../services/solutions';
import { AVATAR_COLORS } from '../../../../packages/dazzler-constants';
import { getActiveAccount } from '../../../../packages/accounts';

const MAX_SOLUTION_NAME_LENGTH = 30;

const styles = (theme) => ({
    '@keyframes cardLoading': {
        '0% 100%': { backgroundPosition: '0 50%' },
        '50%': { backgroundPosition: '100% 50%' },
    },
    card: {
        cursor: 'pointer',
        marginBottom: '8px',
        width: '100%',
        '& .ant-card-actions > li > span': {
            height: 46,
            margin: `-${theme.marginSm} 0`,
        },

        '& .ant-card-loading-block': {
            height: 14,
            margin: '4px 0',
            background:
                'linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2))',
            backgroundSize: '600% 600%',
            borderRadius: 2,
            animation: '$cardLoading 1.4s ease infinite',
        },
    },
    cardTitle: {
        color: theme.neutral05,
        marginTop: '4px',
    },
    cardMeta: {
        display: 'flex',
        margin: '24px 0 12px 0',
        overflow: 'hidden',
        justifyContent: 'space-between',
    },
    itemWrapper: {
        textAlign: 'center',
    },
    itemText: {
        fontSize: theme.fontSizeSm,
        color: theme.neutral04,
    },
    itemNumber: {
        fontWeight: 400,
        fontSize: theme.fontSizeXl,
        color: theme.neutral05,
    },
    itemNumberRed: {
        color: theme.red05,
    },
    actionLabel: {
        marginLeft: '8px',
    },
    newCard: {
        opacity: 0.6,
        cursor: 'not-allowed',
    },
    button: {
        padding: 0,
        height: '100%',
        color: theme.neutral04,
        '&:hover, &:focus, &:active': {
            background: 'none',
            color: theme.brand05,
        },
    },
    disabledButtonFix: {
        paddingTop: '6px',
    },
});

const useStyles = createUseStyles(styles);

interface Props {
    solutionId: Solution['id'];
    granted: boolean;
}

interface ItemProps {
    value: string | number;
    type: string;
}

const SolutionCard = ({ solutionId, granted }: Props) => {
    const classes = useStyles();
    const intl = useIntl();
    const account: Account = useAppSelector(getActiveAccount);
    const { solution, isFetching } = useGetSolutionsByAccountIdQuery(account.id, {
        selectFromResult: ({ data, isFetching: isFetchingSolution }) => ({
            isFetching: isFetchingSolution,
            solution: data?.find((s) => s.id === solutionId) ?? ({} as Solution),
        }),
    });
    const { data: solutions } = useGetSolutionsByAccountIdQuery(account.id);
    const [deleteSolution] = useDeleteSolutionByIdMutation();
    const [editSolution] = useUpdateSolutionMutation();

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>(solution.name);

    const isSharedStoreInspectorFeatureEnabled = useSelector((state) =>
        isFeatureEnabled(state)('sharedStoreInspector'),
    );
    const i18n = (id: string) => intl.formatMessage({ id: `solution.${id}` });
    const navigate = useNavigate();
    const onCardClick = () => {
        if (solution.id) {
            navigate(`/solutions/${solutionId}`);
        }
    };

    const checkFunc = async () => {
        const response = await Promise.all([
            fetchWrapper({
                uri: `/accounts/${account.id}/solutions/${solutionId}/data-correction-check`,
                intl,
                functionDispatch: useAppDispatch,
            }),
            fetchWrapper({
                uri: `/accounts/${account.id}/solutions/${solutionId}/aggregation-check`,
                intl,
                functionDispatch: useAppDispatch,
            }),
            fetchWrapper({
                uri: `/accounts/${account.id}/meters-check?solutionId=${solutionId}`,
                intl,
                functionDispatch: useAppDispatch,
            }),
        ]);

        return {
            dataCorrection: response[0].result,
            aggregation: response[1].result,
            usageMetering: response[2].result,
        };
    };

    const onSharedStoreClick = () => {
        navigate(`/solutions/${solutionId}/shared-store`);
    };

    const handleAction =
        (key: string): MouseEventHandler<HTMLButtonElement> =>
        async (event) => {
            event.stopPropagation();

            let checkRes;
            switch (key) {
                case 'delete':
                    checkRes = await checkFunc();
                    if (checkRes.dataCorrection && checkRes.aggregation) {
                        Modal.info({
                            title: i18n('inform_delete_aggregation_data_correction_title'),
                            content: i18n('inform_delete_aggregation_data_correction'),
                            onOk: () => {},
                        });
                    } else if (checkRes.dataCorrection) {
                        Modal.info({
                            title: i18n('inform_delete_aggregation_data_correction_title'),
                            content: i18n('inform_delete_data_correction'),
                            onOk: () => {},
                        });
                    } else if (checkRes.aggregation) {
                        Modal.info({
                            title: i18n('inform_delete_aggregation_data_correction_title'),
                            content: i18n('inform_delete_aggregation'),
                            onOk: () => {},
                        });
                    } else if (checkRes.usageMetering) {
                        Modal.info({
                            // update
                            title: i18n('inform_delete_aggregation_data_correction_title'),
                            content: i18n('inform_delete_usage_metering'),
                            onOk: () => {},
                        });
                    } else {
                        Modal.confirm({
                            title: i18n('remove'),
                            content: <div>{i18n('confirm_remove')}</div>,
                            onOk: () => {
                                deleteSolution(solutionId);
                            },
                            onCancel: () => {
                                /* intentionally empty */
                            },
                            okText: i18n('delete_button'),
                        });
                    }
                    break;
                case 'edit':
                    setShowEditModal(true);
                    break;
                case 'store':
                    onSharedStoreClick();
                    break;
                default:
                    break;
            }
        };

    const toggleModal = () => {
        setShowEditModal(!showEditModal);
    };

    const updateSolution = (name: string, color: string) => {
        editSolution({ id: solutionId, colorHex: color, name });
        setNewName(name);
        toggleModal();
    };

    const Item = ({ value, type }: ItemProps) => (
        <div className={classes.itemWrapper}>
            <div className={classes.itemText}>{i18n(`card_label.${type}`)}</div>
            <div
                className={clsx(classes.itemNumber, {
                    [classes.itemNumberRed]: type === 'failed' && typeof value === 'number' && value > 0,
                })}
            >
                {value}
            </div>
        </div>
    );

    const deleteDisabled = !granted || !solution.id || solutions.length <= 1;
    const actions = [
        <Tooltip
            placement="bottom"
            title={!granted && i18n('unauthorized')}
            arrowPointAtCenter
            style={{ color: 'white' }}
        >
            <Button
                block
                type="text"
                icon={<FontAwesomeIcon icon={icons.solid.faEdit} />}
                disabled={!granted || !solution.id}
                onClick={handleAction('edit')}
                id="editSolution"
                className={`${classes.button} ${!granted || (!solution.id && classes.disabledButtonFix)}`}
            >
                <span className={classes.actionLabel}>
                    <FormattedMessage id="solution.edit_text" />
                </span>
            </Button>
        </Tooltip>,
        <Tooltip placement="bottom" title={!granted && i18n('unauthorized')} arrowPointAtCenter>
            <Button
                block
                type="text"
                icon={<FontAwesomeIcon icon={icons.solid.faTrashAlt} />}
                disabled={deleteDisabled}
                onClick={handleAction('delete')}
                className={`${classes.button} ${deleteDisabled && classes.disabledButtonFix}`}
                id="deleteSolution"
            >
                <span className={classes.actionLabel}>
                    <FormattedMessage id="solution.remove" />
                </span>
            </Button>
        </Tooltip>,
    ];

    if (isSharedStoreInspectorFeatureEnabled) {
        actions.push(
            <Tooltip
                placement="bottom"
                title={<FormattedMessage id="solution.shared_store.title" />}
                arrowPointAtCenter
            >
                <Button
                    block
                    type="text"
                    icon={<FontAwesomeIcon icon={icons.solid.faDraftingCompass} />}
                    disabled={!solution.id}
                    onClick={handleAction('store')}
                    id="sharedStore"
                    className={`${classes.button} ${!solution.id && classes.disabledButtonFix}`}
                >
                    <span className={classes.actionLabel}>
                        <FormattedMessage id="solution.store" />
                    </span>
                </Button>
            </Tooltip>,
        );
    }

    return (
        <>
            <BasicModal
                cancelText={i18n('cancel')}
                errorMsg={i18n('fill_in_solution_name')}
                formTitle={i18n('create_label')}
                modalTitle={i18n('edit_title')}
                isVisible={showEditModal}
                okText={i18n('update_text')}
                placeholder={i18n('add_new_solution_placeholder')}
                onCancel={toggleModal}
                onOk={updateSolution}
                max={MAX_SOLUTION_NAME_LENGTH}
                solutions={solutions}
                solution={solution}
                initialValue={newName}
                useColorPicker
                colorTitle={i18n('color_title')}
                selectedColor={solution.colorHex || AVATAR_COLORS[0]}
                mode={ModalMode.EDIT}
            />
            <Card
                hoverable
                onClick={onCardClick}
                className={clsx(classes.card, { [classes.newCard]: !solution.id })}
                loading={isFetching}
                actions={actions}
            >
                <Card.Meta
                    avatar={
                        <Avatar key={solution.name} style={{ backgroundColor: solution.colorHex || AVATAR_COLORS[0] }}>
                            {solution.name.charAt(0).toUpperCase()}
                        </Avatar>
                    }
                    title={
                        <div data-testid="solution-name" className={classes.cardTitle}>
                            {solution.name}
                        </div>
                    }
                    description={
                        <div className={classes.cardMeta}>
                            <Item value={solution.streamsCount ?? 0} type="streams" />
                            <Item value={solution.streamsStatus?.scheduled ?? 0} type="scheduled" />
                            <Item value={solution.streamsStatus?.running ?? 0} type="running" />
                            <Item value={solution.streamsStatus?.failed ?? 0} type="failed" />
                        </div>
                    }
                    data-testid="solution-card-body"
                />
            </Card>
        </>
    );
};

export default memo(
    SolutionCard,
    (prevProps, nextProps) => prevProps.solutionId === nextProps.solutionId && prevProps.granted === nextProps.granted,
);
