import React, { KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Form, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useIntl } from 'react-intl';
import { set, unset } from 'lodash';
import { getActiveAccountId } from '../../../../../accounts';
import type { Node } from '../../../../../dazzler-types';
import { useGetGroupsByAccountIdQuery } from '../../../../../../services/notifications';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
    classes;
};

const EmailV2Recipient = ({ node, onUpdate, i18nPrefix, classes }: Props) => {
    const intl = useIntl();
    const activeAccount = useSelector(getActiveAccountId);
    const { data: notificationGroups = [] } = useGetGroupsByAccountIdQuery(activeAccount);
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });
    const defaultDestination: string[] = node.params.destination?.split(',').map(String);
    const defaultCC: string[] = node.params.cc?.split(',').map(String);
    const defaultBCC: string[] = node.params.bcc?.split(',').map(String);
    const requireCC = node.params.enableCC;
    const requireBCC = node.params.enableBCC;

    const onCheckboxChecked = ({ target: { checked } }: CheckboxChangeEvent, paramName: string) => {
        set(node.params, paramName, checked);
        onUpdate(node);
    };

    const getNotificationGroup = () => {
        const notificationGroupEmails = {};
        notificationGroups.forEach((group) => {
            notificationGroupEmails[group.name] = group.emails.toString();
        });
        return notificationGroupEmails;
    };

    const onRecipientUpdate = (value: string[], paramName: string) => {
        set(node.params, paramName, value.toString());
        set(node.params, 'notificationGroupEmails', getNotificationGroup());

        if (!value.toString()) {
            unset(node.params, paramName);
        }

        onUpdate(node);
    };

    const shouldBlur = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
            e.currentTarget.focus();
        }
    };

    return (
        <fieldset>
            <legend>{i18n('recipients')}</legend>
            <Form.Item label={i18n('recipients_label')} required>
                <Select
                    mode="tags"
                    onChange={(val) => onRecipientUpdate(val, 'destination')}
                    tokenSeparators={[',']}
                    size="large"
                    defaultValue={defaultDestination}
                    notFoundContent={null}
                    placeholder={<span className={classes.italicPlaceholder}>{i18n('recipients_placeholder')}</span>}
                    onInputKeyDown={shouldBlur}
                >
                    {notificationGroups.map((group) => (
                        <Select.Option value={group.name} key={group.name}>
                            {group.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Checkbox
                    onChange={(checked) => onCheckboxChecked(checked, 'enableCC')}
                    checked={requireCC}
                    className={classes.checkbox}
                >
                    {i18n('cc')}
                </Checkbox>
                {node.params.enableCC && (
                    <Select
                        mode="tags"
                        onChange={(val) => onRecipientUpdate(val, 'cc')}
                        tokenSeparators={[',']}
                        size="large"
                        defaultValue={defaultCC}
                        notFoundContent={null}
                        placeholder={<span className={classes.italicPlaceholder}>{i18n('cc_placeholder')}</span>}
                        onInputKeyDown={shouldBlur}
                    >
                        {notificationGroups.map((group) => (
                            <Select.Option value={group.name} key={group.name}>
                                {group.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
            <Form.Item>
                <Checkbox
                    onChange={(checked) => onCheckboxChecked(checked, 'enableBCC')}
                    checked={requireBCC}
                    className={classes.checkbox}
                >
                    {i18n('bcc')}
                </Checkbox>
                {node.params.enableBCC && (
                    <Select
                        mode="tags"
                        onChange={(val) => onRecipientUpdate(val, 'bcc')}
                        tokenSeparators={[',']}
                        size="large"
                        defaultValue={defaultBCC}
                        notFoundContent={null}
                        placeholder={<span className={classes.italicPlaceholder}>{i18n('bcc_placeholder')}</span>}
                        onInputKeyDown={shouldBlur}
                    >
                        {notificationGroups.map((group) => (
                            <Select.Option value={group.name} key={group.name}>
                                {group.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        </fieldset>
    );
};

export default EmailV2Recipient;
