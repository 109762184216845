import React from 'react';
import { createUseStyles } from 'react-jss';
import { Select, Popover } from 'antd';
import { useIntl } from 'react-intl';
import { getPropertyDescription } from '../utils';
import { ValidationGroupsType, ValidationRule } from '../types';

const styles = (theme) => ({
    selectOverwrite: {
        '&.ant-select, &.ant-input': {
            marginTop: theme.marginXs,
        },
    },
    block: {
        display: 'block',
    },
    popover: {
        maxWidth: 350,
        zIndex: 2000,
    },
});

const useStyles = createUseStyles(styles);

type Props = {
    rule: ValidationRule;
    groups: ValidationGroupsType[];
    onChange: (value: string) => void;
    disabled?: boolean;
};

const ValidationGroups = ({ rule, groups, onChange, disabled = false }: Props) => {
    const classes = useStyles();
    const intl = useIntl();

    const i18n = (id: string) => {
        if (id !== '') {
            return intl.formatMessage({ id: `function_config.transforms.validator.${id}` });
        }

        return '';
    };

    return (
        <Select
            className={classes.selectOverwrite}
            placeholder={i18n('select_validation_placeholder')}
            onChange={onChange}
            value={rule.validationRuleKey}
            dropdownMatchSelectWidth={false}
            showSearch
            disabled={disabled}
        >
            {groups.map((group) => {
                const [{ title, values }] = Object.values(group);

                return (
                    <Select.OptGroup key={title} label={i18n(title)}>
                        {values.map((property) => (
                            <Select.Option key={property} value={property}>
                                <Popover
                                    content={i18n(getPropertyDescription(property))}
                                    placement="right"
                                    mouseEnterDelay={0.2}
                                    overlayClassName={classes.popover}
                                >
                                    <strong className={classes.block}>{property}</strong>
                                </Popover>
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                );
            })}
        </Select>
    );
};

export default ValidationGroups;
