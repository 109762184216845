const parseEnum = (enumData: any) =>
    enumData.map((value) => {
        if (value === 'null') {
            return null;
        }

        return value;
    });

const validationParse = ({ object, property, validationRule, parse }) => ({
    nestedObject() {
        if (object) {
            const { required = [], properties = {} } = parse({ properties: object.properties });
            const uniques = Object.keys(property.properties || {})
                .filter((currentProp) => !Object.keys(properties).some((prop) => prop === currentProp))
                .map((unique) => ({
                    [unique]: property.properties[unique],
                }));
            const merged = Object.keys(properties).map((prop) => ({
                [prop]: {
                    ...properties[prop],
                    ...property.properties?.[prop],
                },
            }));

            return {
                type: 'object',
                properties: Object.assign({}, ...uniques, ...merged),
                required: [...new Set([...required, ...(property.required || [])])],
            };
        }

        return {};
    },
    array: () => ({
        type: 'array',
        items: {
            type: validationRule.validationRuleValue,
        },
    }),
    oneOf: () => ({
        type: 'array',
        items: {
            type: validationRule.validationRuleValue,
        },
    }),
    enum() {
        if (validationRule.validationRuleValue) {
            return {
                [validationRule.validationRuleKey]: parseEnum(validationRule.validationRuleValue),
            };
        }

        return {};
    },
    uniqueItems: () => ({
        [validationRule.validationRuleKey]: validationRule.validationRuleValue.toLocaleLowerCase() === 'yes',
    }),
});

export default validationParse;
