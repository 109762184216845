import React from 'react';
import { Form } from 'antd';
import { Divider } from '@digitalroute-internal/dazzlerjs-react-ui';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import ZuoraEnvironmentItem from '../../common/zuora/ZuoraEnvironmentItem';
import ZuoraMultiEntityItem from '../../common/zuora/ZuoraMultiEntityItem';
import SecretsWalletSelector from '../../common/secrets-wallet/SecretsWalletSelector';
import type { ParametersProps } from '../../../../../dazzler-types';

const styles = (theme) => ({
    fieldSetOverride: {
        '& fieldset': {
            borderWidth: 0,
            paddingBottom: 0,
            marginBottom: 0,
        },
        '& label': {
            fontSize: theme.fontSizeLg,
        },
    },

    dividerOverride: {
        marginLeft: theme.paddingXs,
        marginTop: `${theme.marginSm} !important`,
        marginBottom: `${theme.marginMd} !important`,
    },
});
const useStyles = createUseStyles(styles);

const I18N_PREFIX = 'function_config.writer.zuora_usage_record';

const ZuoraUsageRecordParameters = ({ functionType, node, onUpdate }: ParametersProps): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `${I18N_PREFIX}.${id}` });
    return (
        <Form layout="vertical" className={classes.fieldSetOverride}>
            <ZuoraEnvironmentItem node={node} onUpdate={onUpdate} functionType={functionType} />
            <Divider overrideClass={classes.dividerOverride} />
            <fieldset>
                <legend>{i18n('authentication_legend')}</legend>
                <SecretsWalletSelector node={node} onUpdate={onUpdate} />
            </fieldset>
            <Divider overrideClass={classes.dividerOverride} />
            <ZuoraMultiEntityItem node={node} onUpdate={onUpdate} />
        </Form>
    );
};

export default ZuoraUsageRecordParameters;
