import { Popover } from 'antd';
import ReactMarkdown from 'react-markdown';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@digitalroute-internal/dazzlerjs-react-ui';
import { icons } from '../../../../resources';

const styles = () => ({
    legend: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

type PublicProps = {
    legend: string;
    infoText: string;
};

type Props = PublicProps & {
    classes: any;
};

const LegendWithInfo = ({ classes, legend, infoText }: Props) => (
    <legend>
        <div className={classes.legend}>
            <span>{legend}</span>
            <Popover content={<ReactMarkdown>{infoText}</ReactMarkdown>} placement="bottomLeft">
                <FontAwesomeIcon icon={icons.solid.faInfoCircle} />
            </Popover>
        </div>
    </legend>
);

export default withStyles(styles)(LegendWithInfo);
