import { useState, useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

type RouteMatchCallback = (id: string) => Promise<void>;

export const routeMatchBuilder =
    (currentLocation: string) =>
    async (pathname: string | string[], cb: RouteMatchCallback, findParam: string = ''): Promise<void> => {
        const checkPath = (path: string): boolean => {
            const location = matchPath(path, currentLocation);
            if (location) {
                const id = location.params?.[findParam];
                cb(id);
            }

            return !!location;
        };

        if (Array.isArray(pathname)) {
            pathname.some(checkPath);
        } else {
            checkPath(pathname);
        }
    };

export const providesEntity =
    <T extends string>(tagType: T) =>
    <R extends string>(_result: unknown, _error: FetchBaseQueryError, id: R) =>
        [{ type: tagType, id }];

export const providesList = <R extends { id: string }[], T extends string>(resultsWithIds: R | undefined, tagType: T) =>
    resultsWithIds
        ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
        : [{ type: tagType, id: 'LIST' }];

export const providesListObject = <R extends Record<string, unknown>, T extends string>(
    results: R | undefined,
    tagType: T,
) =>
    results
        ? [{ type: tagType, id: 'LIST' }, ...Object.keys(results).map((key) => ({ type: tagType, id: key }))]
        : [{ type: tagType, id: 'LIST' }];

/**
 * Needed during development to allow hot reloading to replace endpoints.
 * Should be used in *injectEndpoints* for all services.
 */
export const overrideExisting = (): boolean => (module as any).hot?.status() === 'apply';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const provideWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};
