import React, { useEffect, useState } from 'react';
import { get, set } from 'lodash';
import { IntlShape } from 'react-intl';
import {
    Divider,
    Button,
    FormItem,
    icons,
    FieldMapping,
    OptionProp,
    OptionsType,
} from '@digitalroute-internal/dazzlerjs-react-ui';
import { FunctionType, Node } from '../../../../../dazzler-types';
import { getFunctionValidationMessage } from '../../../../../dazzler-intl';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    treeNodeData: any;
    classes: any;
    i18n: any;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => OptionsType[];
    intl: IntlShape;
    isPreviewClicked: boolean;
};

const TESTID_PREFIX = 'SapSubscriptionOptionalField';

const SapSubscriptionOptionalField = ({
    node,
    onUpdate,
    functionType,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
    intl,
    isPreviewClicked,
}: Props) => {
    const initCustomFieldMapping = get(node, 'params.customFieldMappings', []);
    const [customFieldMapping, setCustomFieldMapping] = useState([]);
    const mappingProperties = get(functionType, 'params.definitions.mapping.properties', {});
    const requiredProperties = get(functionType, 'params.definitions.mapping.required', []);
    const [targetFieldDropdownOption, setTargetFieldDropdownOption] = useState([]);

    useEffect(() => {
        const optionalFields = Object.keys(mappingProperties).map((val) =>
            requiredProperties.includes(val)
                ? {}
                : {
                      children: [],
                      key: val,
                      title: val,
                      value: val,
                  },
        );
        setTargetFieldDropdownOption(optionalFields.filter((val) => Object.keys(val).length !== 0));

        setCustomFieldMapping(initCustomFieldMapping);
    }, []);

    useEffect(() => {
        set(node.params, 'customFieldMappings', customFieldMapping);
        onUpdate(node);
    }, [customFieldMapping]);

    const onClickDeleteCustomFieldMapping = (idx: number) => {
        setCustomFieldMapping((prevOptionalRef) => prevOptionalRef.filter((custRef, i) => i !== idx));
    };

    const onClickAddCustomFieldMapping = () => {
        setCustomFieldMapping((prevOptionalRef) => [...prevOptionalRef, { source: undefined, target: undefined }]);
    };

    const onChangeCustomParam = (idx: number, val: string, paramName: string) => {
        const newData = customFieldMapping.map((data, index) => (index === idx ? { ...data, [paramName]: val } : data));
        setCustomFieldMapping(newData);
    };

    return (
        <div className={classes.formItemContainer}>
            <FormItem>
                <legend>{i18n('optional_reference_fields')}</legend>

                {customFieldMapping.map((arrVal, idx) => {
                    // eslint-disable-next-line consistent-return
                    const validateInput = (isTarget: boolean, currentValue, relatedValue) => {
                        if (isPreviewClicked && !currentValue) {
                            // related value is not set, show required message
                            if (!relatedValue) {
                                return getFunctionValidationMessage(intl, 'required');
                            }

                            // related value IS set, show related message
                            const validationObj = {
                                currentFieldName: isTarget
                                    ? i18n('mappings_target_field_name')
                                    : i18n('mappings_source_field_name'),
                                relatedFieldName: isTarget
                                    ? i18n('mappings_source_field_name')
                                    : i18n('mappings_target_field_name'),
                            };
                            return getFunctionValidationMessage(intl, 'related', validationObj);
                        }
                    };
                    const options: OptionProp[] = [
                        {
                            name: 'custom_reference_typecode',
                            label: `* ${i18n('mappings_target_field_label')}`,
                            isSelect: true,
                            selectOption: {
                                isTree: true,
                                optionData: addAddonDataTreeData(arrVal.target, targetFieldDropdownOption),
                                value: arrVal.target ? arrVal.target : undefined,
                                allowAddon: true,
                                allowClear: true,
                                inputAddonProps: {
                                    isAppend: false,
                                    checkDuplicateBeforeAppend: false,
                                    checkDuplicateValue: true,
                                },
                                onChange: (val: string) => onChangeCustomParam(idx, val, 'target'),
                                onOptionsChange: (_newOption, newValue) => {
                                    if (newValue) onChangeCustomParam(idx, newValue, 'target');
                                },
                                'data-testid': `${TESTID_PREFIX}__select-typecode-${idx}`,
                            },
                            hasError: !!validateInput(true, arrVal.target, arrVal.source),
                            errorMessage: validateInput(true, arrVal.target, arrVal.source),
                        },
                        {
                            name: 'custom_reference_id',
                            label: `* ${i18n('mappings_source_field_label')}`,
                            isSelect: true,
                            selectOption: {
                                isTree: true,
                                optionData: addAddonDataTreeData(arrVal.source, treeNodeData),
                                value: arrVal.source ? arrVal.source : undefined,
                                allowAddon: true,
                                allowClear: true,
                                inputAddonProps: {
                                    isAppend: false,
                                    checkDuplicateBeforeAppend: false,
                                    checkDuplicateValue: true,
                                },
                                onChange: (val: string) => onChangeCustomParam(idx, val, 'source'),
                                onOptionsChange: (_newOption, newValue) => {
                                    if (newValue) onChangeCustomParam(idx, newValue, 'source');
                                },
                                'data-testid': `${TESTID_PREFIX}__select-id-${idx}`,
                            },
                            hasError: !!validateInput(false, arrVal.source, arrVal.target),
                            errorMessage: validateInput(false, arrVal.source, arrVal.target),
                        },
                    ];

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={classes.spacingTop} key={`optionalFieldIdx_${idx}`}>
                            <FieldMapping
                                options={options}
                                onDelete={() => onClickDeleteCustomFieldMapping(idx)}
                                data-testid={`${TESTID_PREFIX}_FieldMapping`}
                            />
                        </div>
                    );
                })}

                <div className={classes.spacingTop}>
                    <Button
                        type="dashed"
                        text={i18n('add_optional_reference')}
                        icon
                        iconName={icons.regular.faPlus}
                        onClick={() => onClickAddCustomFieldMapping()}
                        data-testid={`${TESTID_PREFIX}__button-add`}
                    />
                </div>
            </FormItem>

            <Divider />
        </div>
    );
};

export default SapSubscriptionOptionalField;
