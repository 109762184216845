/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import type { ComponentClass } from 'react';
import { useBreakpoint } from '@digitalroute-internal/dazzlerjs-react-ui';

export const withBreakpoints = (Component: ComponentClass) => (props) => {
    const breakpoints = useBreakpoint();

    return <Component {...props} breakpoints={breakpoints} />;
};
