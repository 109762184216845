import * as React from 'react';
import { useIntl } from 'react-intl';
import LegendWithInfo from './LegendWithInfo';

const OutputFileInfoLegend = () => {
    const intl = useIntl();
    return (
        <LegendWithInfo
            legend={intl.formatMessage({ id: 'output_file_info_legend.legend' })}
            infoText={intl.formatMessage({ id: 'output_file_info_legend.popup' })}
        />
    );
};

export default OutputFileInfoLegend;
