import { DATABASE_TYPES } from '../../../../../dazzler-constants';

export const mapValToLang = (val: string): string => {
    const selectedType = DATABASE_TYPES.find(({ value }) => value === val);

    if (selectedType) {
        return selectedType.language;
    }
    return '';
};

export const mapValToPort = (val: string): number => {
    const selectedType = DATABASE_TYPES.find(({ value }) => value === val);

    if (selectedType) {
        return selectedType.defaultPort;
    }
    return 9999;
};
