import React, { MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse } from 'antd';
import { useIntl } from 'react-intl';
import QueueAnim from 'rc-queue-anim';
import ObjectView from './ObjectView';
import { icons } from '../../../../../../../resources';
import { DataObject } from '../types';
import { useValidatorState } from '../context';

const styles = (theme) => ({
    buttonDiv: {
        display: 'flex',
        justifyContent: 'start',
        marginBottom: theme.marginXs,
        '& > button': {
            position: 'static',
        },
    },
    closeBtn: {
        cursor: 'pointer',
        position: 'relative',
        float: 'right',
    },
    container: {
        marginBottom: theme.marginXs,
        '&:last-child': {
            marginBottom: theme.marginLg,
        },
    },
    addObjIcon: {
        marginRight: theme.marginXs,
    },
    roundBorder: {
        backgroundColor: theme.white,
        height: 16,
        margin: 0,
        padding: '6px 7px',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: theme.brand02,
        color: theme.neutral04,
        borderRadius: 50,
        alignItems: 'center',
        '&button': {
            width: 32,
            height: 32,
            boxShadow: 'unset',
        },
        '&:disabled, &:disabled:hover': {
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
        '&:disabled *, &:disabled:hover *': {
            color: theme.neutral04,
        },
        '&:hover *': {
            color: theme.brand05,
            borderColor: theme.brand05,
        },
    },
});

const useStyles = createUseStyles(styles);

const i18n = (id: string) => useIntl().formatMessage({ id: `function_config.transforms.validator.${id}` });

type Props = {
    disabled?: boolean;
};

const ObjectsView = ({ disabled = false }: Props) => {
    const classes = useStyles();
    const { objects, dispatch, form } = useValidatorState();
    const panelsToExpand = objects.filter((o) => o.expand).map((o) => o.key);

    const addObject = () => {
        form.validateFields();
        dispatch({ type: 'ADD_OBJECT' });
    };

    const onCollapseChange = (keys: DataObject['key'][]) => {
        dispatch({
            type: 'EXPAND_OBJECT',
            payload: keys,
        });
    };

    const removeObject = (key: DataObject['key']) => (e: MouseEvent<SVGElement>) => {
        e.stopPropagation();
        form.validateFields();

        dispatch({
            type: 'DELETE_OBJECT',
            payload: key,
        });
    };

    const renderObjects = objects.map((object) => (
        <div key={object.key} className={classes.container}>
            <Collapse activeKey={panelsToExpand} onChange={onCollapseChange}>
                <Collapse.Panel
                    key={object.key}
                    header={object.objectName}
                    extra={
                        !disabled && (
                            <FontAwesomeIcon
                                icon={icons.regular.faTrash}
                                onClick={removeObject(object.key)}
                                className={`${classes.closeBtn} ${classes.roundBorder}`}
                            />
                        )
                    }
                >
                    <ObjectView key={object.key} object={object} disabled={disabled} />
                </Collapse.Panel>
            </Collapse>
        </div>
    ));

    return (
        <fieldset>
            <legend>{i18n('objects')}</legend>
            {objects.length > 0 && (
                <QueueAnim type={['right', 'left']} leaveReverse>
                    {renderObjects}
                </QueueAnim>
            )}
            <div className={classes.buttonDiv}>
                <Button type="dashed" onClick={addObject} disabled={disabled}>
                    <FontAwesomeIcon className={classes.addObjIcon} icon={icons.regular.faPlus} />
                    {i18n('add_object')}
                </Button>
            </div>
        </fieldset>
    );
};

export default ObjectsView;
