import React from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'antd';
import { set } from 'lodash';
import FileFormatConfigurator, { deleteFileFormatConfig } from '../FileFormatConfigurator';
import OutputFilenameConfigurator, { deleteFilenameConfig } from '../OutputFilenameConfigurator';
import type { Node } from '../../../../../dazzler-types';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
    classes;
};

const EmailV2AttachFile = ({ node, onUpdate, i18nPrefix, classes }: Props) => {
    const intl = useIntl();
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });
    const onChangeRecordFile = (recordFileToggle: boolean) => {
        if (!recordFileToggle) {
            deleteFilenameConfig(node);
            deleteFileFormatConfig(node);
        }
        set(node.params, 'attachFile', recordFileToggle);
        onUpdate(node);
    };

    return (
        <fieldset>
            <>
                <div className={classes.grid}>
                    <legend>{i18n('attach_records')}</legend>
                    <div className={classes.rightSection}>
                        <Switch
                            className={classes.rightElement}
                            onChange={onChangeRecordFile}
                            checked={node.params.attachFile}
                        />
                    </div>
                </div>
            </>
            {node.params.attachFile && (
                <>
                    <OutputFilenameConfigurator node={node} onUpdate={onUpdate} />
                    <FileFormatConfigurator node={node} onUpdate={onUpdate} isWriter />
                </>
            )}
        </fieldset>
    );
};

export default EmailV2AttachFile;
