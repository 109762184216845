import React, { useMemo, createContext, useReducer, ReactNode, Dispatch } from 'react';
import { AnyAction, combineReducers } from 'redux';
import * as reducers from './reducers';
import { StreamInstance } from '../../../../packages/dazzler-types';
import { ActionType } from '../types';

type Props = {
    children: ReactNode;
};

export type State = {
    search: reducers.search.State;
    modal: reducers.modal.State;
    action: reducers.action.State;
    selected: reducers.selected.State;
    importStream: reducers.importStream.State;
};

type ContextState = State & {
    localDispatch: Dispatch<AnyAction>;
    selectedStream: StreamInstance;
};

const initialState: State = {
    search: reducers.search.initialState,
    modal: reducers.modal.initialState,
    action: reducers.action.initialState,
    selected: reducers.selected.initialState,
    importStream: reducers.importStream.initialState,
};

const reducer = combineReducers<State>({
    search: reducers.search.reducer,
    modal: reducers.modal.reducer,
    action: reducers.action.reducer,
    selected: reducers.selected.reducer,
    importStream: reducers.importStream.reducer,
});

export const StreamsListContext = createContext<ContextState | undefined>(undefined);

export const StreamsListProvider = ({ children }: Props) => {
    const [state, localDispatch] = useReducer(reducer, initialState);
    const selectedStream: StreamInstance =
        state.action.type === ActionType.StreamMenu ? state.action.stream : state.selected[0];

    const contextValue = useMemo(
        () => ({
            ...state,
            selectedStream,
            localDispatch,
        }),
        [state, localDispatch],
    );

    return <StreamsListContext.Provider value={contextValue}>{children}</StreamsListContext.Provider>;
};
