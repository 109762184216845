import React from 'react';
import { get, set, unset } from 'lodash';
import { Checkbox, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TextInputItem, NumberInputItem } from '../../configurator-fields';
import type { FunctionType, Node } from '../../../../../dazzler-types';
import SecretsWalletConfigurator from '../secrets-wallet/SecretsWalletConfigurator';
import { SECRETS_WALLET_TYPE_PATH } from '../../../../../dazzler-constants';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    i18nPrefix: string;
    classes;
};

const EmailV2SMTPSetting = ({ node, onUpdate, functionType, i18nPrefix, classes }: Props) => {
    const intl = useIntl();
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });
    const secretType = get(functionType, SECRETS_WALLET_TYPE_PATH, null);

    const onSecretsWalletToggle = (secretsWalletToggle) => {
        if (secretsWalletToggle) {
            unset(node.params, 'smtpUser');
            unset(node.params, 'smtpPassword');
        } else {
            unset(node.params, 'secretsWalletEntry');
        }

        onUpdate(node);
    };

    const onChangeSmtpTLS = ({ target: { checked } }: CheckboxChangeEvent) => {
        set(node.params, 'smtpTLS', checked);
        onUpdate(node);
    };

    return (
        <fieldset>
            <legend>{i18n('smtp_settings')}</legend>
            <Row>
                <Col span={12}>
                    <TextInputItem
                        label={i18n('host_name')}
                        path="smtpHost"
                        required
                        node={node}
                        onUpdate={onUpdate}
                        placeholder={i18n('host_name_placeholder')}
                    />
                </Col>
                <Col span={12}>
                    <NumberInputItem
                        label={i18n('port')}
                        min={1}
                        node={node}
                        path="smtpPort"
                        onUpdate={onUpdate}
                        defaultValue={node.params.smtpPort ? node.params.smtpPort : 465}
                        className={classes.smtpPort}
                    />
                </Col>
            </Row>
            <SecretsWalletConfigurator
                legend={i18n('secrets_wallet.legend')}
                node={node}
                onUpdate={onUpdate}
                type={secretType}
                i18nPrefix={i18nPrefix}
                onToggle={onSecretsWalletToggle}
                useFieldset={false}
            />
            <Checkbox onChange={onChangeSmtpTLS} checked={node.params.smtpTLS} className={classes.tls}>
                {i18n('enable_tls')}
            </Checkbox>
        </fieldset>
    );
};

export default EmailV2SMTPSetting;
