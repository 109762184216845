import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import {
    Form,
    FormItem,
    Input,
    Button,
    Divider,
    Select,
    OptionsType,
    CheckboxGroup,
    Switch,
    DateInput,
    CheckboxGroupItemProps,
} from '@digitalroute-internal/dazzlerjs-react-ui';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { isEqual } from 'lodash';
import { icons } from '../../resources';
import { LogLevel } from '../../dazzler-types';
import { getDocUrl } from '../../docs';
import { RIGHT_SIDEBAR_WIDTH, TIMESTAMP_FORMAT } from '../../dazzler-constants';

const styles = (theme) => ({
    sectionHeader: {
        height: theme.drawerHeaderCloseSize,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: theme.marginLg,
        fontSize: theme.fontSizeXl,
    },
    marginZero: {
        margin: 0,
    },
    sectionTitle: {
        align: 1,
    },
    grid: {
        padding: `${theme.paddingXs} 0px ${theme.paddingXs} ${theme.paddingLg}`,
        fontSize: theme.fontSizeLg,
        fontWeight: 'bold',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginLg,
    },
    switchText: {
        fontWeight: 'normal',
        marginLeft: theme.marginXs,
    },
    filterForm: {
        marginBottom: '18%',
        '& .ant-row': {
            margin: `0px ${theme.marginLg}`,
        },
    },
    noMargin: {
        '& .ant-row': {
            margin: 0,
        },
    },
    dateMarginFrom: {
        '& .ant-row': {
            marginLeft: theme.marginLg,
            marginRight: theme.marginXs,
        },
    },
    dateMarginTo: {
        '& .ant-row': {
            marginLeft: theme.marginXs,
            marginRight: theme.marginLg,
        },
    },
    sider: {
        boxShadow: theme.shadow1Left,
        background: theme.white,
        height: 'fit-content',
        '& .ant-layout-sider-zero-width': {
            display: 'none',
        },
        '& .ant-layout-sider-children': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    siderFooter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flex: 1,
        width: '360px',
        bottom: '0',
        position: 'fixed',
        background: theme.white,
        [`@media (max-width: ${theme.screenMd})`]: {
            width: '100%',
        },
    },
    filterFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .ant-btn': {
            margin: `${theme.marginXs} ${theme.marginMd}`,
        },
    },
    dividerMargin: {
        marginBottom: `${theme.marginXs}`,
    },
    checkboxItem: {
        '& .ant-checkbox-group-item': {
            padding: '6px 0',
        },
    },
    hidden: {
        display: 'none',
    },
    messageDescriptionContainer: {
        marginLeft: theme.paddingXs,
        color: theme.neutral04,
        '& a': {
            textDecoration: 'underline',
        },
    },
    checkboxContainer: {
        '& .ant-form-item-control-input-content > div': {
            padding: 0,
        },
    },
});
const useStyles = createUseStyles(styles);

export type FilterValues = {
    message?: string;
    executionId?: string;
    replicaId?: string;
    datePeriod?: string;
    date?: {
        from: string;
        to: string;
    };
    severity?: LogLevel[];
    origin?: string[];
    nodeName?: string[];
};

const LogFilterSidebar = ({
    isFilterVisible,
    setIsFilterVisible,
    filterApplied,
    setFilterApplied,
    initFilterValues,
    onFilterChange,
    isClearFilter,
    setIsClearFilter,
    streamNodes,
    isDeploymentReplicasEnabled,
    severityCheckedValue,
    setSeverityCheckedValue,
    originCheckedValue,
    setOriginCheckedValue,
    setPagination,
    i18n,
}): JSX.Element => {
    const collapsed = !isFilterVisible;
    const siderWidth = window.innerWidth < 768 ? '100%' : RIGHT_SIDEBAR_WIDTH;
    const width = collapsed ? 0 : siderWidth;
    const classes = useStyles();
    const [filterValues, setFilterValues] = useState<FilterValues>({});
    const [useAdvancedSearch, setUseAdvancedSearch] = useState<boolean>(false);
    const [useCustomDate, setUseCustomDate] = useState<boolean>(false);
    const [useAdvancedOrigin, setUseAdvancedOrigin] = useState<boolean>(false);
    const [lastSubmittedMessage, setLastSubmittedMessage] = useState('');
    const [lastExecutionId, setLastExecutionId] = useState('');
    const [lastReplicaId, setLastReplicaId] = useState('');

    const periodOptionData: OptionsType[] = [
        {
            title: i18n('filter.last_15_min'),
            value: 'MIN_15',
            key: 'last_15_min',
        },
        {
            title: i18n('filter.last_hour'),
            value: 'MIN_60',
            key: 'last_hour',
        },
        {
            title: i18n('filter.last_day'),
            value: 'HOUR_24',
            key: 'last_day',
        },
        {
            title: i18n('filter.last_2_day'),
            value: 'HOUR_48',
            key: 'last_2_day',
        },
        {
            title: i18n('filter.last_week'),
            value: 'DAY_7',
            key: 'last_week',
        },
        {
            title: i18n('filter.last_month'),
            value: 'DAY_30',
            key: 'last_month',
        },
        {
            title: i18n('filter.custom'),
            value: 'CUSTOM',
            key: 'custom',
        },
    ];
    const severityCheckboxOptions: CheckboxGroupItemProps[] = [
        { value: LogLevel.Info, label: i18n('filter.information') },
        { value: LogLevel.Warning, label: i18n('filter.warning') },
        { value: LogLevel.Error, label: i18n('filter.error') },
    ];
    const originValue: CheckboxGroupItemProps[] = [
        { value: 'readers', label: i18n('filter.collectors') },
        { value: 'transforms', label: i18n('filter.processors') },
        { value: 'writers', label: i18n('filter.forwarders') },
    ];
    const advancedOriginValue = streamNodes?.map(({ name }) => ({ value: name, label: name }));
    useEffect(() => {
        if (isClearFilter) {
            setFilterValues({});
            setIsClearFilter(false);
        }
    }, [isClearFilter]);

    useEffect(() => {
        setOriginCheckedValue([]);
    }, [useAdvancedOrigin]);

    const disabledDate = (current, isFrom) =>
        current &&
        (isFrom ? current > moment(filterValues.date.to).add(1, 'day') : current < moment(filterValues.date.from));

    const messageDescriptionMessage = () => (
        <p className={classes.messageDescriptionContainer}>
            {`${i18n('filter.message.regex_information')} `}
            <a href={getDocUrl('logging')} target="_blank" rel="noreferrer">
                {i18n('filter.message.documentation')}
            </a>
        </p>
    );

    const handlePressEnterMessage = () => {
        if (filterValues.message !== lastSubmittedMessage) {
            setFilterApplied(Object.keys(filterValues).length > 0);
            onFilterChange(filterValues);
            setPagination((currPagination) => ({ ...currPagination, current: 1 }));
            setLastSubmittedMessage(filterValues.message);
        }
    };

    const handlePressEnterExecutionId = () => {
        if (filterValues.executionId !== lastExecutionId) {
            setFilterApplied(Object.keys(filterValues).length > 0);
            onFilterChange(filterValues);
            setPagination((currPagination) => ({ ...currPagination, current: 1 }));
            setLastExecutionId(filterValues.message);
        }
    };

    const handlePressEnterReplicaId = () => {
        if (filterValues.replicaId !== lastReplicaId) {
            setFilterApplied(Object.keys(filterValues).length > 0);
            onFilterChange(filterValues);
            setPagination((currPagination) => ({ ...currPagination, current: 1 }));
            setLastReplicaId(filterValues.message);
        }
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={!isFilterVisible}
            collapsedWidth="0"
            width={width}
            breakpoint="lg"
            theme="light"
            trigger={null}
            className={classes.sider}
        >
            <div className={classes.sectionHeader}>
                <div className={classes.sectionTitle}> {i18n('filter.filter')} </div>
                <Button
                    type="link"
                    icon
                    iconName={icons.regular.faXmark}
                    onClick={() => {
                        setIsFilterVisible(false);
                    }}
                />
            </div>
            <Divider overrideClass={classes.marginZero} />

            <div className={`${classes.filterForm} ${isFilterVisible ? '' : classes.hidden}`}>
                <Form layout="vertical">
                    <div className={classes.grid}>
                        <div>{i18n('filter.search')}</div>
                        <div className={classes.rightSection}>
                            <Switch
                                onChange={(checked) => {
                                    setUseAdvancedSearch(checked);
                                    if (!checked) {
                                        setFilterValues((current) => {
                                            const { executionId, replicaId, ...rest } = current;
                                            return rest;
                                        });
                                    }
                                }}
                                checked={useAdvancedSearch}
                            />
                            <div className={classes.switchText}>{i18n('filter.advanced')}</div>
                        </div>
                    </div>
                    <FormItem label={i18n('filter.message')}>
                        <Input
                            value={filterValues.message}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                setFilterValues((current) => {
                                    const { message, ...rest } = current;
                                    return ev.target.value === '' ? rest : { message: ev.target.value, ...rest };
                                });
                            }}
                            onPressEnter={handlePressEnterMessage}
                        />
                        {messageDescriptionMessage()}

                        {!useAdvancedSearch && <Divider overrideClass={classes.dividerMargin} />}
                    </FormItem>
                    {useAdvancedSearch && (
                        <>
                            <FormItem label={i18n('filter.execution_id')}>
                                <Input
                                    value={filterValues.executionId}
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        setFilterValues((current) => {
                                            const { executionId, ...rest } = current;
                                            return ev.target.value === ''
                                                ? rest
                                                : { executionId: ev.target.value, ...rest };
                                        });
                                    }}
                                    onPressEnter={handlePressEnterExecutionId}
                                />
                                {!isDeploymentReplicasEnabled && <Divider overrideClass={classes.dividerMargin} />}
                            </FormItem>
                            {isDeploymentReplicasEnabled && (
                                <FormItem label={i18n('filter.replica_id')}>
                                    <Input
                                        value={filterValues.replicaId}
                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterValues((current) => {
                                                const { replicaId, ...rest } = current;
                                                return ev.target.value === ''
                                                    ? rest
                                                    : { replicaId: ev.target.value, ...rest };
                                            });
                                        }}
                                        onPressEnter={handlePressEnterReplicaId}
                                    />
                                    <Divider overrideClass={classes.dividerMargin} />
                                </FormItem>
                            )}
                        </>
                    )}
                    <div>
                        <div className={classes.grid}>
                            <div>{i18n('filter.date')}</div>
                        </div>
                        <FormItem label={i18n('filter.select_period')}>
                            <Select
                                onChange={(value: string) => {
                                    setUseCustomDate(value === 'CUSTOM');
                                    setFilterValues((current) => {
                                        const { datePeriod, ...rest } = current;
                                        return value === '' ? rest : { datePeriod: value, ...rest };
                                    });
                                }}
                                value={filterValues.datePeriod || undefined}
                                optionData={periodOptionData}
                                isTree={false}
                            />
                        </FormItem>
                        {useCustomDate && (
                            <FormItem label={i18n('filter.from')}>
                                <DateInput
                                    onChange={(_date, value) => {
                                        setFilterValues({
                                            ...filterValues,
                                            date: { ...filterValues.date, from: value },
                                        });
                                    }}
                                    format={TIMESTAMP_FORMAT}
                                    showTime
                                    value={filterValues?.date?.from ? moment(filterValues.date.from) : undefined}
                                    disabledDate={(current) =>
                                        filterValues?.date?.to ? disabledDate(current, true) : undefined
                                    }
                                />
                            </FormItem>
                        )}
                        {useCustomDate && (
                            <FormItem label={i18n('filter.to')}>
                                <DateInput
                                    onChange={(_date, value) => {
                                        setFilterValues({
                                            ...filterValues,
                                            date: { ...filterValues.date, to: value },
                                        });
                                    }}
                                    format={TIMESTAMP_FORMAT}
                                    showTime
                                    value={filterValues?.date?.to ? moment(filterValues.date.to) : undefined}
                                    disabledDate={(current) =>
                                        filterValues?.date?.from ? disabledDate(current, false) : undefined
                                    }
                                />
                            </FormItem>
                        )}

                        <Divider overrideClass={classes.dividerMargin} />
                    </div>
                    <div className={classes.grid}>
                        <div>{i18n('filter.severity')}</div>
                    </div>
                    <div className={classes.checkboxContainer}>
                        <FormItem>
                            <CheckboxGroup
                                options={severityCheckboxOptions}
                                onChange={(value: LogLevel[]) => {
                                    setSeverityCheckedValue(value);
                                    setFilterValues((current) => {
                                        const { severity, ...rest } = current;
                                        return value.length ? { severity: value, ...rest } : rest;
                                    });
                                }}
                                checkedValues={severityCheckedValue}
                                overrideClass={classes.checkboxItem}
                            />
                            <Divider overrideClass={classes.dividerMargin} />
                        </FormItem>
                    </div>

                    <div className={classes.grid}>
                        <div>{i18n('filter.origin')}</div>
                        <div className={classes.rightSection}>
                            <Switch
                                onChange={(checked) => {
                                    setUseAdvancedOrigin(checked);
                                    setFilterValues((current) => {
                                        const { origin, nodeName, ...rest } = current;
                                        return rest;
                                    });
                                    setOriginCheckedValue([]);
                                }}
                                checked={useAdvancedOrigin}
                            />
                            <div className={classes.switchText}>{i18n('filter.advanced')}</div>
                        </div>
                    </div>
                    <div className={classes.checkboxContainer}>
                        <FormItem>
                            <CheckboxGroup
                                options={useAdvancedOrigin ? advancedOriginValue : originValue}
                                onChange={(value: string[]) => {
                                    setOriginCheckedValue(value);
                                    setFilterValues((current) => {
                                        const { origin, nodeName, ...rest } = current;
                                        const paramName = useAdvancedOrigin ? 'nodeName' : 'origin';
                                        return value.length ? { [paramName]: value, ...rest } : rest;
                                    });
                                }}
                                checkedValues={originCheckedValue}
                                overrideClass={classes.checkboxItem}
                            />
                        </FormItem>
                    </div>
                </Form>
            </div>

            <div className={classes.siderFooter}>
                <Divider overrideClass={classes.marginZero} />
                <div className={classes.filterFooter}>
                    {filterApplied && (
                        <Button
                            text={i18n('filter.filter_clear')}
                            onClick={() => {
                                setFilterApplied(false);
                                setFilterValues({});
                                onFilterChange({});
                                setSeverityCheckedValue([]);
                                setOriginCheckedValue([]);
                                setPagination((currPagination) => ({ ...currPagination, current: 1 }));
                            }}
                        />
                    )}
                    <Button
                        text={i18n('filter.filter')}
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            setFilterApplied(Object.keys(filterValues).length > 0);
                            onFilterChange(filterValues);
                            setPagination((currPagination) => ({ ...currPagination, current: 1 }));
                        }}
                        disabled={isEqual(filterValues, initFilterValues)}
                    />
                </div>
            </div>
        </Layout.Sider>
    );
};

export default LogFilterSidebar;
