import { Node, Kvp } from '../../../../../dazzler-types';

export const getSecretType = (params: any, defaultValue = null) => {
    const {
        properties: {
            secretsWalletEntry: {
                properties: { secretType: secretTypeValues },
            },
        },
    } = params;

    return secretTypeValues.const || defaultValue;
};
export const getFileCollectionStrategyDefinitions = (params: any, defaultValue = {}) => {
    const {
        properties: {
            fileCollectionStrategy: { definitions: definitionsValues },
        },
    } = params;

    return definitionsValues || defaultValue;
};
export const hasParam = (n: Node, prop: string): boolean => Object.prototype.hasOwnProperty.call(n.params, prop);
export const transformObjToArr = (obj: Array<Kvp>) => Object.keys(obj).map((k) => ({ key: k, value: obj[k] }));
export const transformArrToObj = (array: Array<Kvp>) =>
    array.reduce((obj, item) => {
        if (item.key) {
            // eslint-disable-next-line
            obj[item.key] = item.value;
        }
        return obj;
    }, {});
