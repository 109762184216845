import React from 'react';
import { Form, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { set, unset } from 'lodash';
import { Node } from '../../../../../dazzler-types';

type Props = {
    node: Node;
    classes: any;
    i18n: any;
    onUpdate: (Node: Node) => void;
};

function ZuoraFileForwarderRequestComponent({ node, classes, i18n, onUpdate }: Props) {
    const { useActiveRating } = node.params;

    const setActiveRating = ({ target: { checked } }: CheckboxChangeEvent) => {
        set(node.params, 'useActiveRating', checked);
        unset(node.params, 'fieldMappings');
        unset(node.params, 'customFieldMappings');
        onUpdate(node);
    };

    const boldText = (text: string) => <b>{text}</b>;

    const zuoraBannerMessage = () => {
        const href = useActiveRating
            ? 'https://www.zuora.com/developer/archive/active-rating/active-rating-api/#operation/POST_ImportUsageFile'
            : 'https://www.zuora.com/developer/api-references/api/operation/POST_Usage/';
        return (
            <>
                {i18n(useActiveRating ? 'banner_message_pre_text_active' : 'banner_message_pre_text', {
                    bold: boldText,
                    link: (
                        <a href={href} target="_blank" rel="noreferrer">
                            {i18n(useActiveRating ? 'banner_message_link_active' : 'banner_message_link')}
                        </a>
                    ),
                })}
            </>
        );
    };

    return (
        <>
            <legend>{i18n('request_legend')}</legend>
            <div>
                <Form.Item>
                    <Checkbox
                        data-testid="zuora_req_item_checkbox"
                        onChange={setActiveRating}
                        checked={useActiveRating}
                    >
                        {i18n('use_active_rating')}
                    </Checkbox>
                </Form.Item>
            </div>
            <div className={classes.bannerContainer}>{zuoraBannerMessage()}</div>
        </>
    );
}

export default ZuoraFileForwarderRequestComponent;
