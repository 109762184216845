export const DATABASE_I18N_PREFIX = 'function_config.generic.database';
export const DATABASE_TYPES = [
    {
        value: 'mysql2', // The value that we set in the storable, matching what the knex 3pp expects
        language: 'mysql', // The value that we set in the CodeEditor
        text: 'MySQL', // Text shown in the dropdown
        defaultPort: 3306, // Default port of the Db
    },
    {
        value: 'pg',
        language: 'pgsql',
        text: 'PostgreSQL',
        defaultPort: 5432,
    },
    {
        value: 'mssql',
        language: 'sql',
        text: 'Microsoft SQL',
        defaultPort: 1433,
    },
];
export const CACHE_ENABLED_PATH = 'cache';
export const MAX_CACHE_ITEMS_PATH = 'cache.maxCacheItems';
export const MAX_CACHE_SECONDS_PATH = 'cache.maxCacheSeconds';
export const DEFAULT_MAX_CACHE_ITEMS = 100;
export const DEFAULT_MAX_CACHE_SECONDS = 300;
export const MIN_MAX_CACHE_ITEMS = 0;
export const MIN_MAX_CACHE_SECONDS = 1;
