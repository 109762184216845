import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import type { Solution } from '../../services/solutions';

export interface SolutionsState {
    activeSolution: Solution;
}

const initialState = {
    activeSolution: null,
} as SolutionsState;

export const solutionsSlice = createSlice({
    name: 'solutions',
    initialState,
    reducers: {
        setActiveSolution: (state, action: PayloadAction<Solution>) => {
            state.activeSolution = action.payload;
        },
    },
});

export const { setActiveSolution } = solutionsSlice.actions;

export const selectActiveSolution = (state: RootState) => state.solutions.activeSolution;
