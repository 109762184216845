import { Reducer } from 'react';
import { Property, ValidationRule, CreatePropertyProps, UpdateProperty, UpdatePropertyValidation } from '../types';
import { createProperty, createValidationRule, defaultValues } from '../utils';

export type Action =
    | { type: 'ADD_PROPERTY'; payload?: CreatePropertyProps }
    | { type: 'ADD_PROPERTY_VALIDATION'; payload: Property }
    | { type: 'DELETE_PROPERTY'; payload: Property['key'] }
    | { type: 'DELETE_PROPERTY_VALIDATION'; payload: ValidationRule }
    | { type: 'UPDATE_PROPERTY'; payload: { key: Property['key'] } & UpdateProperty }
    | { type: 'UPDATE_PROPERTY_VALIDATION'; payload: { rule: ValidationRule } & UpdatePropertyValidation }
    | { type: 'EXPAND_PROPERTY'; payload: Property['key'][] };

export type State = {
    properties: Property[];
};

export const initialState: State = {
    properties: [],
};

export const reducer: Reducer<State, Action> = (state = initialState, action): State => {
    switch (action.type) {
        case 'ADD_PROPERTY':
            return {
                ...state,
                properties: [...state.properties, createProperty()],
            };
        case 'ADD_PROPERTY_VALIDATION': {
            const properties = [...state.properties];

            properties
                .find((property) => property.key === action.payload.key)
                .validationRules.push(
                    createValidationRule({
                        propertyKey: action.payload.key,
                    }),
                );

            return { ...state, properties };
        }
        case 'DELETE_PROPERTY':
            return {
                ...state,
                properties: [...state.properties.filter((property) => property.key !== action.payload)],
            };
        case 'DELETE_PROPERTY_VALIDATION': {
            const properties = [...state.properties];
            const propertyIndex = properties.findIndex((property) => property.key === action.payload.propertyKey);
            const updatedValidationRules = properties[propertyIndex].validationRules.filter(
                (rule) => rule.key !== action.payload.key,
            );

            properties[propertyIndex].validationRules = updatedValidationRules;

            return { ...state, properties };
        }
        case 'UPDATE_PROPERTY': {
            const properties = [...state.properties];
            const property = properties.find((prop) => prop.key === action.payload.key);

            property[action.payload.field as string] = action.payload.value;

            return {
                ...state,
                properties,
            };
        }
        case 'UPDATE_PROPERTY_VALIDATION': {
            const properties = [...state.properties];
            const validationRule = properties
                .find((property) => property.key === action.payload.rule.propertyKey)
                .validationRules.find((rule) => rule.key === action.payload.rule.key);

            validationRule[action.payload.field as string] = action.payload.value;

            if (action.payload.field === 'validationRuleKey') {
                validationRule.validationRuleValue = defaultValues[action.payload.value];
            }

            return { ...state, properties };
        }
        case 'EXPAND_PROPERTY':
            return {
                ...state,
                properties: state.properties.map((property) => {
                    if (action.payload.includes(property.key)) {
                        return {
                            ...property,
                            expand: true,
                        };
                    }

                    return {
                        ...property,
                        expand: false,
                    };
                }),
            };
        default:
            return state;
    }
};
