import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import { RadioGroup, RadioOptionType } from '@digitalroute-internal/dazzlerjs-react-ui';
import Form, { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { set } from 'lodash';
import { createUseStyles } from 'react-jss';
import { Node } from '../../../../../dazzler-types';
import { ActionOnError } from './ZuoraEnum';

const styles = (theme: Record<string, any>) => ({
    timeoutFormItem: {
        marginBottom: 0,
        display: 'flex',
        maxWidth: 300,
    },
    textWithMargin: {
        marginLeft: theme.marginXs,
        marginBottom: theme.marginSm,
        fontSize: '13px',
        maxWidth: 300,
        color: theme.neutral04,
    },
    radioGroupContainer: {
        '& > div': {
            padding: 0,
        },
    },
});

type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18n: (id: string, prefix?: string, values?: any) => string;
};

const I18N_GENERIC_PREFIX = 'function_config.generic.zuora';
const I18N_ZUORA_QUERY_PREFIX = 'function_config.transforms.zuora_query';
const DEFAULT_WAIT_TIMEOUT_SECONDS = 20;
const TEST_ID = 'zuora_query_opt_settings_item';

function ZuoraQueryOperationalSettings({ node, onUpdate, i18n }: Props) {
    const useStyles = createUseStyles(styles);
    const classes = useStyles();
    const [form] = Form.useForm();

    const radioOptions: Array<RadioOptionType> = [
        {
            label: i18n('ignore_label', I18N_ZUORA_QUERY_PREFIX),
            value: ActionOnError.IGNORE_ERROR,
        },
        {
            label: i18n('stop_stream_label', I18N_ZUORA_QUERY_PREFIX),
            value: ActionOnError.STOP_STREAM,
        },
    ];

    const { maxWaitTimeoutSeconds, actionOnError, useDataQuery } = node.params;

    const [retryCount, setRetryCount] = useState(0);

    const setRetryCountValue = (timeout: number) => {
        if (timeout < 20 || timeout > 3600) {
            setRetryCount(0);
        } else {
            const retryValue = Math.floor(timeout / 20);
            setRetryCount(retryValue);
        }
    };

    const setTimeout = (timeout: number) => {
        setRetryCountValue(timeout);
        if (timeout) set(node.params, 'maxWaitTimeoutSeconds', timeout);
        onUpdate(node);
    };

    const setActionOnError = (value: string) => {
        if (value === ActionOnError.STOP_STREAM) {
            set(node.params, 'actionOnError', ActionOnError.STOP_STREAM);
        } else {
            set(node.params, 'actionOnError', ActionOnError.IGNORE_ERROR);
        }
        onUpdate(node);
    };

    const validateTimeout = (_: RuleObject, _v: StoreValue, callback: (error?: string) => void) => {
        if (maxWaitTimeoutSeconds < 20) {
            callback(i18n('min_timeout_error_msg', I18N_ZUORA_QUERY_PREFIX));
        } else if (maxWaitTimeoutSeconds > 3600) {
            callback(i18n('max_timeout_error_msg', I18N_ZUORA_QUERY_PREFIX));
        }
        callback();
    };

    const boldText = (text: string) => <b>{text}</b>;

    useEffect(() => {
        form.validateFields([i18n('max_wait_timeout_seconds_label', I18N_GENERIC_PREFIX)]);
    });

    useEffect(() => {
        setRetryCountValue(maxWaitTimeoutSeconds);
    }, [maxWaitTimeoutSeconds]);

    return (
        <>
            <legend>{i18n('operational_settings_legend', I18N_GENERIC_PREFIX)}</legend>

            <Form form={form} layout="vertical">
                {useDataQuery && (
                    <>
                        <Form.Item
                            className={classes.timeoutFormItem}
                            label={i18n('max_wait_timeout_seconds_label', I18N_GENERIC_PREFIX)}
                            name={i18n('max_wait_timeout_seconds_label', I18N_GENERIC_PREFIX)}
                            required
                            rules={[{ validator: validateTimeout }]}
                        >
                            <div>
                                <InputNumber
                                    defaultValue={DEFAULT_WAIT_TIMEOUT_SECONDS}
                                    value={maxWaitTimeoutSeconds}
                                    onChange={setTimeout}
                                    type="number"
                                    data-testid={`${TEST_ID}_max_wait_timeout_seconds_input`}
                                />
                                {i18n('max_wait_timeout_seconds_label_suffix', I18N_GENERIC_PREFIX)}
                            </div>
                        </Form.Item>
                        <div className={classes.textWithMargin}>
                            {retryCount >= 1 && (
                                <>
                                    {i18n('timeout_desc_1', I18N_ZUORA_QUERY_PREFIX, {
                                        retryValue: `${retryCount.toString()} ${retryCount > 1 ? 'retries' : 'retry'}`,
                                        strong: boldText,
                                    })}
                                    <br />
                                </>
                            )}
                            {i18n('timeout_desc_2', I18N_ZUORA_QUERY_PREFIX)}
                        </div>
                    </>
                )}
                <Form.Item label={i18n('action_on_error_label', I18N_ZUORA_QUERY_PREFIX)}>
                    <div className={classes.radioGroupContainer}>
                        <RadioGroup
                            onChange={setActionOnError}
                            selectedValue={actionOnError}
                            defaultValue={ActionOnError.IGNORE_ERROR}
                            options={radioOptions}
                            isVertical
                            data-testid={`${TEST_ID}_action_on_error_radio`}
                        />
                    </div>
                </Form.Item>
            </Form>
        </>
    );
}

export default ZuoraQueryOperationalSettings;
