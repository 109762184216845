import { handleActions } from 'redux-actions';
import { setStreamToImport, setParametersType, setReplaceId, setKeepParams } from '../actions/importStream';
import { SetParametersType } from '../../types';
import type { Stream } from '../../../../../packages/dazzler-types';

export type State = {
    type: SetParametersType;
    stream: Partial<Stream>;
    keepParams: boolean;
    replaceId: Stream['id'];
};

export const initialState: State = {
    type: SetParametersType.UPDATE,
    stream: {},
    keepParams: false,
    replaceId: null,
};

export const reducer = handleActions(
    {
        [setStreamToImport.toString()]: (state: State, { payload }): State => ({
            ...state,
            stream: payload,
        }),
        [setParametersType.toString()]: (state: State, { payload }): State => ({
            ...state,
            type: payload,
        }),
        [setReplaceId.toString()]: (state: State, { payload }): State => ({
            ...state,
            replaceId: payload,
        }),
        [setKeepParams.toString()]: (state: State, { payload }): State => ({
            ...state,
            keepParams: payload,
        }),
    },
    initialState,
);
