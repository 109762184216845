import React, { useContext, useMemo, createContext, useReducer, ReactNode, Dispatch } from 'react';
import { Form, FormInstance } from 'antd';
import { object, property, common } from './reducers';

type Props = {
    children: ReactNode;
    node: any;
};

type Action = object.Action | property.Action | common.Action;

type ContextState = object.State &
    property.State &
    common.State & {
        dispatch: Dispatch<Action>;
        node: any;
        form: FormInstance;
    };

const reduceReducers =
    (...reducers) =>
    (state, action) =>
        reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state);

const initialState = { ...object.initialState, ...property.initialState, ...common.initialState };
const ValidatorContext = createContext<ContextState | undefined>(undefined);

const reducer = reduceReducers(object.reducer, property.reducer, common.reducer);

export const ValidatorProvider = ({ node, children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [form] = Form.useForm();

    const contextValue = useMemo(
        () => ({
            ...state,
            dispatch,
            node,
            form,
        }),
        [state, dispatch, node, form],
    );

    return <ValidatorContext.Provider value={contextValue}>{children}</ValidatorContext.Provider>;
};

export const useValidatorState = () => {
    const context = useContext(ValidatorContext);

    if (context === undefined) {
        throw new Error('useValidatorState must be used within ValidatorProvider');
    }

    return context;
};
