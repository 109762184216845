import React, { useEffect } from 'react';
import { Form, TreeSelect, Input } from 'antd';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { useValidatorState } from '../context';
import { Property, SourceFields } from '../types';

const styles = {
    selectOverwrite: {
        '&.ant-select, &.ant-input': {
            marginTop: 0,
        },
    },
    field: {
        marginLeft: 0,
        marginRight: 0,
    },
};

const useStyles = createUseStyles(styles);

type Props = {
    value?: string;
    onChange: (value: any) => void;
    fieldName: string;
    existingField: boolean;
    inputFieldsTree?: SourceFields[];
    disabled?: boolean;
};

const FieldSelect = ({ value, onChange, fieldName, existingField, inputFieldsTree, disabled = false }: Props) => {
    const { properties, form } = useValidatorState();
    const classes = useStyles();
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `function_config.transforms.validator.${id}` });
    const filteredTreeData = inputFieldsTree
        ? inputFieldsTree.filter(
              (val: SourceFields) => !properties.some((prop: Property) => prop.propertyValue === val.value),
          )
        : [];
    const renderInputComponent = () =>
        existingField ? (
            <TreeSelect
                className={classes.selectOverwrite}
                showSearch
                onChange={onChange}
                treeData={filteredTreeData}
                placeholder={i18n('select_property_placeholder')}
                disabled={disabled}
            />
        ) : (
            <Input
                className={classes.selectOverwrite}
                size="middle"
                type="text"
                onChange={onChange}
                placeholder={i18n('enter_field_name_placeholder')}
                autoComplete="off"
                disabled={disabled}
            />
        );

    useEffect(() => {
        if (!value) {
            form.setFieldsValue({ [fieldName]: null });
        }
    }, [value]);

    return (
        <div>
            <Form.Item
                className={classes.field}
                name={fieldName}
                initialValue={value}
                validateFirst
                validateTrigger={['onChange', 'onBlur', 'onFocus']}
                rules={[
                    { required: true, message: i18n('field_required_message') },
                    {
                        validator: () => {
                            const isDuplicate = properties.filter((p) => p.propertyValue === value).length > 1;
                            const hasInvalidChacters = value.match(/^[a-zA-Z_\d\s.]+$/g) === null;
                            if (!isDuplicate && !hasInvalidChacters) {
                                return Promise.resolve();
                            }
                            if (hasInvalidChacters) {
                                return Promise.reject(new Error(i18n('field_character_validation_message')));
                            }

                            return Promise.reject(new Error(i18n('property_exists')));
                        },
                    },
                ]}
            >
                {renderInputComponent()}
            </Form.Item>
        </div>
    );
};

export default FieldSelect;
