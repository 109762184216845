import { FetchState } from '../helpers';
import type { GetDataCorrectionWidgets, GetDataCorrectionSummaryTable, GetDataCorrectionDetailTable } from './api';

type SelectOptionType = {
    label?: string;
    value: string;
    key: string;
};

export type Detail = {
    streamId: string;
    nodeId: string;
    errorMessage: string;
};

export type FilterDetail = {
    selectFieldOptions: SelectOptionType[];
    selectFieldTypeOptions: SelectOptionType[];
    updatedFilter: boolean;
};

export type DCStream = {
    streamId: string;
    streamName: string;
};

export type ErrorStatus = 'NEW' | 'REPAIRED' | 'REPLAYED' | 'REPLAY_FAILED';
export type Event = {
    [key: string]: string;
};

export type FilterEvent = {
    error_status?: ErrorStatus[];
    start_date?: string;
    end_date?: string;
    stream_name?: string;
    keyword?: string;
    event?: Event;
    relative_days?: any;
};

export type FilterFilled = {
    filterEvent: FilterEvent;
};

export type State = {
    dataSummary: GetDataCorrectionWidgets;
    dataSummaryTable: GetDataCorrectionSummaryTable;
    dataSummaryTableDetail: any;
    dataDetailTable: GetDataCorrectionDetailTable;
    filterDetailTable: FilterDetail;
    filterFilled: FilterFilled;
    streams: DCStream[];
    fetchStreams: FetchState;
    fetchSummary: FetchState;
    fetchSummaryTable: FetchState;
    fetchDetailTable: FetchState;
    dataEditViewModeSummary: GetDataCorrectionWidgets;
    dataCorrectionErrorMessages: string[];
};

export type ST = { dataCorrection: State };

export const getSessionSummary = (state: ST) => state.dataCorrection.dataSummary;

export const getTableSummary = (state: ST) => state.dataCorrection.dataSummaryTable;

export const getTableSummaryDetail = (state: ST) => state.dataCorrection.dataSummaryTableDetail;

export const getTableDetail = (state: ST) => state.dataCorrection.dataDetailTable;

export const getFilter = (state: ST) => state.dataCorrection.filterDetailTable;

export const getStreams = (state: ST) => state.dataCorrection.streams;

export const getFilledFilter = (state: ST) => state.dataCorrection.filterFilled;

export const isFetchingSummaryTable = (state: ST) => state.dataCorrection.fetchSummaryTable.isLoading;

export const isFetchingDetailTable = (state: ST) => state.dataCorrection.fetchDetailTable.isLoading;

export const getModeView = (state: ST) => state.dataCorrection.dataEditViewModeSummary;

export const getErrorMessages = (state: ST) => state.dataCorrection.dataCorrectionErrorMessages;
