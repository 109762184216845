import React, { useCallback, ComponentClass } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as stream from '../../streams';

export type ReadOnlyModeProps = {
    isReadOnly: boolean;
    setReadOnly: (newReadOnlyState: boolean) => void;
};

export const useReadOnlyMode = () => {
    const dispatch = useDispatch();
    const isReadOnly: ReadOnlyModeProps['isReadOnly'] = useSelector(stream.isEditorReadOnly);
    const setReadOnly: ReadOnlyModeProps['setReadOnly'] = useCallback(
        (newReadOnlyState: boolean) => dispatch(stream.setReadOnly(newReadOnlyState)),
        [],
    );

    const location = useLocation();
    const isInStreamEditor = matchPath('/streams/:streamId', location.pathname);
    return {
        setReadOnly,
        isReadOnly: isInStreamEditor ? isReadOnly : false,
    };
};

// HOC for not-yet migrated class components
export const withReadOnly =
    <P extends ReadOnlyModeProps = ReadOnlyModeProps>(Component: ComponentClass<P>) =>
    (props: Omit<P, keyof ReadOnlyModeProps>) => {
        const { isReadOnly, setReadOnly } = useReadOnlyMode();

        return <Component {...(props as P)} isReadOnly={isReadOnly} setReadOnly={setReadOnly} />;
    };
