import React, { useCallback } from 'react';
import { Form, Popover } from 'antd';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../../../../resources';
import NumberInputItem from '../../configurator-fields/NumberInputItem';
import SecretsWalletConfigurator from '../../common/secrets-wallet/SecretsWalletConfigurator';
import SalesforceEnvironmentItem from '../../common/SalesforceEnvironmentItem';
import { SECRETS_WALLET_TYPE_PATH } from '../../../../../dazzler-constants';
import { ParametersProps } from '../../../../../dazzler-types';

const styles = {
    descriptionIcon: {
        float: 'right',
    },
    fileInfoPopover: {
        whiteSpace: 'pre',
    },
};

const useStyles = createUseStyles(styles);
const MAX_WAIT_TIMEOUT_SECONDS = 60;
const I18N_PREFIX = 'function_config.transform.salesforce_bulk';

const SalesforceBulkParameters = ({ node, onUpdate, functionType }: ParametersProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const secretType = get(functionType, SECRETS_WALLET_TYPE_PATH, null);

    const i18n = (id: string, values?: any, prefix: string = I18N_PREFIX) =>
        intl.formatMessage({ id: `${prefix}.${id}` }, values);

    const onSecretsWalletToggle = useCallback(
        (secretsWalletToggle: boolean): void => {
            const params = { ...node.params };

            if (secretsWalletToggle) {
                delete params.clientId;
                delete params.clientSecret;
                delete params.username;
                delete params.password;
            } else {
                delete params.secretsWalletEntry;
            }

            onUpdate({ ...node, params });
        },
        [node],
    );

    return (
        <Form layout="vertical">
            <SalesforceEnvironmentItem node={node} onUpdate={onUpdate} functionType={functionType} />
            <SecretsWalletConfigurator
                legend={i18n('secrets_wallet.legend')}
                node={node}
                onUpdate={onUpdate}
                type={secretType}
                i18nPrefix={I18N_PREFIX}
                onToggle={onSecretsWalletToggle}
            />
            <fieldset>
                <legend>
                    {i18n('timeout_settings_legend')}
                    <Popover
                        content={<div className={classes.fileInfoPopover}>{i18n('timeout_settings_description')}</div>}
                        placement="bottomLeft"
                        arrowPointAtCenter
                    >
                        <FontAwesomeIcon icon={icons.solid.faInfoCircle} className={classes.descriptionIcon} />
                    </Popover>
                </legend>
                <NumberInputItem
                    label={i18n('max_wait_timeout_seconds_label')}
                    suffix={i18n('max_wait_timeout_seconds_label_suffix')}
                    node={node}
                    path="maxWaitTimeoutSeconds"
                    min={1}
                    defaultValue={MAX_WAIT_TIMEOUT_SECONDS}
                    onUpdate={onUpdate}
                    required
                />
            </fieldset>
        </Form>
    );
};

export default SalesforceBulkParameters;
