import { api } from '../app/api';
import { overrideExisting, providesEntity } from '../app/utils';
import type { RootState } from '../app/store';

export interface Solution {
    id: string;
    name: string;
    colorHex: string;
    accountId: string;
    createdAt: string;
    createdBy: string;
    streamsStatus: {
        failed: number | '-';
        running: number | '-';
        scheduled: number | '-';
    };
    streamsCount: number;
    updatedAt: string;
    dataCorrectionTotalRecords: number;
}

interface NodeError {
    details: Record<string, string>;
    message: string;
}

interface StreamError {
    code: string;
    message: string;
}

interface ValidateResponse {
    [solutionId: string]: {
        [streamId: string]: {
            stream: StreamError[];
            nodes: {
                [nodeId: string]: NodeError[];
            };
        };
    }[];
}

interface CreateSolutionRequest {
    accountId: string;
    name: string;
    color: string;
}

interface SummaryResponse {
    solutions: Solution[];
}

export const solutionApi = api.injectEndpoints({
    overrideExisting: overrideExisting(),
    endpoints: (builder) => ({
        /**
         * RTK TODO:
         * Add tags for *getSolutionsByAccountId* and invalidate them on stream add/delete or deployment status change in this solution.
         * Should be added when streams api and/or deployments api is migrated, and and *refetchOnMountOrArgChange* should be removed.
         */
        getSolutionsByAccountId: builder.query<Solution[], string>({
            query: (accountId) => `accounts/${accountId}/solutions-summary`,
            transformResponse: (response: SummaryResponse) => response.solutions,
        }),
        getSolutionById: builder.query<Solution, string>({
            query: (id) => `solutions/${id}`,
            providesTags: providesEntity('Solution'),
        }),
        /**
         * RTK TODO:
         * Add tags for *validateSolutionById* and invalidate them on stream add/delete/change in this solution.
         * Should be added when streams api is migrated, and *refetchOnMountOrArgChange* should be removed.
         */
        validateSolutionById: builder.query<string[], string>({
            query: (id) => `solutions/${id}/validate`,
            transformResponse: (response: ValidateResponse, _meta, arg) =>
                response[arg].map((stream) => Object.keys(stream).pop()),
        }),
        updateSolution: builder.mutation<Solution, Partial<Solution>>({
            query: ({ id, ...body }) => ({
                url: `solutions/${id}`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted(solution, { dispatch, getState, queryFulfilled }) {
                const state = getState() as RootState;

                const updateResult = dispatch(
                    solutionApi.util.updateQueryData(
                        'getSolutionsByAccountId',
                        state.accounts.activeAccount,
                        (draft) => {
                            const solutionIndex = draft.findIndex(({ id }) => id === solution.id);

                            draft[solutionIndex] = { ...draft[solutionIndex], ...solution };
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch {
                    updateResult.undo();
                }
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Solution', id }],
        }),
        createSolution: builder.mutation<Solution, CreateSolutionRequest>({
            query: ({ accountId, name, color }) => ({
                url: `accounts/${accountId}/solutions`,
                method: 'POST',
                body: { name, colorHex: color },
            }),
            async onQueryStarted({ accountId, name, color }, { dispatch, queryFulfilled }) {
                const createResult = dispatch(
                    solutionApi.util.updateQueryData('getSolutionsByAccountId', accountId, (draft) => {
                        draft.push({ name, colorHex: color } as Solution);
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    createResult.undo();
                }
            },
            invalidatesTags: [{ type: 'Solution', id: 'LIST' }],
        }),
        deleteSolutionById: builder.mutation<void, Solution['id']>({
            query: (id) => ({
                url: `solutions/${id}`,
                method: 'DELETE',
            }),
            async onQueryStarted(id, { dispatch, getState, queryFulfilled }) {
                const state = getState() as RootState;

                const deleteResult = dispatch(
                    solutionApi.util.updateQueryData('getSolutionsByAccountId', state.accounts.activeAccount, (draft) =>
                        draft.filter((solution) => solution.id !== id),
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
    }),
});

export const {
    endpoints,
    useGetSolutionsByAccountIdQuery,
    useGetSolutionByIdQuery,
    useValidateSolutionByIdQuery,
    useCreateSolutionMutation,
    useUpdateSolutionMutation,
    useDeleteSolutionByIdMutation,
} = solutionApi;
