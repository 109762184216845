import { IntlShape } from 'react-intl';

type ValidationMessageIdInput = 'required' | 'related' | 'unique' | 'invalid';
type ValidationMessageValues = Record<string, string> & {
    currentFieldName?: string;
    relatedFieldName?: string;
    exampleText?: string;
};
export const getFunctionValidationMessage = (
    intl: IntlShape,
    messageId: ValidationMessageIdInput,
    values?: ValidationMessageValues,
) => {
    let inputId: string = messageId;
    if (inputId === 'invalid' && values.exampleText) {
        inputId = `${inputId}_with_example`;
    }
    return intl.formatMessage({ id: `function_config.validation.${inputId}` }, values);
};
