import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { unset, get } from 'lodash';
import { Node, Stream, FunctionType } from '../../../../../dazzler-types';
import { SECRETS_WALLET_TYPE_PATH } from '../../../../../dazzler-constants';
import { getNodeInputFieldsTreeData, StreamsPreviewState } from '../../../../../streams';
import SecretsWalletConfigurator from '../../common/secrets-wallet/SecretsWalletConfigurator';
import GoTransverseEnviromentItem from '../../common/goTransverse/GoTransverseEnviromentItem';
import GoTransverseFieldMappingsItemUsage from '../../common/goTransverse/GoTransverseFieldMappingsItemUsage';
import GoTransverseModeItem from '../../common/goTransverse/GoTransverseMode';
import GoTransverseS3Writer from '../../common/goTransverse/GoTransverseS3Writer';
import { SourceFields } from '../transforms/validatev2/types';

const I18N_PREFIX = 'function_config.transform.goTransverse';

type ConfiguratorProps = {
    node: Node;
    stream: Stream;
    functionType: FunctionType;
    onUpdate: (node: Node) => void;
};

const GoTransverseConfigurator = ({ node, stream, onUpdate, functionType }: ConfiguratorProps) => {
    const intl = useIntl();

    const i18n = (values: string) => intl.formatMessage({ id: `${I18N_PREFIX}.${values}` });

    const secretType = get(functionType, SECRETS_WALLET_TYPE_PATH, null);
    const sourceFields = useSelector((state: StreamsPreviewState) =>
        getNodeInputFieldsTreeData(state, node, stream),
    ) as Array<SourceFields>;
    const update = (type: string, newData: any | string) => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                [type]: newData,
            },
        });
    };
    const onSecretsWalletToggle = (secretsWalletToggle: boolean) => {
        if (secretsWalletToggle) {
            unset(node.params, 'authentication');
            unset(node.params, 'apiAccessKeyId');
            unset(node.params, 'apiSecretAccessKey');
            unset(node.params, 'clientId');
            unset(node.params, 'clientSecret');
        } else {
            unset(node.params, 'authentication');
            unset(node.params, 'secretsWalletEntry');
        }
        onUpdate(node);
    };

    return (
        <Form layout="vertical">
            <GoTransverseEnviromentItem
                node={node}
                functionType={functionType}
                onUpdate={onUpdate}
                i18nPrefix={I18N_PREFIX}
            />
            <SecretsWalletConfigurator
                legend={i18n('secrets_wallet.legend')}
                node={node}
                onUpdate={onUpdate}
                type={secretType}
                i18nPrefix={I18N_PREFIX}
                onToggle={onSecretsWalletToggle}
            />
            <GoTransverseModeItem node={node} onUpdate={onUpdate} i18nPrefix={I18N_PREFIX} />
            <GoTransverseFieldMappingsItemUsage
                node={node}
                onUpdate={onUpdate}
                functionType={functionType}
                updateParams={(type: string, newData: any | string) => update(type, newData)}
                sourceFields={sourceFields}
                i18nPrefix={I18N_PREFIX}
            />
            <GoTransverseS3Writer node={node} onUpdate={onUpdate} i18nPrefix={I18N_PREFIX} />
        </Form>
    );
};

export default GoTransverseConfigurator;
