import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { get, unset } from 'lodash';
import { createUseStyles } from 'react-jss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxItem, RadioGroup, Paragraph } from '@digitalroute-internal/dazzlerjs-react-ui';
import { useSelector } from 'react-redux';
import { FIRST_VALUE, NumberInputItem, SECOND_VALUE } from '../configurator-fields';
import type { ConfiguratorProps, Node } from '../../../../dazzler-types';

type Props = Required<Pick<ConfiguratorProps, 'node' | 'functionType' | 'onUpdate'>>;

const TEST_ID = 'general-retry-on-fail';

const styles = (theme: Record<string, any>) => ({
    checkbox: {
        margin: `${theme.paddingXs} !important`,
        width: 'fit-content',
    },
    retryContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .ant-form-item': {
            marginRight: 0,
            marginBottom: 0,
        },
        marginBottom: theme.marginMd,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        '& input[type="text"]': {
            width: '30%',
        },
    },
    actionOnError: {
        marginTop: theme.marginXs,
        '& .ant-radio-group > div': {
            flexDirection: 'column',
            '& > div:first-child': {
                marginBottom: theme.marginXss,
            },
        },
    },
    radioGroupContainer: {
        '& > div': {
            padding: 0,
        },
    },
});

const IGNORE = FIRST_VALUE;
const STOP_THE_STREAM = SECOND_VALUE;

function RetryOnFail({ node, functionType, onUpdate }: Props) {
    const useStyles = createUseStyles(styles);
    const classes = useStyles();
    const intl = useIntl();
    const [shouldRetry, setShouldRetry] = useState<boolean>(node.params.shouldRetry);
    const configParams = useSelector(() => functionType);
    const retriesProps = configParams.params.properties.retries || {};
    const { retries = 1 } = node.params;
    const totalDelay = retries === 1 ? 1 : 2 ** retries;

    const i18n = (id: string, value?: any) =>
        value
            ? intl.formatMessage({ id: `function_config.general.retry_on_fail.${id}` }, value)
            : intl.formatMessage({ id: `function_config.general.retry_on_fail.${id}` });

    const onShouldRetryChange = (e: CheckboxChangeEvent): void => {
        const params = { ...node.params, shouldRetry: e.target.checked };

        setShouldRetry(e.target.checked);

        if (!e.target.checked) {
            delete params.retries;
            delete params.errorMode;
        } else {
            params.errorMode = 'emit';
        }

        onUpdate({
            ...node,
            params,
        });
    };

    const returnOperationSettingsBannerMsg = () =>
        i18n('description', {
            totalDelay,
            newLine: <br />,
            bold: (chunks) => <b>{chunks}</b>,
        });

    const onUpdatePath = (path: string) => (updatedNode: Node) => {
        const value = get(updatedNode.params, path);
        if (!value) {
            unset(updatedNode.params, path);
        }
        if (typeof updatedNode.params.retries === 'string') {
            updatedNode.params.retries = Number(updatedNode.params.retries);
        }
        onUpdate(updatedNode);
    };

    const toggleActionOnError = (value: string): void => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                errorMode: value === 'first' ? 'emit' : 'defer',
            },
        });
    };

    const boldText = (text: string) => <b>{text}</b>;

    const radioOptions = [
        {
            value: IGNORE,
            label: i18n('advanced_error_first'),
        },
        {
            value: STOP_THE_STREAM,
            label: i18n('advanced_error_second'),
        },
    ];

    return (
        <div className={classes.retryContainer}>
            <CheckboxItem
                onChange={onShouldRetryChange}
                checked={shouldRetry}
                label={i18n('checkbox_label')}
                data-testid={`${TEST_ID}__retry-checkbox`}
            />
            {shouldRetry && (
                <div className={classes.flexColumn}>
                    <Paragraph text={returnOperationSettingsBannerMsg()} isBold={false} isDescription />
                    <NumberInputItem
                        label={i18n('retries_number')}
                        min={retriesProps.minimum}
                        max={retriesProps.maximum}
                        node={node}
                        path="retries"
                        suffix={i18n('retry_times')}
                        onUpdate={onUpdatePath('retries')}
                        data-testid={`${TEST_ID}__retry-number`}
                        required
                    />
                    <span data-testid={`${TEST_ID}__retry-total-delay`}>
                        {i18n('description_input', {
                            totalDelay,
                            newLine: <br />,
                            bold: boldText,
                        })}
                    </span>
                    <div className={classes.radioGroupContainer}>
                        <RadioGroup
                            isVertical
                            hasTitle
                            title={i18n('advanced_error_label')}
                            onChange={toggleActionOnError}
                            options={radioOptions}
                            selectedValue={node.params.errorMode === 'emit' ? IGNORE : STOP_THE_STREAM}
                            data-testid={`${TEST_ID}__advanced-error-handling`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default RetryOnFail;
