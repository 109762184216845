import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Checkbox, Button, Tabs, Form, Alert } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { debounce } from 'lodash';
import { preview } from '../../../../../../streams';
import { CodeConfigurator } from '../../../../../../generic-configurator';
import { ConfiguratorProps, FeatureToggleFn } from '../../../../../../dazzler-types';
import { selectors as nodeValidation } from '../../../../../../validations';
import { isFeatureEnabled as isFeatureToggleEnabled } from '../../../../../../feature-toggles';
import ObjectsView from './objects/ObjectsView';
import PropertiesView from './properties/PropertiesView';
import { ValidatorProvider, useValidatorState } from './context';
import { dereferenceSchema } from './utils';
import { useReadOnlyMode } from '../../../../../../stream-editor/hooks';

const styles = (theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    applyRules: {
        marginTop: theme.marginXs,
    },
});

const windowHeightOffset = 400 + 1 + 1; // compensate for borders
const editorHeight = () => window.innerHeight - windowHeightOffset;

const useStyles = createUseStyles(styles);

const ValidatorV2Configurator = ({ node, stream, onUpdate }: ConfiguratorProps) => {
    const { dispatch, validationSchema, objects, properties, schema, lastValidSchema, form } = useValidatorState();
    const { isReadOnly } = useReadOnlyMode();
    const errorWithPathDot = useSelector((state: any) =>
        nodeValidation.getNodeValidationMessage(node.id, `.schema`)(state),
    );
    const errorWithPathSlash = useSelector((state: any) =>
        nodeValidation.getNodeValidationMessage(node.id, `/schema`)(state),
    );
    const hasEditorError = errorWithPathDot || errorWithPathSlash;
    const classes = useStyles();

    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `function_config.transforms.validator.${id}` });

    const globalDispatch = useDispatch();
    const previewRequest = (userTriggeredOnNode?: string) =>
        globalDispatch(preview.request(stream, userTriggeredOnNode));
    const debouncePreview = debounce(() => {
        previewRequest();
    }, 0);

    const isFeatureEnabled: FeatureToggleFn = useSelector(isFeatureToggleEnabled);

    const updateNodeParams = (key: string, value: any) => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                [key]: value,
            },
        });
    };

    const updateLastNodeParams = (params: any) => {
        onUpdate({
            ...node,
            params,
        });
    };

    const onSuspenseManagementChange = ({ target: { checked } }: CheckboxChangeEvent) => {
        updateNodeParams('suspenseManagement', { enabled: checked });
    };

    const parseSchemaToRules = () => {
        dispatch({ type: 'PARSE_SCHEMA_TO_RULES', payload: node.params.schema });
    };

    const onParseSchemaToRules = () => {
        try {
            parseSchemaToRules();
        } catch (ex) {
            // do nothing
        }
    };

    const onStateLoad = (value) => {
        if (typeof value === 'object') {
            return JSON.stringify(value, null, 2);
        }
        return value;
    };

    const onStateSave = (value) => {
        try {
            return JSON.parse(value);
        } catch (err) {
            // do nothing
        }

        return value;
    };

    useEffect(() => {
        const getSchema = async () => {
            try {
                const dereferencedSchema = await dereferenceSchema();

                dispatch({
                    type: 'SET_VALIDATION_SCHEMA',
                    payload: dereferencedSchema,
                });
            } catch (error) {
                console.log('An error occurred when trying to dereference the validation schema.', error); // eslint-disable-line
            }
        };

        getSchema();
    }, []);

    const schemaRef = useRef();
    const suspenseManagementRef = useRef();
    useEffect(() => {
        schemaRef.current = lastValidSchema;
        suspenseManagementRef.current = node.params.suspenseManagement;
    }, [node, lastValidSchema]);

    useEffect(() => {
        updateNodeParams('schema', schema);
    }, [schema]);

    useEffect(
        () => () => {
            const newParams = {
                ...node.params,
                schema: schemaRef.current,
                suspenseManagement: suspenseManagementRef.current,
            };
            updateLastNodeParams(newParams);
        },
        [],
    );

    useEffect(() => {
        if (node.params.schema) {
            dispatch({ type: 'PARSE_SCHEMA_TO_RULES', payload: node.params.schema });
        }

        if (!isFeatureEnabled('dataCorrectionService')) {
            updateNodeParams('suspenseManagement', { enabled: false });
        }

        return () => debouncePreview();
    }, []);

    useEffect(() => {
        dispatch({
            type: 'PARSE_RULES_TO_SCHEMA',
        });
    }, [objects, properties]);

    if (!validationSchema) {
        return null;
    }

    return (
        <div className={classes.container}>
            <Form layout="vertical" form={form} validateTrigger={['onChange', 'onBlur', 'onFocus']}>
                {isFeatureEnabled('dataCorrectionService') && (
                    <Checkbox
                        onChange={onSuspenseManagementChange}
                        checked={node.params.suspenseManagement?.enabled}
                        disabled={isReadOnly}
                    >
                        {i18n('data_correction')}
                    </Checkbox>
                )}
                <Tabs defaultActiveKey="rules" animated={false}>
                    <Tabs.TabPane tab="Rules" key="rules">
                        <PropertiesView disabled={isReadOnly} />
                        <ObjectsView disabled={isReadOnly} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Schema" key="schema">
                        <CodeConfigurator
                            height={editorHeight()}
                            language="json"
                            node={node}
                            path="schema"
                            themeColor="light"
                            onUpdate={onUpdate}
                            onUpdateDelay={300}
                            onStateLoad={onStateLoad}
                            onStateSave={onStateSave}
                            options={{
                                glyphMargin: false,
                                guides: {
                                    indentation: false,
                                },
                                lineDecorationsWidth: 0,
                                lineNumbersMinChars: 3,
                            }}
                        />
                        {hasEditorError && <Alert message={i18n('fix_errors_in_schema')} type="error" />}
                        <Button
                            className={classes.applyRules}
                            disabled={hasEditorError || isReadOnly}
                            onClick={onParseSchemaToRules}
                        >
                            {i18n('apply_rules')}
                        </Button>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </div>
    );
};

const ValidationProvider = (props: ConfiguratorProps) => (
    <ValidatorProvider {...props}>
        <ValidatorV2Configurator {...props} />
    </ValidatorProvider>
);

export default ValidationProvider;
