import * as React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import ReactMarkdown from 'react-markdown';

const MyDishConfigurator = (): JSX.Element => {
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `function_config.reader.s3_dataexchange.${id}` });

    return (
        <Form layout="vertical">
            <fieldset>
                <legend>{i18n('legend')}</legend>
                <ReactMarkdown>{i18n('message')}</ReactMarkdown>
            </fieldset>
        </Form>
    );
};

export default MyDishConfigurator;
