import { Form } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Node } from '../../../../../dazzler-types';
import { NumberInputItem } from '../../configurator-fields';

type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
};

const styles = {
    inputWrapper: {
        display: 'flex',
        width: '100%',
    },
    span: {
        color: '#FF0000',
    },
};

const useStyles = createUseStyles(styles);

const SleepTimerConfigurator = ({ node, onUpdate }: Props) => {
    const intl = useIntl();
    const classes = useStyles();
    const i18n = (id: string, prefix = 'function_config.transform.sleep_timer') =>
        intl.formatMessage({ id: `${prefix}.${id}` });

    const updateSleepTimer = (nodeRef) => {
        onUpdate({ ...nodeRef, params: { sleepTimer: node.params.sleepTimer } });
    };

    return (
        <Form layout="vertical">
            <legend>{i18n('input_section')}</legend>
            <fieldset className={classes.inputWrapper}>
                <Form.Item label={i18n('input_field')} className={classes.inputWrapper} required>
                    <NumberInputItem
                        min={0}
                        max={60000} // One minute
                        node={node}
                        path="sleepTimer"
                        onUpdate={updateSleepTimer}
                    />
                    &nbsp;
                    <span className={classes.span}>{i18n('preview_msg')}</span>
                </Form.Item>
            </fieldset>
        </Form>
    );
};

export default SleepTimerConfigurator;
