import React from 'react';
import { useSelector } from 'react-redux';
import { get, set, unset } from 'lodash';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { Checkbox, Form } from 'antd';
import { RadioGroup, RadioOptionType } from '@digitalroute-internal/dazzlerjs-react-ui';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isFeatureEnabled as isFeatureToggleEnabled } from '../../../../../feature-toggles';
import { FeatureToggleFn, Node } from '../../../../../dazzler-types';
import { FIRST_VALUE, NumberInputItem, SECOND_VALUE } from '../../configurator-fields';

const styles = (theme: Record<string, any>) => ({
    checkbox: {
        margin: `${theme.paddingXs} !important`,
        width: 'fit-content',
    },
    retryContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .ant-form-item': {
            marginRight: 0,
            marginBottom: 0,
        },
        marginBottom: theme.marginMd,
    },
    retryDescription: {
        maxWidth: 340,
        marginLeft: theme.marginXs,
        color: theme.neutral04,
    },
    flex: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    actionOnError: {
        marginTop: theme.marginXs,
        '& .ant-radio-group > div': {
            flexDirection: 'column',
            '& > div:first-child': {
                marginBottom: theme.marginXss,
            },
        },
    },
    radioGroupContainer: {
        '& > div': {
            padding: 0,
        },
    },
});

type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
};

const useStyles = createUseStyles(styles);

const IGNORE = FIRST_VALUE;
const STOP_THE_STREAM = SECOND_VALUE;

function GoTransverseOperationalSettings({ node, onUpdate, i18nPrefix }: Props) {
    const intl = useIntl();
    const i18n = (id: string, value?: any) =>
        value
            ? intl.formatMessage({ id: `${i18nPrefix}.${id}` }, value)
            : intl.formatMessage({ id: `${i18nPrefix}.${id}` });
    const { shouldRetry, retries = 1 } = node.params;
    const totalDelay = retries === 1 ? 1 : 2 ** retries;

    const radioOptions: Array<RadioOptionType> = [
        {
            label: i18n('advanced_error_first'),
            value: IGNORE,
        },
        {
            label: i18n('advanced_error_second'),
            value: STOP_THE_STREAM,
        },
    ];

    const boldText = (text: string) => <b>{text}</b>;

    const onShouldRetryChange = (e: CheckboxChangeEvent): void => {
        set(node.params, 'shouldRetry', e.target.checked);
        if (!e.target.checked) unset(node.params, 'retries');
        onUpdate(node);
    };

    const onUpdatePath = (path: string) => (updatedNode: Node) => {
        const value = get(updatedNode.params, path);
        if (!value) {
            unset(updatedNode.params, path);
        }

        onUpdate(updatedNode);
    };

    const toggleActionOnError = (value: string): void => {
        set(node.params, 'errorMode', value === IGNORE ? 'emit' : 'defer');
        onUpdate(node);
    };

    const classes = useStyles();

    const isFeatureEnabled: FeatureToggleFn = useSelector(isFeatureToggleEnabled);

    return (
        <>
            <legend>{i18n('operational_settings_legend')}</legend>
            {isFeatureEnabled('httpRetry') && (
                <div className={classes.retryContainer}>
                    <Checkbox
                        checked={shouldRetry}
                        onChange={onShouldRetryChange}
                        className={classes.checkbox}
                        data-testid="gotQuery-advanced-enable-retry-on-fail"
                    >
                        {i18n('retry_on_fail')}
                    </Checkbox>
                    {shouldRetry && (
                        <div className={classes.flexColumn}>
                            <NumberInputItem
                                label={i18n('retries_number')}
                                min={1}
                                max={12}
                                node={node}
                                path="retries"
                                suffix={i18n('retry_times', { retries })}
                                onUpdate={onUpdatePath('retries')}
                                data-testid="gotQuery-advanced-retries-number"
                            />
                            <span className={classes.retryDescription}>
                                {i18n('retry_description', {
                                    totalDelay,
                                    newLine: <br />,
                                    bold: boldText,
                                })}
                            </span>
                        </div>
                    )}
                </div>
            )}
            <Form.Item label={i18n('advanced_error_label')}>
                <div className={classes.radioGroupContainer}>
                    <RadioGroup
                        onChange={toggleActionOnError}
                        options={radioOptions}
                        selectedValue={node.params.errorMode === 'defer' ? STOP_THE_STREAM : IGNORE}
                        defaultValue={IGNORE}
                        data-testid="gotQuery-advanced-error-handling"
                        isVertical
                    />
                </div>
            </Form.Item>
        </>
    );
}

export default GoTransverseOperationalSettings;
