import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { clsx } from 'clsx';
import { Col, Radio, Row, Form } from 'antd';

/*
    This abstracts a recurring pattern of having two radio buttons beside each other.
    Note that this does not take a `path` argument. Instead you need to manually wire
    it to your configuration.
*/

const styles = {
    radioPair: {
        '& .ant-radio-group': {
            display: 'block !important',
        },
    },
};

const useStyles = createUseStyles(styles);

export const FIRST_VALUE = 'first';
export const SECOND_VALUE = 'second';

type Value = typeof FIRST_VALUE | typeof SECOND_VALUE | string;

interface Props {
    label: React.ReactNode;
    onChange: (value: unknown) => void;
    firstOption: ReactNode;
    secondOption: ReactNode;
    value: Value;
    disabled?: boolean;
    className?: string;
}

const RadioPairItem = ({ className, label, firstOption, secondOption, value, onChange, disabled = false }: Props) => {
    const classes = useStyles();

    return (
        <Form.Item label={label} className={clsx(classes.radioPair, className)}>
            <Radio.Group onChange={(ev) => onChange(ev.target.value)} value={value} disabled={disabled}>
                <Row>
                    <Col span={12}>
                        <Radio value={FIRST_VALUE}>{firstOption}</Radio>
                    </Col>
                    <Col span={12}>
                        <Radio value={SECOND_VALUE}>{secondOption}</Radio>
                    </Col>
                </Row>
            </Radio.Group>
        </Form.Item>
    );
};

export default RadioPairItem;
