import React from 'react';
import { useValidatorState } from '../context';
import { Property, ValidationGroupsType, ValidationRule } from '../types';
import { getValidationGroups } from '../utils';
import ValidationGroups from './ValidationGroups';

type Props = {
    rule: ValidationRule;
    properties: Property[];
    onChange: (value: string) => void;
    disabled?: boolean;
};

const ValidationSelect = ({ rule, properties, onChange, disabled = false }: Props) => {
    const { validationSchema } = useValidatorState();
    const validationShemaProperties = [...Object.keys(validationSchema.properties), 'nestedObject', 'contains'];
    // dependentRequired is ommited because it's a bit more complex to implement and not used often
    const filteredSchemaProperties = validationShemaProperties.filter((prop) => prop !== 'dependentRequired');
    const groups = getValidationGroups(filteredSchemaProperties);
    const property = properties.find((p) => p.key === rule.propertyKey);
    const validationRules = property?.validationRules ?? [];

    const filteredGroups: ValidationGroupsType[] = Object.keys(groups).reduce(
        (result, group: keyof ValidationGroupsType) => {
            const values = groups[group].values.filter((value) =>
                validationRules.every((vRule) => vRule.validationRuleKey !== value),
            );

            if (values.length) {
                result.push({ [group]: { ...groups[group], values } });
            }

            return result;
        },
        [],
    );

    return <ValidationGroups rule={rule} groups={filteredGroups} onChange={onChange} disabled={disabled} />;
};

export default ValidationSelect;
