export const findValueInTreeData = (valueToFind: string, treeData): boolean => {
    const result = treeData.find((el) => {
        let foundInTreeData = el.value === valueToFind;
        if (!foundInTreeData && el.children.length >= 1) {
            foundInTreeData = findValueInTreeData(valueToFind, el.children);
        }
        return foundInTreeData;
    });
    return result !== undefined && Object.keys(result).length >= 1;
};

export const addAddonDataTreeData = (valueToFind: string | string[], treeData) => {
    const fieldsToAdd = [];
    if (Array.isArray(valueToFind)) {
        valueToFind.forEach((v) => {
            if (!(valueToFind === undefined ? true : findValueInTreeData(v, treeData))) {
                fieldsToAdd.push(v);
            }
        });
        return fieldsToAdd.length === 0
            ? treeData
            : treeData.concat(
                  fieldsToAdd.map((f) => ({
                      label: f,
                      value: f,
                      key: f,
                      children: [],
                  })),
              );
    }

    const foundInTreeData =
        valueToFind === undefined || valueToFind === '' ? true : findValueInTreeData(valueToFind, treeData);

    return foundInTreeData
        ? treeData
        : [
              ...treeData,
              {
                  label: valueToFind,
                  value: valueToFind,
                  key: valueToFind,
                  children: [],
              },
          ];
};
