type Selection = Array<string> | null;

export const keyToFields = (key?: string): Selection => {
    if (key === undefined) return null;
    const wholePayloadMatch = key.match(/[@$]{payload}/g) || [];
    if (wholePayloadMatch.length) return null;

    const paths = key.match(/[$@]{payload\.[^}]+}/g) || [];
    return paths.map((p) => p.substr(2, p.length - 3));
};

export const fieldsToKey = (fields: Selection, keepFormat: boolean = false): string => {
    const escapeCharacter = keepFormat ? '@' : '$';
    return fields
        ? fields
              .map((f) => (f.startsWith('payload.') ? `${escapeCharacter}{${f}}` : `${escapeCharacter}{payload.${f}}`))
              .join('')
        : // eslint-disable-next-line no-template-curly-in-string
          '${payload}';
};
