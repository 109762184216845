/*
 * Utility functions.
 */

import { Deployment, DeploymentSchedule } from '../dazzler-types';

export const isRegularDeploymentSchedule = (schedule: DeploymentSchedule): boolean => schedule.interval !== 0;

export const getRegularDeploymentSchedules = (schedules: Array<DeploymentSchedule>): Array<DeploymentSchedule> =>
    schedules.filter(isRegularDeploymentSchedule);

export const isScheduled = (deployment: Deployment): boolean =>
    deployment.cronSchedules?.some((s) => s.scheduleExpressions.length);

// Get the last date of given month.
// For example, getLastDateOfMonth(2020, 02) gives 2020-02-29T00:00:00.000Z
export const getLastDateOfMonth = (year: number, month: number) => new Date(Date.UTC(year, month, 0, 0));
