import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import { setActive as setActiveAccount } from '../packages/accounts';
import { setActiveSolution } from '../features/solutions/solutionsSlice';
import { solutionApi } from '../services/solutions';
import { streamApi } from '../services/streams';
import { routeMatchBuilder } from './utils';
import { type RootState } from './store';

const initialState = {
    location: null,
    navigationType: null,
};
export const routerSlice = createSlice({
    name: 'router',
    initialState,
    reducers: {
        locationChanged(state, action) {
            if (action.payload) {
                state.location = action.payload.location;
                state.navigationType = action.payload.navigationType;
            }
        },
    },
});
export const { locationChanged } = routerSlice.actions;

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: locationChanged,
    effect: async (action, listenerApi) => {
        const {
            accounts: { activeAccount },
            solutions: { activeSolution },
        } = listenerApi.getState() as RootState;

        const routeCheck = routeMatchBuilder(action.payload.location.pathname);

        routeCheck(
            ['/accounts/:accountId', '/profile/:accountId', '/accounts/:accountId/*'],
            async (accountId) => {
                if (accountId && activeAccount !== accountId) {
                    listenerApi.dispatch(setActiveAccount({ id: accountId }));
                }

                if (activeSolution) {
                    listenerApi.dispatch(setActiveSolution(null));
                }
            },
            'accountId',
        );

        routeCheck(
            '/solutions/:solutionId',
            async (solutionId) => {
                const solution = await listenerApi
                    .dispatch(solutionApi.endpoints.getSolutionById.initiate(solutionId))
                    .unwrap();

                if (activeAccount !== solution.accountId) {
                    listenerApi.dispatch(setActiveAccount({ id: solution.accountId }));
                }

                if (activeSolution?.id !== solutionId) {
                    listenerApi.dispatch(setActiveSolution(solution));
                }
            },
            'solutionId',
        );

        routeCheck(
            ['/streams/:streamId', '/streams/:streamId/*'],
            async (streamId) => {
                const stream = await listenerApi
                    .dispatch(streamApi.endpoints.getStreamById.initiate(streamId))
                    .unwrap();
                const solution = await listenerApi
                    .dispatch(solutionApi.endpoints.getSolutionById.initiate(stream.solutionId))
                    .unwrap();

                if (activeAccount !== solution.accountId) {
                    listenerApi.dispatch(setActiveAccount({ id: solution.accountId }));
                }

                if (activeSolution?.id !== solution.id) {
                    listenerApi.dispatch(setActiveSolution(solution));
                }
            },
            'streamId',
        );
    },
});
