import React, { Component } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Input, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@digitalroute-internal/dazzlerjs-react-ui';
import _ from 'lodash';
import { icons } from '../../../../resources';
import InputDataFieldSingle from '../configurator-fields/InputDataFieldSingle';
import { ConfiguratorProps } from '../../../../dazzler-types';

const styles = (theme) => ({
    kvpWrapper: {
        display: 'flex',
    },
    kvpInput: {
        maxWidth: '44%',
        minWidth: '44%',
        marginLeft: theme.paddingXs,
    },
    kvpNarrowInput: {
        width: '44%',
        marginLeft: theme.paddingXs,
        '& .ant-form-item-explain': {
            fontSize: theme.fontSizeSm,
        },
    },
    dynamicDeleteButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'relative',
        top: '-10px',
        fontSize: theme.fontSizeLg,
        color: theme.neutral05,
        transition: 'all .3s',
        marginLeft: theme.paddingXxs,
    },
    dynamicDeleteButtonFirstElementOnly: {
        top: theme.marginMd,
    },
    addRowBtn: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        marginBottom: theme.paddingMd,
        marginTop: '30px',
    },
    arrowIcon: {
        display: 'flex',
        alignItems: 'center',
        bottom: theme.paddingXxs,
        position: 'relative',
    },
    arrowIconCompact: {
        display: 'flex',
        alignItems: 'center',
        bottom: theme.paddingXs,
        position: 'relative',
    },
});

type Props = ConfiguratorProps & {
    classes?: any;
    updateParams: (arg0: string, arg1: any | string) => void;
    sourceFields: Array<Object>;
    customFieldsPattern?: RegExp;
    renderDefaultFields: boolean;
};

type State = {
    existingMappings: Array<any>;
};

class SalesforceObjectFieldMappings extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            existingMappings: [],
        };
    }

    componentDidMount() {
        const { node, functionType } = this.props;

        const allTargets = Object.keys(_.get(functionType, 'params.definitions.mapping.properties', {}));
        const removedDefaulТargets = Object.keys(_.get(node, 'params.removedDefault', {}));
        const requiredTargets = _.get(functionType, 'params.definitions.mapping.required', []);
        const optionalTargets = allTargets.filter((target) => !requiredTargets.includes(target));
        const optionalTargetsNotRemoved = optionalTargets.filter((target) => !removedDefaulТargets.includes(target));
        const fieldMappings = _.get(node, 'params.fieldMappings', {});
        const customFieldMappings = _.get(node, 'params.customFieldMappings', {});
        const mappings = [];

        requiredTargets.forEach((target) =>
            mappings.push({
                source: fieldMappings[target] || undefined,
                target,
                required: true,
                optional: false,
            }),
        );

        optionalTargetsNotRemoved.forEach((target) =>
            mappings.push({
                source: fieldMappings[target] || undefined,
                target,
                required: false,
                optional: true,
            }),
        );

        Object.keys(customFieldMappings).forEach((target) =>
            mappings.push({
                source: customFieldMappings[target] || undefined,
                target,
                required: false,
                optional: false,
            }),
        );

        this.setState({
            existingMappings: mappings,
        });
    }

    i18n = (id: string, values?: any, prefix: string = 'function_config.transform.salesforce_bulk') => {
        const { intl } = this.props;
        return intl.formatMessage({ id: `${prefix}.${id}` }, values);
    };

    updateNodeMapping = (index: number) => (ev) => {
        const { node, updateParams } = this.props;
        const { existingMappings } = this.state;
        const fieldMappings = _.get(node, 'params.fieldMappings', {});
        const customFieldMappings = _.get(node, 'params.customFieldMappings', {});
        const newData: any = existingMappings.map((m, i) => (i === index ? { ...m, source: ev } : m));
        const isRequired = newData[index].required || false;
        const isEditable = newData[index].optional || false;
        let updatedMappings;

        if (isRequired || isEditable) {
            updatedMappings = {
                ...fieldMappings,
                [newData[index].target]: ev,
            };
        } else {
            updatedMappings = {
                ...customFieldMappings,
                [newData[index].target]: ev,
            };
        }

        if (!isRequired && !isEditable && !ev && updatedMappings) {
            delete updatedMappings[newData[index].target];
        }

        if (updatedMappings) {
            this.setState({ existingMappings: newData });
            updateParams(isRequired || isEditable ? 'fieldMappings' : 'customFieldMappings', updatedMappings);
        }
    };

    updateCustomTarget = (index: number) => (event) => {
        const { updateParams } = this.props;
        const { existingMappings } = this.state;
        const { value: newTargetName } = event.target;

        const existingCustomMappings = existingMappings.reduce((acc, curr: any) => {
            if (!curr.required && !curr.optional && curr.target !== undefined && curr.target !== '') {
                return { ...acc, [curr.target]: curr.source };
            }
            return acc;
        }, {});

        if (Object.keys(existingCustomMappings).length && newTargetName !== '') {
            const updatedMappings = {
                ...existingCustomMappings,
                [newTargetName]: existingMappings[index].source,
            };

            updateParams('customFieldMappings', updatedMappings);
        }
    };

    updateCustomTargetState = (index: number) => (ev) => {
        const { value } = ev.target;
        const { existingMappings } = this.state;
        const newData = existingMappings.map((m, i) => (i === index ? { ...m, target: value } : m));
        this.setState({ existingMappings: newData });
    };

    addRow = () => {
        const { existingMappings } = this.state;
        this.setState({
            existingMappings: [
                ...existingMappings,
                {
                    source: undefined,
                    target: undefined,
                    required: false,
                    optional: false,
                },
            ],
        });
    };

    removeRow = (index: number) => {
        const { node, onUpdate } = this.props;
        const { existingMappings } = this.state;
        const newData = existingMappings.filter((m, i) => i !== index);
        const keyТoRemove = Object.keys(existingMappings)[index];
        const nodeRemoveRecords = { ...node.params.removedDefault };

        const newRemovedOptional = existingMappings[keyТoRemove].optional
            ? {
                  ...nodeRemoveRecords,
                  [existingMappings[keyТoRemove].target]: existingMappings[keyТoRemove].source || '',
              }
            : {
                  ...nodeRemoveRecords,
              };

        this.setState({ existingMappings: newData });

        let newRequired = {};
        let newCustom = {};

        newData.forEach((d) => {
            if (d.required || d.optional) {
                newRequired = {
                    ...newRequired,
                    [d.target]: d.source,
                };
            } else {
                newCustom = {
                    ...newCustom,
                    [d.target]: d.source,
                };
            }
        });
        const n = {
            ...node,
            params: {
                ...node.params,
                fieldMappings: newRequired,
                customFieldMappings: newCustom,
                removedDefault: newRemovedOptional,
            },
        };

        onUpdate(n);
    };

    render() {
        const { classes, sourceFields, customFieldsPattern, renderDefaultFields } = this.props;
        const { existingMappings } = this.state;
        const excludeDefaultFields = _.reject(
            [...existingMappings],
            ({ optional, required }) => optional === true || required === true,
        );
        const mappings = renderDefaultFields ? existingMappings : excludeDefaultFields;

        // In case of custom object, default fields are not included, so we need index correction to addres correct field
        const indexCorrection = existingMappings.length - mappings.length;

        return (
            <fieldset>
                <legend>{this.i18n('mappings_legend')}</legend>
                {mappings.map((field, k) => (
                    // eslint-disable-next-line
                    <div key={`em${k}`}>
                        <div className={classes.kvpWrapper}>
                            <Form.Item
                                className={classes.kvpInput}
                                label={k === 0 && this.i18n('mappings_source_field')}
                            >
                                <InputDataFieldSingle
                                    treeData={sourceFields}
                                    value={field.source}
                                    onChange={this.updateNodeMapping(k + indexCorrection)}
                                    disabled={false}
                                    hasPreviewData={sourceFields.length > 0}
                                    placeholderTree={
                                        field.required
                                            ? `*${this.i18n('mappings_source_field_placeholder')}`
                                            : this.i18n('mappings_source_field_placeholder')
                                    }
                                    placeholderInput={this.i18n('mappings_source_field_search')}
                                    size="large"
                                />
                            </Form.Item>
                            <div className={k === 0 ? classes.arrowIcon : classes.arrowIconCompact}>
                                <FontAwesomeIcon icon={icons.solid.faArrowCircleRight} />
                            </div>
                            <Form.Item
                                className={!field.required ? classes.kvpNarrowInput : classes.kvpInput}
                                label={k === 0 && this.i18n('mappings_target_field')}
                                help={
                                    customFieldsPattern
                                        ? !field.required &&
                                          field.optional &&
                                          field.target !== undefined &&
                                          !field.target.match(customFieldsPattern) &&
                                          this.i18n('custom_target_error_message')
                                        : null
                                }
                                validateStatus={
                                    customFieldsPattern &&
                                    !field.required &&
                                    field.optional &&
                                    field.target !== undefined &&
                                    !field.target.match(customFieldsPattern)
                                        ? 'error'
                                        : 'validating'
                                }
                            >
                                <Tooltip trigger={['hover']} title={field.target} placement="topLeft">
                                    <div>
                                        <Input
                                            value={field.required ? `*${field.target}` : field.target}
                                            disabled={field.required || field.optional}
                                            size="large"
                                            type="text"
                                            placeholder={this.i18n('mappings_target_field_placeholder')}
                                            onChange={this.updateCustomTargetState(k + indexCorrection)}
                                            onBlur={
                                                !(field.required && field.optional)
                                                    ? this.updateCustomTarget(k + indexCorrection)
                                                    : undefined
                                            }
                                        />
                                    </div>
                                </Tooltip>
                            </Form.Item>
                            {!field.required && (
                                <div
                                    className={`${classes.dynamicDeleteButton} ${
                                        k === 0 ? classes.dynamicDeleteButtonFirstElementOnly : ''
                                    }`}
                                >
                                    <MinusCircleOutlined onClick={() => this.removeRow(k + indexCorrection)} />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                <Button className={classes.addRowBtn} onClick={this.addRow} type="dashed">
                    <PlusOutlined />
                    {this.i18n('mappings_add_row_title')}
                </Button>
            </fieldset>
        );
    }
}

export default withStyles(styles)(injectIntl(SalesforceObjectFieldMappings));
