import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { get, isEqual, set } from 'lodash';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FunctionType, Node } from '../../../../../dazzler-types';
import { transformArrToObj } from '../../../../../helpers';

type Props = {
    node: Node;
    functionType: FunctionType;
    onUpdate: (Node) => void;
    i18nPrefix: string;
};

const styles = (theme) => ({
    kvpWrapper: {
        display: 'flex',
        marginTop: theme.paddingXs,
    },
    kvpInput: {
        maxWidth: '42%',
        minWidth: '42%',
    },
    dynamicDeleteButton: {
        width: '10%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: '4px',
        fontSize: theme.fontSizeXxl,
        color: theme.neutral05,
        transition: 'all .3s',
    },
    addRowBtn: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        marginBottom: theme.paddingMd,
    },
});

type Endpoint = {
    name: string;
    url: string;
};

type ParameterMappingItem = {
    key: string;
    value: string;
};

const useStyles = createUseStyles(styles);

function GoTransverseQueryParametersItem(props: Props) {
    const classes = useStyles();
    const [selectedParams, setSelectedParams] = useState([]);

    const { node, onUpdate, functionType } = props;
    const [parameterMappings, setParameterMappings] = useState([]);

    const intl = useIntl();

    const i18n = (values: string) => intl.formatMessage({ id: `${props.i18nPrefix}.${values}` });

    const getAvailableParams = (allParams, endpoint: Endpoint) => {
        const { billingAccountsParams, usageLookupTablesParams } = allParams;
        if (endpoint.url.includes('billing-accounts')) {
            return Object.entries(billingAccountsParams.properties);
        }
        if (endpoint.url.includes('usage-lookup-tables')) {
            return Object.entries(usageLookupTablesParams.properties);
        }
        return [];
    };

    const updateParams = (type, newData) => {
        set(node.params, type, transformArrToObj(newData));
        onUpdate(node);
    };

    const removeRow = (type, k) => {
        const newData = parameterMappings.filter((m, i) => i !== k);
        setParameterMappings(newData);
        updateParams(type, newData);
    };

    const onUpdateKeyOrValue = (type, part, index) => (ev) => {
        const nodeKeyName = type;
        const inputValue = part === 'key' ? ev : ev.target.value;
        const newData = [...parameterMappings];
        if (newData[index]) {
            newData[index][part] = inputValue;
        } else {
            newData.push({ [part]: inputValue });
        }
        setParameterMappings(newData);
        updateParams(nodeKeyName, newData);
    };

    const addRow = () => {
        setParameterMappings([...parameterMappings, { key: '', value: '' }]);
    };

    useEffect(() => {
        const selectedApiEndpoint = get(node.params, 'apiEndpoint', '');
        const queryParameters = get(node.params, 'queryParameters', []);
        const availableEndpoints = get(functionType, 'params.properties.endpoints.enum', []);
        const supportedQueryParams = get(functionType, 'params.supportedQueryParams.properties', {});

        let endpoint = availableEndpoints[0];
        if (selectedApiEndpoint) {
            endpoint = availableEndpoints.find((t) => isEqual(t.url, selectedApiEndpoint));
        }
        setSelectedParams(getAvailableParams(supportedQueryParams, endpoint));
        const pm = [];
        Object.entries(queryParameters).forEach((qp) => {
            if (Array.isArray(qp[1])) {
                qp[1].forEach((v) => {
                    pm.push({ key: qp[0], value: v });
                });
            } else {
                pm.push({ key: qp[0], value: qp[1] });
            }
        });
        setParameterMappings(pm);
    }, [node.params.apiEndpoint]);

    const drawParameterMapping = (parameterMappings || []).map((parameterMappingItem: ParameterMappingItem, index) => (
        <div key={parameterMappingItem.key}>
            <div className={classes.kvpWrapper}>
                <Form.Item className={classes.kvpInput} label={i18n('queryparam_key_label')}>
                    <Select
                        value={parameterMappingItem.key}
                        size="large"
                        showSearch
                        placeholder={i18n('queryparam_key_placeholder')}
                        getPopupContainer={({ parentNode }) => parentNode}
                        onChange={onUpdateKeyOrValue('queryParameters', 'key', index)}
                    >
                        {selectedParams.map((p) => (
                            <Select.Option key={p[0]} value={p[0]}>
                                <Tooltip key={`${p[0]}t`} placement="bottomLeft" title={p[0]}>
                                    {p[0]}
                                </Tooltip>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item className={classes.kvpInput} label={i18n('queryparam_value_label')}>
                    <Input
                        value={parameterMappingItem.value}
                        size="large"
                        type="text"
                        placeholder={i18n('queryparam_value_placeholder')}
                        onChange={onUpdateKeyOrValue('queryParameters', 'value', index)}
                    />
                </Form.Item>
                <div className={classes.dynamicDeleteButton}>
                    <MinusCircleOutlined onClick={() => removeRow('queryParameters', index)} />
                </div>
            </div>
        </div>
    ));

    return (
        <div>
            {drawParameterMapping}
            <Button className={classes.addRowBtn} onClick={addRow} type="dashed">
                <PlusOutlined />
                {i18n('parameter_mapping_button_text')}
            </Button>
        </div>
    );
}

export default GoTransverseQueryParametersItem;
