import { User } from '../../../services/user';
import type { Account } from '../../dazzler-types';

type LocalStorageAccount = {
    accountId: string;
    userId: string;
};

const addOrUpdateLastActiveAccount = (user: User, account: Account) => {
    const localStorageAccount = localStorage.getItem('lastActiveAccounts');
    const lastActiveAccounts: Array<LocalStorageAccount> = localStorageAccount ? JSON.parse(localStorageAccount) : [];

    let userExists = false;
    if (lastActiveAccounts.length && user && account) {
        lastActiveAccounts.forEach((lsa, index) => {
            if (lsa.userId === user?.id) {
                userExists = true;
                if (account.id !== lsa.accountId) {
                    // NB: Modify existing user if account has changed
                    const newLsa = {
                        ...lsa,
                        accountId: account.id,
                    };
                    lastActiveAccounts[index] = newLsa;
                    localStorage.setItem('lastActiveAccounts', JSON.stringify(lastActiveAccounts));
                }
            }
        });
    }
    // NB: User doesn't exist in array, add
    if (!userExists && user && account) {
        lastActiveAccounts.push({ userId: user?.id, accountId: account.id });
        localStorage.setItem('lastActiveAccounts', JSON.stringify(lastActiveAccounts));
    }
};

export default addOrUpdateLastActiveAccount;
