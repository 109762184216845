import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'antd';
import { JsonItem } from '../../configurator-fields';
import { PubNubParameters } from '../../parameters/writers';
import { isFeatureEnabled } from '../../../../../feature-toggles';
import type { ConfiguratorProps, FeatureToggleFn } from '../../../../../dazzler-types';

const PubNubConfigurator = ({ node, onUpdate }: ConfiguratorProps) => {
    const intl = useIntl();
    const isFeatureToggleEnabled: FeatureToggleFn = useSelector(isFeatureEnabled);
    const isVersionControlPostPhase2Enabled: boolean = isFeatureToggleEnabled('versionControlPostPhase2');

    return (
        <>
            {!isVersionControlPostPhase2Enabled && <PubNubParameters node={node} onUpdate={onUpdate} />}
            <Form layout="vertical">
                <fieldset>
                    <legend>
                        <FormattedMessage id="function_config.writer.pubnub.message_legend" />
                    </legend>

                    <JsonItem
                        label={<FormattedMessage id="function_config.writer.pubnub.message" />}
                        path="message"
                        node={node}
                        onUpdate={onUpdate}
                        required
                        value={node.params.message}
                        placeholder={intl.formatMessage({
                            id: 'function_config.writer.pubnub.message_placeholder',
                        })}
                    />
                </fieldset>
            </Form>
        </>
    );
};

export default PubNubConfigurator;
