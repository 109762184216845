import { Middleware } from 'redux';
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import sagas from './sagas';
import accounts from '../packages/accounts';
import aggregationInspector from '../packages/aggregation';
import audit from '../packages/audit';
import customAudit from '../packages/custom-audit';
import dataCorrection from '../packages/data-correction';
import featureToggles from '../packages/feature-toggles';
import feedback from '../packages/feedback';
import groups from '../packages/groups';
import intl from '../packages/dazzler-intl';
import { solutionsSlice } from '../features/solutions/solutionsSlice';
import streams from '../packages/streams';
import { deploymentsReducer as deployments } from '../packages/deployments';
import { metricsReducer as metrics } from '../packages/metrics';
import { reducer as logs } from '../packages/logs';
import { reducers as functions } from '../packages/functions';
import { reducers as validations } from '../packages/validations';
import { listenerMiddleware, routerSlice } from './listener-middleware';
import { api } from './api';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    accounts,
    aggregationInspector,
    audit,
    customAudit,
    dataCorrection,
    deployments,
    featureToggles,
    feedback,
    functions,
    groups,
    intl,
    logs,
    metrics,
    [solutionsSlice.name]: solutionsSlice.reducer,
    streams,
    validations,
    router: routerSlice.reducer,
    [api.reducerPath]: api.reducer,
});

export const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
            listenerMiddleware.middleware,
            sagaMiddleware,
            api.middleware,
        );

        if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENABLE_LOGGER === 'true') {
            middleware.push(logger as Middleware);
        }

        return middleware;
    },
    reducer: rootReducer,
});

sagaMiddleware.run(sagas);
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
