export const readers = {
    auditLog: 'readers/audit_log',
    azureBlobStorage: 'readers/azure_blob_storage',
    clx: 'readers/clx',
    counter: 'readers/counter',
    dataCorrection: 'readers/data_correction',
    dataGenerator: 'readers/data_generator',
    dropbox: 'readers/dropbox',
    file: 'readers/file',
    googleCloudStorage: 'readers/google_cloud_storage',
    http: 'readers/http',
    httpServer: 'readers/http-server',
    httpServerOneWay: 'readers/http-server-oneway',
    interconnect: 'readers/interconnect',
    onlineCollector: 'readers/online_collector',
    pubnub: 'readers/pubnub',
    kafka_consumer: 'readers/kafka_consumer',
    s3: 'readers/s3',
    s3DataExchange: 'readers/s3_dataexchange',
    script: 'readers/script',
    sftp: 'readers/sftp',
    slack: 'readers/slack',
    soap: 'readers/soap',
    usageDataHub: 'readers/usage_data_hub',
} as const;

export const transforms = {
    abort: 'transforms/abort',
    aggregate: 'transforms/aggregate',
    audit: 'transforms/audit',
    clxRcsMessage: 'transforms/clx-rcs-message',
    clxRcsText: 'transforms/clx-rcs-text',
    dataAggregate: 'transforms/data_aggregate',
    databaseQuery: 'transforms/database_query',
    dataMasking: 'transforms/data_masking',
    dataMaskingDecryption: 'transforms/data_masking_decryption',
    dataMaskingEncryption: 'transforms/data_masking_encryption',
    date: 'transforms/date',
    deduplicate: 'transforms/deduplicate',
    dynamodb: 'transforms/dynamodb',
    enrichment: 'transforms/enrichment',
    gotransverseQuery: 'transforms/gotransverse_query',
    http: 'transforms/http',
    map: 'transforms/map',
    rating: 'transforms/rating',
    revenueCloud: 'transforms/revenuecloud',
    salesforceBulk: 'transforms/salesforce_bulk',
    salesforceQuery: 'transforms/salesforce_query',
    sapEmsConsume: 'transforms/sap_ems_consume',
    sapEmsCreate: 'transforms/sap_ems_create',
    sapEmsQuery: 'transforms/sap_ems_query',
    sapSbCreateCustomer: 'transforms/sap_sb_create_customer',
    sapSbCreateSubscription: 'transforms/sap_sb_create_subscription',
    sapSbQuery: 'transforms/sap_sb_query',
    script: 'transforms/script',
    scriptAggregate: 'transforms/script_aggregate',
    sleepTimer: 'transforms/sleep_timer',
    soap: 'transforms/soap',
    split: 'transforms/split',
    telemetryAggregator: 'transforms/telemetry_aggregator',
    throttle: 'transforms/throttle',
    usageMetering: 'transforms/meter',
    validate: 'transforms/validate',
    xaccumulate: 'transforms/xaccumulate',
    xmap: 'transforms/xmap',
    zuoraDataQuery: 'transforms/zuora_data_query',
    zuoraImportUsage: 'transforms/zuora_import_usage',
    zuoraLookup: 'transforms/zuora_lookup',
    zuoraPost: 'transforms/zuora_post',
    zuoraPostUsage: 'transforms/zuora_post_usage',
    zuoraQuery: 'transforms/zuora_query',
} as const;

export const writers = {
    abort: 'writers/abort',
    azureBlobStorage: 'writers/azure_blob_storage',
    clx: 'writers/clx',
    clxRcsFile: 'writers/clx-rcs-file',
    database: 'writers/database',
    dynamodb: 'writers/dynamodb',
    email: 'writers/email',
    email2: 'writers/email-2.0',
    emailNotification: 'writers/email_notification',
    file: 'writers/file',
    googleCloudStorage: 'writers/google_cloud_storage',
    gotransverseUsage: 'writers/gotransverse_usage',
    http: 'writers/http',
    httpServer: 'writers/http-server',
    interconnect: 'writers/interconnect',
    kafkaProducer: 'writers/kafka_producer',
    log: 'writers/log',
    noop: 'writers/noop',
    pubnub: 'writers/pubnub',
    revenueCloud: 'writers/revenuecloud',
    s3: 'writers/s3',
    s3Rotating: 'writers/s3rotating',
    sapHana: 'writers/sap_hana',
    sftp: 'writers/sftp',
    slack: 'writers/slack',
    sns: 'writers/sns',
    soap: 'writers/soap',
    twilio: 'writers/twilio',
    usageDataHub: 'writers/usage_data_hub',
    zuoraPostUsage: 'writers/zuora_post_usage',
    zuoraUsage: 'writers/zuora_usage',
    zuoraUsageFile: 'writers/zuora_usage_file',
} as const;
