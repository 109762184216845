import React, { useEffect } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { locationChanged } from './app/listener-middleware';

const RouteOverlay = ({ children }) => {
    const location = useLocation();
    const navigationType = useNavigationType();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(locationChanged({ location, navigationType }));
    }, [location, navigationType, dispatch]);

    const childProps = {
        redirect: navigate,
    };

    return <div>{React.Children.map(children, (child) => React.cloneElement(child, { ...childProps }))}</div>;
};

export default RouteOverlay;
