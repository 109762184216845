import * as React from 'react';
import { withStyles } from '@digitalroute-internal/dazzlerjs-react-ui';
import { WithStylesProps } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { Popover } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { icons } from '../../resources';
import { getFunctionDocUrl } from '../../docs';
import type { FunctionType } from '../../dazzler-types';

const styles = (theme) => ({
    descriptionIcon: {
        float: 'right',
    },
    popoverInner: {
        maxWidth: '1200px',
    },
    betaBadge: {
        float: 'right',
        textAlign: 'center',
        color: theme.white,
        fontFamily: theme.fontFamily,
        fontSize: theme.fontSizeSm,
        backgroundColor: theme.brand05,
        padding: '2px 6px',
        borderRadius: '20px',
        marginLeft: '10px',
        cursor: 'pointer',
    },
    '@media only screen and (max-width: 1450px)': {
        popoverInner: {
            maxWidth: '900px',
        },
    },
});

interface Props extends WithStylesProps<typeof styles> {
    functionType: FunctionType;
    children: any;
}

const NodeDescriptionPopover = ({ classes, functionType, children }: Props) => {
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id });

    if (functionType.stage === 'beta') {
        const betaText = i18n('function_description.beta-function');
        const betaContent = (
            <div className={classes.popoverInner}>
                <ReactMarkdown>{betaText}</ReactMarkdown>
            </div>
        );

        return (
            <Popover content={betaContent} placement="bottomLeft" arrowPointAtCenter>
                <span className={classes.betaBadge}>
                    <FormattedMessage id="streameditor.library.beta" />
                </span>
            </Popover>
        );
    }

    const helpUrl = getFunctionDocUrl(functionType.type);
    const helpText = i18n(`function_description.${functionType.type}`);
    const helpContent = (
        <div className={classes.popoverInner}>
            <ReactMarkdown>{helpText}</ReactMarkdown>
            <p>
                <a target="_blank" href={helpUrl} rel="noopener noreferrer">
                    <FontAwesomeIcon style={{ marginRight: '8px' }} icon={icons.regular.faBookReader} />
                    {i18n('function_help_link_label')}
                </a>
            </p>
        </div>
    );

    return (
        <Popover content={helpContent} placement="bottomLeft" arrowPointAtCenter>
            {children}
        </Popover>
    );
};

export default withStyles(styles)(NodeDescriptionPopover);
