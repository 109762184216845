const DETAIL_LOCAL = 'detailLocalStorage';
const SUMMARY_LOCAL = 'summaryLocalStorage';

interface DetailOptions {
    widget: boolean;
    session_created_ts: boolean;
    planned_timeout_ts: boolean;
    aggregated_field?: boolean;
    aggregated_value?: boolean;
}

interface SummaryOptions {
    widget: boolean;
    stream_name: boolean;
    node_name: boolean;
    count: boolean;
    oldest_created_ts: boolean;
    newest_created_ts: boolean;
}

export const defaultDetail: DetailOptions = {
    widget: true,
    session_created_ts: true,
    planned_timeout_ts: true,
};

const defaultSummary: SummaryOptions = {
    widget: true,
    stream_name: true,
    node_name: true,
    count: true,
    oldest_created_ts: true,
    newest_created_ts: true,
};

export const getDisplayOptionsDetail = (id: string): DetailOptions => {
    const options = JSON.parse(localStorage.getItem(`${DETAIL_LOCAL}__${id}`));
    return options || defaultDetail;
};

export const getDisplayOptionsSummary = (): SummaryOptions => {
    const options = JSON.parse(localStorage.getItem(SUMMARY_LOCAL));
    return options || defaultSummary;
};

export const setDisplayOptionsDetail = (options: DetailOptions, id: string): void => {
    localStorage.setItem(`${DETAIL_LOCAL}__${id}`, JSON.stringify(options));
};

export const setDisplayOptionsSummary = (options: SummaryOptions): void => {
    localStorage.setItem(SUMMARY_LOCAL, JSON.stringify(options));
};

export const resetDisplayOptionsDetail = (id: string): void => {
    localStorage.removeItem(`${DETAIL_LOCAL}__${id}`);
};

export const resetDisplayOptionsSummary = (): void => {
    localStorage.removeItem(SUMMARY_LOCAL);
};
