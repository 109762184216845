type Conditional = {
    field?: string;
    inputType?: string;
    key?: string;
    name?: string;
    operation?: string;
    type?: string;
    value?: any;
};
export type ConditionalProps = {
    setConditionals: (conditionalsArray: Conditional[]) => void;
    conditionals: Conditional[];
};

export const FLUSH_ALL_FILES = 'ALL_FILES';
export const FLUSH_EACH_FILE = 'EACH_FILE';
export const TIMEOUT = 'TIMEOUT';

export type FormDetails = {
    validateStatus: 'error' | 'success';
    help: string | null;
};

export enum ConditionalTypes {
    GENERAL_COUNT = 'COUNT',
    NUMERIC_AVERAGE = 'AVG',
    NUMERIC_MAX = 'MAX',
    NUMERIC_MIN = 'MIN',
    NUMERIC_SUM = 'SUM',
}

export type Operation = {
    key: string;
    field?: string;
    type?: string;
};

export type Group = {
    key: string;
    field?: string;
    operationType: string;
};

export type CompoundType = Array<Group | Operation | Conditional>;
