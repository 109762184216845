import { handleActions } from 'redux-actions';
import { StreamInstance } from '../../../../../packages/dazzler-types';
import { setSelection } from '../actions/selected';

export type State = StreamInstance[];

export const initialState: State = [];

export const reducer = handleActions<State, State>(
    {
        [setSelection.toString()]: (_state, { payload }): State => payload,
    },
    initialState,
);
