import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Form } from 'antd';
import TreeSelectInput, { SHOW_CHILD } from '../../configurator-fields/TreeSelectInput';
import RadioTrioItem, { FIRST_VALUE, SECOND_VALUE, THIRD_VALUE } from '../../configurator-fields/RadioTrioItem';
import { Node } from '../../../../../dazzler-types';
import { selectors as nodeValidation } from '../../../../../validations';

const SHA256 = 'sha256';
const SHA1 = 'sha1';
const MD5 = 'md5';
const KEY_ALGORITHM = 'algorithm';
const KEY_INPUT_FIELDS = 'inputFields';

type SelectionType = typeof FIRST_VALUE | typeof SECOND_VALUE | typeof THIRD_VALUE;
type AlgorithmType = typeof SHA256 | typeof SHA1 | typeof MD5;

type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
};

type FormDetails = {
    validateStatus: 'error' | 'success';
    help: string | null;
};

const mapAlgorithm = (algorithm: AlgorithmType): SelectionType => {
    switch (algorithm) {
        case SHA1:
            return SECOND_VALUE;
        case MD5:
            return THIRD_VALUE;
        default:
            return FIRST_VALUE;
    }
};
const styles = () => ({
    inputWrapper: {
        display: 'flex',
        width: '100%',
    },
    legendForRadio: {
        marginBottom: 0,
    },
});

const useStyles = createUseStyles(styles);

const HashingConfigurator = ({ node, onUpdate }: Props) => {
    const getValidation = useSelector((state) => nodeValidation.getNodeValidation(state, node.id));
    const getErrorMessage = (dataPath: string) =>
        useSelector((state) => nodeValidation.getNodeValidationMessage(node.id, dataPath)(state));
    const isPropertyMissing = (field) =>
        getValidation?.some((error) => error.keyword === 'required' && error.params?.missingProperty === field);
    const getInputFieldsErrorMessage = getErrorMessage(`/${KEY_INPUT_FIELDS}`);
    const formDetails = (): FormDetails => ({
        validateStatus: isPropertyMissing(KEY_INPUT_FIELDS) || getInputFieldsErrorMessage ? 'error' : 'success',
        help: !isPropertyMissing(KEY_INPUT_FIELDS) ? getInputFieldsErrorMessage : null,
    });
    const { algorithm, inputFields } = node.params;
    const updateNodeParams = (key: string, value: any): void => {
        const n = {
            ...node,
            params: {
                ...node.params,
                [key]: value,
            },
        };

        onUpdate(n);
    };
    const selectedAlgorithm = mapAlgorithm(algorithm);

    useEffect(() => {
        if (!algorithm) {
            updateNodeParams(KEY_ALGORITHM, SHA256);
        }
    }, []);

    const intl = useIntl();
    const classes = useStyles();
    const i18n = (id: string, prefix = 'function_config.transform.data_masking') =>
        intl.formatMessage({ id: `${prefix}.${id}` });

    const onUpdateFields = (fields: Array<string>): void => {
        updateNodeParams(KEY_INPUT_FIELDS, fields);
    };
    const onChangeAlgorithm = (option: SelectionType): void => {
        switch (option) {
            case FIRST_VALUE:
                updateNodeParams(KEY_ALGORITHM, SHA256);
                break;
            case SECOND_VALUE:
                updateNodeParams(KEY_ALGORITHM, SHA1);
                break;
            default:
                updateNodeParams(KEY_ALGORITHM, MD5);
        }
    };

    return (
        <Form layout="vertical">
            <legend>{i18n('input_section')}</legend>
            <fieldset className={classes.inputWrapper}>
                <Form.Item required label={i18n('input_field')} className={classes.inputWrapper} {...formDetails()}>
                    <TreeSelectInput
                        node={node}
                        showCheckedStrategy={SHOW_CHILD}
                        onChange={onUpdateFields}
                        value={inputFields || []}
                        placeholderTree={i18n('input_placeholder')}
                    />
                </Form.Item>
            </fieldset>
            <legend className={classes.legendForRadio}>{i18n('legend_algorithms')}</legend>
            <RadioTrioItem
                firstOption={i18n('sha256')}
                secondOption={i18n('sha1')}
                thirdOption={i18n('md5')}
                value={selectedAlgorithm}
                onChange={onChangeAlgorithm}
            />
        </Form>
    );
};

export default HashingConfigurator;
