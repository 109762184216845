import React from 'react';
import { createUseStyles } from 'react-jss';
import { Switch } from '@digitalroute-internal/dazzlerjs-react-ui';
import { has, set, unset } from 'lodash';
import { Node } from '../../../../../dazzler-types';
import NumberInputItem from '../../configurator-fields/NumberInputItem';

const styles = (theme: Record<string, any>) => ({
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
    rightElement: {
        marginRight: theme.marginXs,
    },
});

type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18n: (id: string, prefix?: string, values?: any) => string;
};

const MIN_MAX_CACHE_ITEMS = 0;
const MIN_MAX_CACHE_SECONDS = 1;
const CACHE_ENABLED_PATH = 'cache';
const I18N_CACHE_PREFIX = 'function_config.generic.database';
const I18N_ZUORA_QUERY_PREFIX = 'function_config.transforms.zuora_query';
const MAX_CACHE_ITEMS_PATH = 'cache.maxCacheItems';
const MAX_CACHE_SECONDS_PATH = 'cache.maxCacheSeconds';
const DEFAULT_MAX_CACHE_ITEMS = 100;
const DEFAULT_MAX_CACHE_SECONDS = 300;
const TEST_ID = 'zuora_query_cache_item';

function ZuoraQueryCacheItem({ node, onUpdate, i18n }: Props) {
    const useStyles = createUseStyles(styles);
    const classes = useStyles();
    const isCacheEnabled = has(node, `params.${CACHE_ENABLED_PATH}`);

    const enableCacheChange = (value: boolean) => {
        if (value) {
            set(node, `params.${MAX_CACHE_ITEMS_PATH}`, DEFAULT_MAX_CACHE_ITEMS);
            set(node, `params.${MAX_CACHE_SECONDS_PATH}`, DEFAULT_MAX_CACHE_SECONDS);
        } else {
            unset(node, `params.${CACHE_ENABLED_PATH}`);
        }
        onUpdate(node);
    };

    return (
        <fieldset>
            <div className={classes.grid}>
                <legend>{i18n('cache_settings', I18N_CACHE_PREFIX)}</legend>
                <div className={classes.rightSection}>
                    <Switch
                        onChange={enableCacheChange}
                        checked={isCacheEnabled}
                        overrideClass={classes.rightElement}
                        data-testid={`${TEST_ID}_cache_switch`}
                    />
                    {i18n('enable_cache', I18N_CACHE_PREFIX)}
                </div>
            </div>
            {isCacheEnabled && (
                <>
                    <NumberInputItem
                        label={i18n('max_cache_items', I18N_CACHE_PREFIX)}
                        min={MIN_MAX_CACHE_ITEMS}
                        node={node}
                        path={MAX_CACHE_ITEMS_PATH}
                        onUpdate={onUpdate}
                        suffix={i18n('max_cache_items_suffix', I18N_ZUORA_QUERY_PREFIX)}
                        defaultValue={0}
                        data-testid={`${TEST_ID}_max_cache_items_input`}
                    />
                    <NumberInputItem
                        label={i18n('max_cache_seconds', I18N_CACHE_PREFIX)}
                        min={MIN_MAX_CACHE_SECONDS}
                        node={node}
                        path={MAX_CACHE_SECONDS_PATH}
                        onUpdate={onUpdate}
                        suffix={i18n('max_cache_seconds_suffix', I18N_ZUORA_QUERY_PREFIX)}
                        data-testid={`${TEST_ID}_max_cache_seconds_input`}
                    />
                </>
            )}
        </fieldset>
    );
}

export default ZuoraQueryCacheItem;
