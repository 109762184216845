import React, { useCallback } from 'react';
import { set } from 'lodash';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { isFeatureEnabled } from '../../../../../feature-toggles';
import { getNodeInputFieldsTreeData, getSourceNodes, StreamsPreviewState } from '../../../../../streams';
import ZuoraFieldMappingsItemUsageAr from '../../common/zuora/ZuoraFieldMappingsItemUsageAr';
import ZuoraFileInformationItem from '../../common/zuora/ZuoraFileInformationItem';
import ZuoraFieldMappingsItem from '../../common/zuora/ZuoraFieldMappingsItem';
import { ZuoraUsageParameters } from '../../parameters/general';
import { CUSTOM_FIELDS_PATTERN, FILE_SIZE_LIMIT, writers, transforms } from '../../../../../dazzler-constants';
import type { ConfiguratorProps, FeatureToggleFn, Node } from '../../../../../dazzler-types';

const ZuoraUsageConfigurator = ({ functionType, stream, node, onUpdate }: ConfiguratorProps): JSX.Element => {
    const isFeatureToggleEnabled: FeatureToggleFn = useSelector(isFeatureEnabled);
    const isVersionControlPostPhase2Enabled: boolean = isFeatureToggleEnabled('versionControlPostPhase2');
    const shouldShowDescription = functionType.type === transforms.zuoraImportUsage;
    const sourceFields: Object[] = useSelector((state: StreamsPreviewState) =>
        getNodeInputFieldsTreeData(state, node, stream),
    );
    const sourceNodes: Node[] = useSelector((state: any) => getSourceNodes(state, node.id));

    const updateParams = useCallback(
        (field: string, value: unknown) => {
            set(node.params, [field], value);
            onUpdate(node);
        },
        [node],
    );

    return (
        <>
            {!isVersionControlPostPhase2Enabled && (
                <ZuoraUsageParameters functionType={functionType} node={node} onUpdate={onUpdate} />
            )}
            <Form layout="vertical">
                {functionType.type === writers.zuoraPostUsage ? (
                    <ZuoraFieldMappingsItemUsageAr
                        node={node}
                        functionType={functionType}
                        sourceFields={sourceFields}
                        customFieldsPattern={CUSTOM_FIELDS_PATTERN}
                        updateParams={updateParams}
                        onUpdate={onUpdate}
                    />
                ) : (
                    <>
                        <ZuoraFileInformationItem
                            shouldUseDescription={shouldShowDescription}
                            fileSizeLimit={FILE_SIZE_LIMIT[functionType.type]}
                            node={node}
                            updateParams={updateParams}
                            onUpdate={onUpdate}
                            functionType={functionType}
                            stream={stream}
                            sourceNodes={sourceNodes}
                        />
                        <ZuoraFieldMappingsItem
                            node={node}
                            functionType={functionType}
                            sourceFields={sourceFields}
                            customFieldsPattern={CUSTOM_FIELDS_PATTERN}
                            updateParams={updateParams}
                            onUpdate={onUpdate}
                        />
                    </>
                )}
            </Form>
        </>
    );
};

export default ZuoraUsageConfigurator;
