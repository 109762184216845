import React from 'react';
import { Divider, Switch, Paragraph } from '@digitalroute-internal/dazzlerjs-react-ui';
import { unset, get, set } from 'lodash';
import { Node } from '../../../../../dazzler-types';
import { ZuoraActiveRatingEnum } from './ZuoraEnums';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    classes: any;
    i18n: (id: string, values?: string) => string;
};

const ZuoraUsageRecordRequestField = ({ node, onUpdate, classes, i18n }: Props) => {
    const endpoint = get(node, 'params.zuoraApiEndpoint', ZuoraActiveRatingEnum.DISABLED);
    const setEndpoint = (isChecked) => {
        unset(node.params, 'customFieldMappings');
        unset(node.params, isChecked ? 'usageFieldMappings' : 'usageArFieldMappings');
        set(
            node.params,
            'zuoraApiEndpoint',
            isChecked ? ZuoraActiveRatingEnum.ENABLED : ZuoraActiveRatingEnum.DISABLED,
        );
        onUpdate(node);
    };
    const getBannerMessage = () => {
        const activeRatingEnabled = node.params.zuoraApiEndpoint === ZuoraActiveRatingEnum.ENABLED;
        return (
            <div className={`${classes.formMargin} ${classes.fontSizeContainer}`}>
                {i18n('active_rating.banner_text')}{' '}
                <a
                    href={
                        activeRatingEnabled
                            ? 'https://www.zuora.com/developer/archive/active-rating/active-rating-api/#operation/POST_UsageRecords'
                            : 'https://www.zuora.com/developer/api-references/api/operation/Object_POSTUsage/'
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {i18n(
                        activeRatingEnabled
                            ? 'active_rating.enabled_banner_link_text'
                            : 'active_rating.disabled_banner_link_text',
                    )}
                </a>
            </div>
        );
    };
    return (
        <>
            <div className={classes.grid}>
                <legend>{i18n('request_legend')}</legend>
                <div className={classes.rightSection}>
                    <Switch
                        onChange={setEndpoint}
                        checked={endpoint === ZuoraActiveRatingEnum.ENABLED}
                        overrideClass={classes.rightElement}
                        data-testid="ZuoraUsageRecordRequestField-Toggle"
                    />

                    <Paragraph isDescription isBold={false} text={i18n('request_checkbox_label')} />
                </div>
            </div>
            {getBannerMessage()}
            <Divider overrideClass={classes.dividerOverride} />
        </>
    );
};

export default ZuoraUsageRecordRequestField;
