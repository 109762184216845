import React, { useEffect, useState } from 'react';
import { get, set } from 'lodash';
import {
    Divider,
    Button,
    icons,
    FieldMapping,
    OptionProp,
    OptionsType,
} from '@digitalroute-internal/dazzlerjs-react-ui';
import { FunctionType, Node } from '../../../../../dazzler-types';
import { ZuoraActiveRatingEnum } from './ZuoraEnums';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    treeNodeData: any;
    classes: any;
    i18n: (id: string, values?: string) => string;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => any;
    pathMapping: any;
};

const TEST_ID = 'ZuoraUsageRecordOptionalField';

const ZuoraUsageRecordOptionalField = ({
    node,
    onUpdate,
    functionType,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
    pathMapping = {},
}: Props) => {
    // node.params.customFieldMappings is cleared when zuoraApiEndpoint changes
    // refer to ZuoraUsageRecordRequestField.tsx
    const initCustomFieldMapping = get(node, 'params.customFieldMappings', {});
    const [customFieldMapping, setCustomFieldMapping] = useState([]);
    const zuoraEndpoint = node.params.zuoraApiEndpoint || ZuoraActiveRatingEnum.DISABLED;
    const mappingProperties = get(functionType, pathMapping[zuoraEndpoint].properties, {});
    const requiredProperties = get(functionType, pathMapping[zuoraEndpoint].required, []);
    const [targetFieldDropdownOption, setTargetFieldDropdownOption] = useState([]);
    const selectAppendParams = {
        postfix: '__c',
        prefix: '',
    };

    useEffect(() => {
        const optionalFields = Object.keys(mappingProperties).map((val) =>
            requiredProperties.includes(val)
                ? {}
                : {
                      children: [],
                      key: val,
                      title: val,
                      value: val,
                  },
        );
        setTargetFieldDropdownOption(optionalFields.filter((val) => Object.keys(val).length !== 0));

        setCustomFieldMapping(
            Object.keys(initCustomFieldMapping).map((key) =>
                key !== undefined && key !== 'undefined'
                    ? {
                          source: initCustomFieldMapping[key],
                          target: key,
                      }
                    : {},
            ),
        );
    }, [functionType, node.params.zuoraApiEndpoint]);

    useEffect(() => {
        const existingCustomMappings = customFieldMapping.reduce((acc, curr) => {
            if (curr.target || curr.source) {
                return { ...acc, [curr.target]: curr.source };
            }
            return acc;
        }, {});
        set(node.params, 'customFieldMappings', existingCustomMappings);
        onUpdate(node);
    }, [customFieldMapping]);

    const onClickDeleteCustomFieldMapping = (idx: number) => {
        setCustomFieldMapping((prevOptionalRef) => prevOptionalRef.filter((custRef, i) => i !== idx));
    };

    const onClickAddCustomFieldMapping = () => {
        setCustomFieldMapping((prevOptionalRef) => [...prevOptionalRef, { source: undefined, target: undefined }]);
    };

    const onChangeCustomParam = (idx: number, val: string, paramName: string) => {
        const newData = customFieldMapping.map((data, index) => (index === idx ? { ...data, [paramName]: val } : data));
        setCustomFieldMapping(newData);
    };

    return (
        <>
            <legend>{i18n('optional_fields')}</legend>

            <div className={classes.fontSizeContainer}>
                {customFieldMapping.map((arrVal, idx) => {
                    const targetValidation =
                        (!!arrVal.target || !!arrVal.source) && !(!!arrVal.target && !!arrVal.source) && !arrVal.target;
                    const sourceValidation =
                        (!!arrVal.target || !!arrVal.source) && !(!!arrVal.target && !!arrVal.source) && !arrVal.source;
                    const options: OptionProp[] = [
                        {
                            name: 'custom_reference_typecode',
                            label: `* ${i18n('mappings_target_field_placeholder')}`,
                            hasError: targetValidation,
                            errorMessage: i18n('mappings_field_error_message'),
                            isSelect: true,
                            selectOption: {
                                isTree: true,
                                status: targetValidation ? 'error' : undefined,
                                optionData: addAddonDataTreeData(arrVal.target, targetFieldDropdownOption),
                                value: arrVal.target ? arrVal.target : undefined,
                                allowAddon: true,
                                onChange: (val: string) => onChangeCustomParam(idx, val, 'target'),
                                onOptionsChange: (_newOption, newValue) => {
                                    if (newValue) onChangeCustomParam(idx, newValue, 'target');
                                },
                                inputAddonProps: {
                                    isAppend: true,
                                    appendString: selectAppendParams,
                                    inputMessage: i18n('custom_field_info_message'),
                                    inputMessageStatus: 'info',
                                    checkDuplicateValue: true,
                                    checkDuplicateBeforeAppend: true,
                                    errorMessage: i18n('custom_field_error_message'),
                                },
                                'data-testid': `${TEST_ID}-TargetField-${idx}`,
                            },
                        },
                        {
                            name: 'custom_reference_id',
                            label: `* ${i18n('mappings_source_field_placeholder')}`,
                            hasError: sourceValidation,
                            errorMessage: i18n('mappings_field_error_message'),
                            isSelect: true,
                            selectOption: {
                                isTree: true,
                                status: sourceValidation ? 'error' : undefined,
                                allowAddon: true,
                                optionData: addAddonDataTreeData(arrVal.source, treeNodeData),
                                value: arrVal.source ? arrVal.source : undefined,
                                onChange: (val: string) => onChangeCustomParam(idx, val, 'source'),
                                'data-testid': `${TEST_ID}-SourceField-${idx}`,
                                onOptionsChange: (_newOption, newValue) => {
                                    if (newValue) onChangeCustomParam(idx, newValue, 'source');
                                },
                            },
                        },
                    ];

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={classes.formMargin} key={`optionalFieldIdx_${idx}`}>
                            <FieldMapping
                                options={options}
                                onDelete={() => onClickDeleteCustomFieldMapping(idx)}
                                data-testid={`${TEST_ID}-FieldMapping-${idx}`}
                            />
                        </div>
                    );
                })}
            </div>

            <div className={classes.optionalFieldButtonPadding}>
                <Button
                    type="dashed"
                    text={i18n('add_optional_reference')}
                    icon
                    iconName={icons.regular.faPlus}
                    onClick={() => onClickAddCustomFieldMapping()}
                    data-testid={`${TEST_ID}-AddButton`}
                />
            </div>
            <Divider overrideClass={classes.dividerOverride} />
        </>
    );
};

export default ZuoraUsageRecordOptionalField;
