/* eslint-disable react/prop-types */
import React, { Component, Dispatch, ReactNode, SetStateAction } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { injectIntl, IntlShape } from 'react-intl';
import type { Rule } from 'antd/lib/form';
import { FormInstance } from 'antd/es/form';
import { MAX_STREAM_NAME } from '../../dazzler-constants';
import { inputRegex } from '../../helpers/common/validation';

// A function that takes props and returns a restricted input component

type Props = {
    form?: FormInstance;
    propValue?: any;
    defaultValue?: any;
    onChange?: any;
    forbiddeninputs?: any;
    maxLength?: number;
    autoFocus?: boolean;
    rules?: Rule[];
    name?: string;
    intl: IntlShape;
    onBlur?: any;
    onPressEnter?: any;
    label?: ReactNode;
    hasError?: boolean;
    setHasError?: Dispatch<SetStateAction<boolean>>;
    setIsDirty?: Dispatch<SetStateAction<boolean>>;
};

type State = {
    text: string;
    errorType?: any;
};

class RestrictInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { propValue, defaultValue } = props;
        this.state = {
            text: propValue || defaultValue,
        };
    }

    componentDidUpdate(): void {
        const { form, name, setIsDirty } = this.props;

        if (setIsDirty) {
            setIsDirty(form.isFieldTouched(name));
        }
    }

    handleChange = (e) => {
        const { forbiddeninputs, onChange, setHasError } = this.props;
        // check if tooltip should show or not
        const hasRestrictedChars = !inputRegex.test(e.target.value);
        const hasDupName = forbiddeninputs?.filter((s) => s === e.target.value.trim()).length > 0;
        const hasError = hasRestrictedChars || hasDupName;
        // eslint-disable-next-line
        const errorType = hasRestrictedChars ? 'restrictedInput' : hasDupName ? 'dupName' : '';

        this.setState({
            errorType,
            text: e.target.value,
        });

        if (onChange && !hasError) {
            onChange(e);
        }

        if (setHasError) {
            setHasError(hasError);
        }
    };

    validateInput = (_: Rule, value: string) => {
        const { intl } = this.props;
        const hasRestrictedChars = !inputRegex.test(value);

        if (hasRestrictedChars && value) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'restricted_input.forbidden_characters' })));
        }
        return Promise.resolve();
    };

    render() {
        const {
            setIsDirty,
            setHasError,
            form,
            onBlur,
            onPressEnter,
            name,
            intl,
            label,
            hasError,
            maxLength = MAX_STREAM_NAME,
            rules = [],
            autoFocus = true,
            ...rest
        } = this.props;

        const { errorType, text } = this.state;
        const componentProps = {
            ...rest,
            onChange: this.handleChange,
            onPressEnter: hasError
                ? (e) => {
                      e.preventDefault();
                  }
                : onPressEnter,
            onBlur: hasError
                ? (e) => {
                      e.preventDefault();
                  }
                : onBlur,
            value: undefined,
        };

        if (!form) {
            componentProps.value = text;
        }

        return (
            <>
                {form ? (
                    <Form.Item
                        label={label}
                        name={name}
                        rules={[...rules, { pattern: inputRegex, validator: this.validateInput }]}
                    >
                        <Input {...componentProps} autoFocus={autoFocus} maxLength={maxLength} />
                    </Form.Item>
                ) : (
                    <Tooltip
                        visible={hasError && !form}
                        title={
                            errorType === 'restrictedInput'
                                ? intl.formatMessage({ id: 'restricted_input.forbidden_characters' })
                                : intl.formatMessage({ id: 'restricted_input.input_exists' })
                        }
                    >
                        <Input {...componentProps} autoFocus={autoFocus} maxLength={maxLength} />
                    </Tooltip>
                )}
            </>
        );
    }
}

export default injectIntl(RestrictInput);
