import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Form } from 'antd';
import { set } from 'lodash';
import TreeSelectInput, { SHOW_CHILD } from '../../configurator-fields/TreeSelectInput';
import SecretsWalletSelector from '../../common/secrets-wallet/SecretsWalletSelector';
import RadioTrioItem, { FIRST_VALUE, SECOND_VALUE, THIRD_VALUE } from '../../configurator-fields/RadioTrioItem';
import { Node } from '../../../../../dazzler-types';
import { selectors as nodeValidation } from '../../../../../validations';
import { isPreviewForNodeUserTriggered, StreamsPreviewState } from '../../../../../streams';

const paramsAlgorithmKey = 'mode';
const paramsInputFieldsKey = 'inputFields';
const algorithms = ['aes-256-gcm', 'aes-256-ctr', 'aes-256-cbc'] as const;
const selections = [FIRST_VALUE, SECOND_VALUE, THIRD_VALUE] as const;
type SelectionType = (typeof selections)[number];
type AlgorithmType = (typeof algorithms)[number];

const mapValueToAlgorithm: Record<SelectionType, AlgorithmType> = {
    [FIRST_VALUE]: algorithms[0],
    [SECOND_VALUE]: algorithms[1],
    [THIRD_VALUE]: algorithms[2],
};

const mapAlgorithmToValue = (algorithm: AlgorithmType): SelectionType =>
    Object.keys(mapValueToAlgorithm).find((key) => mapValueToAlgorithm[key] === algorithm) as SelectionType;

type ComponentType = 'encrypt' | 'decrypt';

type Props = {
    node: Node;
    onUpdate: (Node) => void;
};

type FormDetails = {
    validateStatus: 'error' | 'success';
    help: string | null;
};

const styles = (theme) => ({
    inputWrapper: {
        display: 'flex',
        width: '100%',
        '&.ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover': {
            borderColor: '#ff4d4f',
        },
        '& .ant-form-item-row': {
            width: '100%',
        },
    },
    inputWrapperSecret: {
        width: '100%',
    },
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
    rightElement: {
        marginRight: theme.marginXs,
    },
    legend: {
        marginLeft: theme.marginXs,
        marginRight: theme.marginXs,
    },
    legendForRadio: {
        marginBottom: 0,
    },
    reset: {
        cursor: 'pointer',
        color: theme.brand05,
        position: 'absolute',
        right: '5px',
        bottom: '45px',
    },
});

const useStyles = createUseStyles(styles);

const EncryptDecryptConfigurator =
    (type: ComponentType) =>
    ({
        node,
        node: {
            params: { inputFields },
        },
        onUpdate,
    }: Props) => {
        const intl = useIntl();
        const classes = useStyles();
        const selectedAlgorithm = mapAlgorithmToValue(node.params[paramsAlgorithmKey]);
        const isPreviewUserTriggered = useSelector((state: StreamsPreviewState) =>
            isPreviewForNodeUserTriggered(state, node.id),
        );
        const getErrorMessage = (dataPath: string) =>
            useSelector((state) => nodeValidation.getNodeValidationMessage(node.id, dataPath)(state));
        const getFieldsError = getErrorMessage(`/${paramsInputFieldsKey}`);
        const formDetails: FormDetails = {
            validateStatus: isPreviewUserTriggered && getFieldsError ? 'error' : 'success',
            help: isPreviewUserTriggered && getFieldsError ? getFieldsError : null,
        };

        const updateNodeParams = (values: object): void => {
            onUpdate({
                ...node,
                params: {
                    ...node.params,
                    ...values,
                },
            });
        };

        const i18n = (id: string, prefix = `function_config.transform.data_masking`) =>
            intl.formatMessage({ id: `${prefix}.${id}` });

        const onUpdateFields = (fields: string[]): void => {
            updateNodeParams({ [paramsInputFieldsKey]: fields });
        };

        const onChangeAlgorithm = (option: SelectionType): void => {
            updateNodeParams({ [paramsAlgorithmKey]: mapValueToAlgorithm[option] });
        };

        useEffect(() => {
            if (!node.params[paramsAlgorithmKey] && !node.params[paramsInputFieldsKey]) {
                set(node.params, paramsAlgorithmKey, algorithms[0]);
                set(node.params, paramsInputFieldsKey, []);
                onUpdate(node);
            }
        }, []);

        return (
            <Form layout="vertical">
                <legend className={classes.legend}>{i18n('input_section')}</legend>
                <fieldset className={classes.inputWrapper}>
                    <Form.Item required label={i18n('input_field')} className={classes.inputWrapper} {...formDetails}>
                        <TreeSelectInput
                            node={node}
                            size="large"
                            showCheckedStrategy={SHOW_CHILD}
                            onChange={onUpdateFields}
                            value={inputFields || []}
                            placeholderTree={i18n('input_placeholder')}
                        />
                    </Form.Item>
                </fieldset>
                <div className={classes.grid}>
                    <legend className={classes.legend}>
                        {i18n('secrets_wallet.legend', `function_config.transform.data_masking_${type}`)}
                    </legend>
                </div>
                <fieldset className={classes.inputWrapper}>
                    <div className={classes.inputWrapperSecret}>
                        <SecretsWalletSelector node={node} onUpdate={onUpdate} />
                    </div>
                </fieldset>
                <legend className={`${classes.legend} ${classes.legendForRadio}`}>{i18n('legend_algorithm')}</legend>
                <RadioTrioItem
                    firstOption={i18n(algorithms[0].split('-')[2])}
                    secondOption={i18n(algorithms[1].split('-')[2])}
                    thirdOption={i18n(algorithms[2].split('-')[2])}
                    value={selectedAlgorithm}
                    onChange={onChangeAlgorithm}
                />
            </Form>
        );
    };

export default EncryptDecryptConfigurator;
