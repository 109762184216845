import React, { ChangeEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { Input, Form } from 'antd';
import { useIntl } from 'react-intl';
import { DataObject, Property } from '../types';
import PropertiesCollapsable from '../properties/PropertiesCollapsable';
import { useValidatorState } from '../context';

const styles = (theme) => ({
    selectOverwrite: {
        '&.ant-select, &.ant-input': {
            marginTop: theme.marginXs,
        },
        marginBottom: theme.marginXs,
    },
});

const useStyles = createUseStyles(styles);

type Props = {
    object: DataObject;
    disabled?: boolean;
};

const ObjectView = ({ object: { key, objectName, properties }, disabled = false }: Props) => {
    const { dispatch, objects } = useValidatorState();
    const classes = useStyles();
    const intl = useIntl();

    const i18n = (id: string) => intl.formatMessage({ id: `function_config.transforms.validator.${id}` });

    const renameObject = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'RENAME_OBJECT',
            payload: { key, value },
        });
    };

    const addProperty = () => {
        dispatch({
            type: 'ADD_OBJECT_PROPERTY',
            payload: key,
        });
    };

    const deleteProperty = (propertyKey: Property['key']) => {
        dispatch({
            type: 'DELETE_OBJECT_PROPERTY',
            payload: { propertyKey, objectKey: key },
        });
    };

    const onCollapseChange = (keys: DataObject['key'][]) => {
        dispatch({
            type: 'EXPAND_OBJECT_PROPERTY',
            payload: { keys, objectKey: key },
        });
    };

    return (
        <>
            {`${i18n('object_name')}:`}
            <Form.Item
                name={key}
                validateTrigger={['onChange', 'onBlur', 'onFocus']}
                rules={[
                    {
                        validator: () => {
                            const isDuplicate = objects.filter((o) => o.objectName === objectName).length > 1;
                            if (!isDuplicate) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error(i18n('object_exists')));
                        },
                    },
                ]}
            >
                <span>
                    <Input
                        className={classes.selectOverwrite}
                        size="middle"
                        type="text"
                        onChange={renameObject}
                        value={objectName}
                        placeholder={i18n('object_name_placeholder')}
                        autoComplete="off"
                        disabled={disabled}
                    />
                </span>
            </Form.Item>
            <PropertiesCollapsable
                isObject
                objectKey={key}
                properties={properties}
                onAdd={addProperty}
                onDelete={deleteProperty}
                onCollapse={onCollapseChange}
                disabled={disabled}
            />
        </>
    );
};

export default ObjectView;
