import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import SecretsWalletSelector from '../../common/secrets-wallet/SecretsWalletSelector';
import { writers } from '../../../../../dazzler-constants';
import type { ParametersProps } from '../../../../../dazzler-types';
import ZuoraEnvironmentItem from '../../common/zuora/ZuoraEnvironmentItem';
import ZuoraMultiEntityItem from '../../common/zuora/ZuoraMultiEntityItem';
import ZuoraRequestItem from '../../common/zuora/ZuoraRequestItem';

const ZuoraUsageFileParameters = ({ functionType, node, onUpdate }: ParametersProps) => (
    <Form layout="vertical">
        <ZuoraEnvironmentItem node={node} onUpdate={onUpdate} functionType={functionType} />
        <fieldset>
            <legend>
                <FormattedMessage id="function_config.writer.zuora_usage_file.authentication_legend" />
            </legend>
            <SecretsWalletSelector node={node} onUpdate={onUpdate} />
        </fieldset>
        {functionType.type === writers.zuoraPostUsage && <ZuoraRequestItem node={node} onUpdate={onUpdate} />}
        <ZuoraMultiEntityItem node={node} onUpdate={onUpdate} />
    </Form>
);

export default ZuoraUsageFileParameters;
