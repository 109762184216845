import React, { ChangeEvent } from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { set } from 'lodash';
import { TextInputItem } from '../../configurator-fields';
import type { Node } from '../../../../../dazzler-types';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
    classes;
};

const EmailV2NewMessage = ({ node, onUpdate, i18nPrefix, classes }: Props) => {
    const intl = useIntl();
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });

    const onChangeMessage = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        set(node.params, 'message', ev.target.value);
        onUpdate(node);
    };

    return (
        <fieldset>
            <legend>{i18n('new_message')}</legend>
            <TextInputItem
                label={i18n('from')}
                path="from"
                required
                node={node}
                onUpdate={onUpdate}
                placeholder={i18n('from_placeholder')}
                className={classes.noMarginBottom}
            />
            <div className={classes.textWithMargin}>{i18n('from_note')}</div>
            <TextInputItem
                label={i18n('subject')}
                path="subject"
                required
                node={node}
                onUpdate={onUpdate}
                placeholder={i18n('subject_placeholder')}
            />
            <Form.Item label={i18n('message')} className={classes.noMarginBottom} required>
                <Input.TextArea
                    className={classes.textAreaFont}
                    placeholder={i18n('message_placeholder')}
                    autoSize={{ minRows: 3, maxRows: 10 }}
                    onChange={onChangeMessage}
                    value={node.params.message}
                />
            </Form.Item>
        </fieldset>
    );
};

export default EmailV2NewMessage;
