const transformArrToObj = (array) =>
    array.reduce((obj, item) => {
        if (item.key) {
            if (obj[item.key]) {
                if (Array.isArray(obj[item.key]) && Array.isArray(item.value)) {
                    item.value.forEach((v) => obj[item.key].push(v));
                } else {
                    obj[item.key].push(item.value);
                }
            } else if (Array.isArray(obj[item.key]) && Array.isArray(item.value)) {
                item.value.forEach((v) => obj[item.key].push(v));
            } else {
                // eslint-disable-next-line
                obj[item.key] = [item.value];
            }
        }
        return obj;
    }, {});

export default transformArrToObj;
