import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { IntlShape } from 'react-intl';

import { Node, FunctionType, FeatureToggleFn } from '../../../../../dazzler-types';
import { isFeatureEnabled } from '../../../../../feature-toggles';
import ZuoraDataQueryItem from '../../common/zuora/ZuoraDataQueryItem';
import { ZuoraUsageParameters } from '../../parameters/general';
import ZuoraQueryOperationalSettings from '../../common/zuora/ZuoraQueryOperationalSettings';
import ZuoraQueryCacheItem from '../../common/zuora/ZuoraQueryCacheItem';

type ConfiguratorProps = {
    node: Node;
    inputData?: Array<any>;
    functionType: FunctionType;
    intl: IntlShape;
    onUpdate: (node: Node) => void;
};

const ZuoraQueryConfigurator = ({ node, inputData, onUpdate, functionType, intl }: ConfiguratorProps) => {
    const isFeatureToggleEnabled: FeatureToggleFn = useSelector(isFeatureEnabled);
    const isVersionControlPostPhase2Enabled: boolean = isFeatureToggleEnabled('versionControlPostPhase2');

    const i18n = (id: string, prefix: string, value?: any) =>
        value ? intl.formatMessage({ id: `${prefix}.${id}` }, value) : intl.formatMessage({ id: `${prefix}.${id}` });

    return (
        <Form layout="vertical">
            {!isVersionControlPostPhase2Enabled && (
                <ZuoraUsageParameters functionType={functionType} node={node} onUpdate={onUpdate} />
            )}
            <ZuoraDataQueryItem node={node} onUpdate={onUpdate} i18n={i18n} inputData={inputData} />

            <ZuoraQueryCacheItem node={node} onUpdate={onUpdate} i18n={i18n} />

            <ZuoraQueryOperationalSettings node={node} onUpdate={onUpdate} i18n={i18n} />
        </Form>
    );
};

export default ZuoraQueryConfigurator;
