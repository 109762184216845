import { FIRST_VALUE, SECOND_VALUE } from '../functions/registry/functions/configurator-fields/RadioPairItem';

export const RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING = 'default';
export const RECORD_EXPIRY_FOREVER = 'forever';
export const RECORD_EXPIRY_CUSTOM_PERIOD = 'custom';

export const PERIOD_1_MONTH = 24 * 30;
export const PERIOD_1_WEEK = 24 * 7;
export const PERIOD_1_DAY = 24;
export const PERIOD_1_HOUR = 1;

export const STORE_RECORD_HISTORY_YES = FIRST_VALUE;
export const STORE_RECORD_HISTORY_NO = SECOND_VALUE;
