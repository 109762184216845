import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { Switch, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from '../../../../dazzler-types';
import { icons } from '../../../../resources';
import { getConfigNode, onNodeUpdate } from '../../../../streams';
import { getFunctionDocUrl } from '../../../../docs';

const styles = (theme) => ({
    rightElement: {
        marginRight: theme.marginXs,
    },
    alertWrapper: {
        marginTop: '10px',
    },
    alert: {
        backgroundColor: theme.brand01,
        borderColor: theme.brand03,
    },
});

const useStyles = createUseStyles(styles);

const PreviewDisableSwitch = () => {
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const node: Node = useSelector(getConfigNode);
    const onUpdate = (updatedNode: Node): void => {
        dispatch(onNodeUpdate(updatedNode));
    };

    const i18n = (id: string, prefix = 'function_config.general.soap') => {
        if (!prefix) {
            return intl.formatMessage({ id });
        }
        return intl.formatMessage({ id: `${prefix}.${id}` });
    };

    const helpUrl = getFunctionDocUrl(node.type);
    const onChangeUseMockData = (checked: boolean) => {
        const n = {
            ...node,
            params: {
                ...node.params,
                useMockData: !checked,
            },
        };
        onUpdate(n);
    };

    return (
        <>
            <div>
                <Switch
                    className={classes.rightElement}
                    onChange={(checked) => onChangeUseMockData(checked)}
                    checked={!node.params.useMockData}
                    data-testid="http-disable-preview-toggle"
                />
                {i18n('enable_preview_text')}
            </div>
            <div className={classes.alertWrapper}>
                <Alert
                    className={classes.alert}
                    message={
                        <>
                            {i18n('preview_description')}
                            <div style={{ paddingTop: '2px' }}>
                                <a target="_blank" href={helpUrl} rel="noopener noreferrer">
                                    <FontAwesomeIcon style={{ marginRight: '8px' }} icon={icons.regular.faBookReader} />
                                    {i18n('function_help_link_label', null)}
                                </a>
                            </div>
                        </>
                    }
                    type="info"
                    showIcon
                />
            </div>
        </>
    );
};

export default PreviewDisableSwitch;
