import { useRef, useEffect } from 'react';

export default <T = unknown>(value: T): T => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};
