import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { FormItem, Input } from '@digitalroute-internal/dazzlerjs-react-ui';
import { get, set } from 'lodash';
import type { ConfiguratorProps } from '../../../../dazzler-types';
import { isPreviewForNodeUserTriggered, StreamsPreviewState } from '../../../../streams';
import { useAppSelector } from '../../../../../app/hooks';
import { getFunctionValidationMessage } from '../../../../dazzler-intl';

const styles = (theme) => ({
    environmentContainer: {
        marginLeft: theme.marginXs,
    },
});
const useStyles = createUseStyles(styles);

type Props = Required<Pick<ConfiguratorProps, 'node' | 'onUpdate'>>;

const TEST_ID = 'general-env-item';

function EnvironmentItem({ node, onUpdate }: Props) {
    const isPreviewClicked = useAppSelector((state: StreamsPreviewState) =>
        isPreviewForNodeUserTriggered(state, node.id),
    );

    const intl = useIntl();
    const [tenant, setTenant] = useState(get(node.params, 'tokenUri'));
    const [region, setRegion] = useState(get(node.params, 'region'));

    const i18n = (id: string) => intl.formatMessage({ id: `function_config.general.environment.${id}` });

    const onChangeTenant = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setTenant(ev.target.value);
    };

    const onChangeRegion = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setRegion(ev.target.value);
    };
    const classes = useStyles();

    useEffect(() => {
        let updated = false;

        if (node.params.tokenUri !== tenant) {
            set(node.params, 'tokenUri', tenant);
            updated = true;
        }

        if (node.params.region !== region) {
            set(node.params, 'region', region);
            updated = true;
        }

        if (updated) {
            onUpdate(node);
        }
    }, [tenant, region]);

    const inputValidation = (inputValue) => {
        if (!inputValue) {
            return getFunctionValidationMessage(intl, 'required');
        }
        return null;
    };

    return (
        <>
            <legend>{i18n('environment_legend')}</legend>
            <div className={classes.environmentContainer}>
                <FormItem name="tenant" label={i18n('tenant_label')} required>
                    <Input
                        defaultValue={tenant}
                        onChange={onChangeTenant}
                        data-testid={`${TEST_ID}__input-tenant`}
                        status={isPreviewClicked && inputValidation(tenant) ? 'error' : undefined}
                        errorText={isPreviewClicked ? inputValidation(tenant) : undefined}
                    />
                </FormItem>
                <FormItem name="region" label={i18n('region_label')} required>
                    <Input
                        defaultValue={region}
                        onChange={onChangeRegion}
                        data-testid={`${TEST_ID}__input-region`}
                        status={isPreviewClicked && inputValidation(region) ? 'error' : undefined}
                        errorText={isPreviewClicked ? inputValidation(region) : undefined}
                    />
                </FormItem>
            </div>
        </>
    );
}

export default EnvironmentItem;
