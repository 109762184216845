import React, { Component } from 'react';
import { Form, Input, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@digitalroute-internal/dazzlerjs-react-ui';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { ConfiguratorProps } from '../../../../../dazzler-types';
import { icons } from '../../../../../resources';
import TextInputItem from '../../configurator-fields/TextInputItem';

const styles = () => ({
    descriptionIcon: {
        float: 'right',
    },
    fileInfoPopover: {
        whiteSpace: 'pre',
    },
});

type Props = ConfiguratorProps & {
    classes: any;
    updateParams: (arg0: string, arg1: string) => void;
    fileSizeLimit: number;
    shouldUseDescription?: boolean;
};

type State = {
    description?: string;
};

class ZuoraFileInformationItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            description: '',
        };
    }

    componentDidMount() {
        const { node } = this.props;
        const { description } = this.state;
        const existingDescription = _.get(node.params, 'description', description);

        this.setState({
            description: existingDescription,
        });
    }

    i18n = (id: string, values?: any, prefix: string = 'function_config.generic.zuora') => {
        const { intl } = this.props;
        return intl.formatMessage({ id: `${prefix}.${id}` }, values);
    };

    updateDescription = (event) => {
        const { updateParams } = this.props;
        const { value: description } = event.target;
        this.setState({ description });

        updateParams('description', description);
    };

    render() {
        const { classes, node, onUpdate, fileSizeLimit, shouldUseDescription = false } = this.props;
        const { description } = this.state;
        return (
            <fieldset>
                <legend>
                    {this.i18n('file_information_legend')}
                    <Popover
                        content={
                            <div className={classes.fileInfoPopover}>
                                {this.i18n('file_information_description', { limit: fileSizeLimit })}
                            </div>
                        }
                        placement="bottomLeft"
                        arrowPointAtCenter
                    >
                        <FontAwesomeIcon icon={icons.solid.faInfoCircle} className={classes.descriptionIcon} />
                    </Popover>
                </legend>
                <TextInputItem
                    label={this.i18n('file_name_label')}
                    path="fileName"
                    node={node}
                    onUpdate={onUpdate}
                    placeholder={this.i18n('file_name_placeholder', {
                        name: '{name}',
                    })}
                />
                {shouldUseDescription && (
                    <Form.Item label={this.i18n('file_description_legend')}>
                        <Input.TextArea
                            placeholder={this.i18n('file_description_placeholder')}
                            autoSize={{ minRows: 3, maxRows: 10 }}
                            onChange={(event) => this.updateDescription(event)}
                            value={description}
                        />
                    </Form.Item>
                )}
            </fieldset>
        );
    }
}

export default withStyles(styles)(injectIntl(ZuoraFileInformationItem));
