import React, { useEffect, useState } from 'react';
import { get, set } from 'lodash';
import {
    Divider,
    Button,
    FormItem,
    icons,
    FieldMapping,
    OptionProp,
    OptionsType,
} from '@digitalroute-internal/dazzlerjs-react-ui';
import { FunctionType, Node } from '../../../../../dazzler-types';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    treeNodeData: any;
    classes: any;
    i18n: any;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => OptionsType[];
};

const ZuoraFileForwarderOptionalFieldsComponent = ({
    node,
    onUpdate,
    functionType,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
}: Props) => {
    const initCustomFieldMapping = get(node, 'params.customFieldMappings', []);
    const [customFieldMapping, setCustomFieldMapping] = useState([]);
    const { useActiveRating } = node.params;
    const requiredType = useActiveRating
        ? 'params.definitions.activeRatingMapping'
        : 'params.definitions.nonActiveRatingMapping';
    const mappingProperties = get(functionType, `${requiredType}.properties`, {});
    const requiredProperties = get(functionType, `${requiredType}.required`, []);
    const [targetFieldDropdownOption, setTargetFieldDropdownOption] = useState([]);

    useEffect(() => {
        const optionalFields = Object.keys(mappingProperties).map((val) =>
            requiredProperties.includes(val)
                ? {}
                : {
                      children: [],
                      key: val,
                      title: val,
                      value: val,
                  },
        );
        setTargetFieldDropdownOption(optionalFields.filter((val) => Object.keys(val).length !== 0));
        setCustomFieldMapping(initCustomFieldMapping);
    }, [functionType, node.params.useActiveRating]);

    useEffect(() => {
        set(
            node.params,
            'customFieldMappings',
            customFieldMapping.filter((arrVal) => arrVal.target || arrVal.source),
        );
        onUpdate(node);
    }, [customFieldMapping]);

    const onClickDeleteCustomFieldMapping = (idx: number) => {
        setCustomFieldMapping((currentCustomFieldMapping) =>
            currentCustomFieldMapping.filter((_curRef, i) => i !== idx),
        );
    };

    const onClickAddCustomFieldMapping = () => {
        setCustomFieldMapping((arr) => [...arr, {}]);
    };

    const onChangeCustomParam = (idx: number, val: string, paramName: string) => {
        customFieldMapping[idx] = set(customFieldMapping[idx], paramName, val);
        setCustomFieldMapping([...customFieldMapping]);
    };

    const checkIfExist = (val: string): boolean =>
        requiredProperties.some((p) => p.toLowerCase() === val.toLowerCase());

    return (
        <div className={classes.formItemContainer}>
            <FormItem>
                <legend>{i18n('optional_reference_fields')}</legend>

                {customFieldMapping.map((arrVal, idx) => {
                    const appendString = {
                        prefix: 'CustomFields.',
                    };
                    const targetValidation =
                        (!!arrVal.target || !!arrVal.source) && !(!!arrVal.target && !!arrVal.source) && !arrVal.target;
                    const sourceValidation =
                        (!!arrVal.target || !!arrVal.source) && !(!!arrVal.target && !!arrVal.source) && !arrVal.source;
                    const options: OptionProp[] = [
                        {
                            name: 'custom_reference_target',
                            label: `* ${i18n('mappings_target_field_placeholder')}`,
                            isSelect: true,
                            hasError: targetValidation,
                            errorMessage: i18n('mappings_field_error_message'),
                            selectOption: {
                                allowClear: true,
                                allowDelete: true,
                                isTree: true,
                                status: targetValidation ? 'error' : undefined,
                                optionData: addAddonDataTreeData(arrVal.target, targetFieldDropdownOption),
                                value: arrVal.target ? arrVal.target : undefined,
                                allowAddon: true,
                                onChange: (val: string) => onChangeCustomParam(idx, val, 'target'),
                                onOptionsChange: (_newOption, newValue) => {
                                    if (newValue) onChangeCustomParam(idx, newValue, 'target');
                                },
                                checkIfExist: (val: string) => checkIfExist(val),
                                inputAddonProps: {
                                    checkDuplicateValue: true,
                                    checkDuplicateBeforeAppend: true,
                                    isAppend: useActiveRating,
                                    appendString: useActiveRating ? appendString : undefined,
                                    inputMessage: useActiveRating
                                        ? i18n('optional_field_inputaddon_message')
                                        : undefined,
                                    inputMessageStatus: 'info',
                                    errorMessage: i18n('optional_field_inputaddon_error_message'),
                                },
                            },
                        },
                        {
                            name: 'custom_reference_source',
                            label: `* ${i18n('mappings_source_field_placeholder')}`,
                            isSelect: true,
                            hasError: sourceValidation,
                            errorMessage: i18n('mappings_field_error_message'),
                            selectOption: {
                                allowClear: true,
                                allowDelete: true,
                                isTree: true,
                                status: sourceValidation ? 'error' : undefined,
                                optionData: addAddonDataTreeData(arrVal.source, treeNodeData),
                                value: arrVal.source ? arrVal.source : undefined,
                                allowAddon: true,
                                inputAddonProps: {
                                    isAppend: false,
                                    checkDuplicateBeforeAppend: false,
                                    checkDuplicateValue: true,
                                    errorMessage: i18n('optional_field_inputaddon_error_message'),
                                },
                                onChange: (val: string) => onChangeCustomParam(idx, val, 'source'),
                                onOptionsChange: (_newOption, newValue) => {
                                    if (newValue) onChangeCustomParam(idx, newValue, 'source');
                                },
                            },
                        },
                    ];

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={classes.spacingTop} key={`optionalFieldIdx_${idx}`}>
                            <FieldMapping options={options} onDelete={() => onClickDeleteCustomFieldMapping(idx)} />
                        </div>
                    );
                })}

                <div className={classes.spacingTop}>
                    <Button
                        type="dashed"
                        text={i18n('add_optional_reference')}
                        icon
                        iconName={icons.regular.faPlus}
                        onClick={() => onClickAddCustomFieldMapping()}
                    />
                </div>
            </FormItem>

            <Divider />
        </div>
    );
};

export default ZuoraFileForwarderOptionalFieldsComponent;
