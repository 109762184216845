import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Form } from 'antd';
import ZuoraRequestItem from '../../common/zuora/ZuoraRequestItem';
import ZuoraEnvironmentItem from '../../common/zuora/ZuoraEnvironmentItem';
import ZuoraMultiEntityItem from '../../common/zuora/ZuoraMultiEntityItem';
import SecretsWalletConfigurator from '../../common/secrets-wallet/SecretsWalletConfigurator';
import { SECRETS_WALLET_TYPE_PATH, writers } from '../../../../../dazzler-constants';
import type { ParametersProps } from '../../../../../dazzler-types';

const I18N_PREFIX = 'function_config.generic.zuora';

const ZuoraUsageParameters = ({ functionType, node, onUpdate }: ParametersProps): JSX.Element => {
    const secretType: string = get(functionType, SECRETS_WALLET_TYPE_PATH);

    const onSecretsWalletToggle = useCallback(
        (secretsWalletToggle: boolean) => {
            const params = { ...node.params };

            if (secretsWalletToggle) {
                delete params.authentication;
                delete params.apiAccessKeyId;
                delete params.apiSecretAccessKey;
                delete params.clientId;
                delete params.clientSecret;
            } else {
                delete params.secretsWalletEntry;
            }

            onUpdate({ ...node, params });
        },
        [node],
    );

    return (
        <Form layout="vertical">
            <ZuoraEnvironmentItem node={node} onUpdate={onUpdate} functionType={functionType} />
            <SecretsWalletConfigurator
                type={secretType}
                legend={<FormattedMessage id={`${I18N_PREFIX}.secrets_wallet.legend`} />}
                i18nPrefix={I18N_PREFIX}
                node={node}
                onUpdate={onUpdate}
                onToggle={onSecretsWalletToggle}
            />
            {functionType.type === writers.zuoraPostUsage && <ZuoraRequestItem node={node} onUpdate={onUpdate} />}
            <ZuoraMultiEntityItem node={node} onUpdate={onUpdate} />
        </Form>
    );
};

export default ZuoraUsageParameters;
