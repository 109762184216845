import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { set } from 'lodash';
import { Form } from 'antd';
import { isFeatureEnabled as isFeatureToggleEnabled } from '../../../../../feature-toggles';
import type { ConfiguratorProps, FeatureToggleFn } from '../../../../../dazzler-types';
import ZuoraFileForwarderFileInformationComponent from '../../common/zuora/ZuoraFileForwarderFileInformationComponent';
import ZuoraFileForwarderRequestComponent from '../../common/zuora/ZuoraFileForwarderRequestComponent';
import ZuoraFileForwarderRequiredFields from '../../common/zuora/ZuoraFileForwarderRequiredFields';
import { getNodeInputFieldsTreeData, StreamsPreviewState } from '../../../../../streams';
import ZuoraFileForwarderOptionalFieldsComponent from '../../common/zuora/ZuoraFileForwarderOptionalFieldsComponent';
import ZuoraUsageFileParameters from '../../parameters/writers/ZuoraUsageFileParameters';
import ForwarderInterconnectSetting from '../../common/ForwarderInterconnectSetting';

const I18N_PREFIX = 'function_config.writer.zuora_usage_file';

const styles = (theme) => ({
    bannerContainer: {
        marginLeft: theme.paddingXs,
        marginBottom: theme.paddingMd,

        '& .ant-alert-content p': {
            marginBottom: 0,
        },

        '& a': {
            textDecoration: 'underline',
        },
    },
    fileDescriptionContainer: {
        marginLeft: theme.paddingXs,
        color: theme.neutral04,
        '& a': {
            textDecoration: 'underline',
        },
    },
    fieldMappings: {
        borderBottom: '0px !important',

        '& .ant-form-item .ant-form-item-label label': {
            fontSize: '14px !important',
        },
    },
    formMargin: {
        marginLeft: theme.paddingXs,
    },
    spacingTop: {
        marginTop: theme.paddingSm,
    },
    formItemContainer: {
        margin: theme.paddingSm,

        '& legend': {
            marginLeft: '0',
        },

        '& .ant-form-item .ant-form-item-label label': {
            fontSize: '14px !important',
        },
    },
    collapsibleContainer: {
        marginTop: theme.paddingSm,

        '& .ant-collapse > .ant-collapse-item > .ant-collapse-header': {
            alignItems: 'center',
        },
    },
    buttonParentDiv: {
        width: '40px',
        height: '40px',
        alignItem: 'center',
        justifyContent: 'center',
        background: theme.white,
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn-text': {
            fontSize: theme.fontSizeLg,
        },
        border: `1px solid ${theme.neutral03}`,
        borderRadius: theme.borderRadiusSm,
    },
    errorText: {
        color: theme.red05,
        marginBottom: 0,
    },
    descriptionIcon: {
        float: 'right',
    },
    fileInfoPopover: {
        whiteSpace: 'pre',
    },
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
    rightElement: {
        marginRight: theme.marginXs,
    },
    wrapper: {
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        color: theme.neutral04,
        width: '90%',
        margin: '0 auto',
    },
    icon: {
        fontSize: '40px',
        marginBottom: theme.paddingMd,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
});

const useStyles = createUseStyles(styles);

const ZuoraUsageFileConfigurator = ({ functionType, intl, node, stream, onUpdate }: ConfiguratorProps): JSX.Element => {
    const { useActiveRating } = node.params;
    const i18n = (id: string, values?: any) => intl.formatMessage({ id: `${I18N_PREFIX}.${id}` }, values);
    const classes = useStyles();
    const sourceFields = useSelector((state: StreamsPreviewState) => getNodeInputFieldsTreeData(state, node, stream));
    const isFeatureEnabled: FeatureToggleFn = useSelector(isFeatureToggleEnabled);
    const isVersionControlPostPhase2Enabled: boolean = isFeatureEnabled('versionControlPostPhase2');

    const updateParams = useCallback(
        (field: string, value: unknown) => {
            set(node.params, [field], value);
            onUpdate(node);
        },
        [node],
    );

    const findValueInTreeData = (valueToFind: string, treeData): boolean => {
        const result = treeData.find((el) => {
            let foundInTreeData = el.value === valueToFind;
            if (!foundInTreeData && el.children.length >= 1) {
                foundInTreeData = findValueInTreeData(valueToFind, el.children);
            }
            return foundInTreeData;
        });
        return result !== undefined && Object.keys(result).length >= 1;
    };

    const addAddonDataTreeData = (valueToFind: string, treeData) => {
        const foundInTreeData = valueToFind === undefined ? true : findValueInTreeData(valueToFind, treeData);
        return foundInTreeData
            ? treeData
            : [
                  ...treeData,
                  {
                      children: [],
                      key: valueToFind,
                      title: valueToFind,
                      value: valueToFind,
                      isDeletable: true,
                  },
              ];
    };

    return (
        <>
            {!isVersionControlPostPhase2Enabled && (
                <ZuoraUsageFileParameters node={node} onUpdate={onUpdate} functionType={functionType} />
            )}

            <Form layout="vertical">
                <ZuoraFileForwarderRequestComponent classes={classes} node={node} i18n={i18n} onUpdate={onUpdate} />
                <ZuoraFileForwarderFileInformationComponent
                    shouldUseDescription={useActiveRating}
                    fileSizeLimit={18}
                    node={node}
                    onUpdate={onUpdate}
                    updateParams={updateParams}
                    i18n={i18n}
                    classes={classes}
                />
                <ZuoraFileForwarderRequiredFields
                    functionType={functionType}
                    node={node}
                    onUpdate={onUpdate}
                    treeNodeData={sourceFields}
                    classes={classes}
                    i18n={i18n}
                    addAddonDataTreeData={addAddonDataTreeData}
                />
                <ZuoraFileForwarderOptionalFieldsComponent
                    functionType={functionType}
                    node={node}
                    onUpdate={onUpdate}
                    treeNodeData={sourceFields}
                    classes={classes}
                    i18n={i18n}
                    addAddonDataTreeData={addAddonDataTreeData}
                />
                <div className={classes.formMargin}>
                    <ForwarderInterconnectSetting
                        node={node}
                        onUpdate={onUpdate}
                        stream={stream}
                        defaultOn={false}
                        dataTestIdPrefix="ZuoraUsageFileInterconnectSetting"
                    />
                </div>
            </Form>
        </>
    );
};

export default ZuoraUsageFileConfigurator;
