import React, { ChangeEventHandler, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { Form } from 'antd';
import { Node } from '../../../../../dazzler-types';
import RadioPairItem, { FIRST_VALUE, SECOND_VALUE } from '../../configurator-fields/RadioPairItem';
import { GoTransverseEnum } from './GoTransverseEnum';

type Props = {
    node: Node;
    onUpdate: (Node) => void;
    i18nPrefix: string;
};

function GoTransverseModeItem(props: Props) {
    const { node, onUpdate } = props;
    const intl = useIntl();
    const mode = get(node.params, 'mode', FIRST_VALUE);

    const i18n = (values: string) => intl.formatMessage({ id: `${props.i18nPrefix}.${values}` });

    const setModeValue = (value) => {
        const returnVal =
            value === FIRST_VALUE ? GoTransverseEnum.OVERWRITE_ON_EXISTING : GoTransverseEnum.FAIL_ON_EXISTING;
        const n = {
            ...node,
            params: {
                ...node.params,
                mode: returnVal,
            },
        };
        onUpdate(n);
    };

    useEffect(() => {
        if (!node.params.mode) {
            const n = {
                ...node,
                params: {
                    ...node.params,
                    mode: GoTransverseEnum.OVERWRITE_ON_EXISTING,
                },
            };
            onUpdate(n);
        }
    }, []);

    return (
        <fieldset>
            <legend>{i18n('mode_legend')}</legend>
            <div>
                <Form.Item>
                    <RadioPairItem
                        label={i18n('auth_radio_label')}
                        firstOption={i18n('mode_radio_first')}
                        secondOption={i18n('mode_radio_second')}
                        value={mode === GoTransverseEnum.FAIL_ON_EXISTING ? SECOND_VALUE : FIRST_VALUE}
                        onChange={(value: ChangeEventHandler) => setModeValue(value)}
                    />
                </Form.Item>
            </div>
        </fieldset>
    );
}

export default GoTransverseModeItem;
