import React from 'react';
import { useValidatorState } from '../context';
import { Property } from '../types';
import PropertiesCollapsable from './PropertiesCollapsable';

type Props = {
    disabled?: boolean;
};

const PropertiesView = ({ disabled = false }: Props) => {
    const { properties, dispatch, form } = useValidatorState();

    const addProperty = () => {
        form.validateFields();
        dispatch({ type: 'ADD_PROPERTY' });
    };

    const deleteProperty = (key: Property['key']) => {
        dispatch({
            type: 'DELETE_PROPERTY',
            payload: key,
        });
    };

    const onCollapseChange = (keys: Property['key'][]) => {
        dispatch({
            type: 'EXPAND_PROPERTY',
            payload: keys,
        });
    };

    return (
        <PropertiesCollapsable
            properties={properties}
            onAdd={addProperty}
            onDelete={deleteProperty}
            onCollapse={onCollapseChange}
            disabled={disabled}
        />
    );
};

export default PropertiesView;
