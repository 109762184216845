import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Form, FormItem, Divider } from '@digitalroute-internal/dazzlerjs-react-ui';
import type { ParametersProps } from '../../../../../dazzler-types';
import EnvironmentItem from '../../common/EnvironmentItem';
import SecretsWalletSelector from '../../common/secrets-wallet/SecretsWalletSelector';
import { DEFAULT_REGION } from '../../../../../dazzler-constants';

const I18N_PREFIX = 'function_config.writer.sap_sb_query';
const styles = (theme) => ({
    marginLeft: {
        marginLeft: theme.paddingSm,
    },
});
const useStyles = createUseStyles(styles);

const SapSubscriptionParameters = ({ node, onUpdate }: ParametersProps) => {
    const { region } = node.params;
    const intl = useIntl();
    const i18n = (id: string) => intl.formatMessage({ id: `${I18N_PREFIX}.${id}` });
    const classes = useStyles();

    useEffect(() => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                region: region ?? DEFAULT_REGION,
            },
        });
    }, []);

    return (
        <Form layout="vertical">
            <EnvironmentItem node={node} onUpdate={onUpdate} />
            <Divider overrideClass={classes.marginLeft} />
            <FormItem>
                <legend>{i18n('authentication_legend')}</legend>
            </FormItem>
            <SecretsWalletSelector node={node} onUpdate={onUpdate} />
        </Form>
    );
};

export default SapSubscriptionParameters;
