import { handleActions } from 'redux-actions';
import { setSearch, resetSearch } from '../actions/search';

export type State = string;

export const initialState: State = '';

export const reducer = handleActions<State, string>(
    {
        [setSearch.toString()]: (_state, { payload }): State => payload,
        [resetSearch.toString()]: () => initialState,
    },
    initialState,
);
