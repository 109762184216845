import React from 'react';
import { useValidatorState } from '../context';
import { ValidationRule } from '../types';
import ValidationSelect from './ValidationSelect';

type Props = {
    rule: ValidationRule;
    disabled?: boolean;
};

const ValidationSelectObject = ({ rule, disabled = false }: Props) => {
    const { objects, dispatch } = useValidatorState();
    const objectProperties = objects.find((o) => o.properties.some((p) => p.key === rule.propertyKey))?.properties;

    const onChange = (value: string) => {
        dispatch({
            type: 'UPDATE_OBJECT_VALIDATION',
            payload: {
                rule,
                value,
                field: 'validationRuleKey',
            },
        });
    };

    if (objectProperties) {
        return <ValidationSelect rule={rule} properties={objectProperties} onChange={onChange} disabled={disabled} />;
    }

    return null;
};

export default ValidationSelectObject;
