import React, { Component } from 'react';
import { Form, Select } from 'antd';
import { injectIntl } from 'react-intl';
import _, { set, unset } from 'lodash';
import type { ConfiguratorProps } from '../../../../../dazzler-types';
import TextInputItem from '../../configurator-fields/TextInputItem';

const TEST_ID = 'zuora_env_item';

type Tenant = {
    name: string;
    url: string;
};

type Props = ConfiguratorProps;

type State = {
    selectedTenant: Tenant;
    extendedTenants: Array<Tenant>;
};

class ZuoraEnvironmentItem extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedTenant: { name: '', url: '' },
            extendedTenants: [],
        };
    }

    componentDidMount() {
        const { node, functionType } = this.props;
        const { selectedTenant } = this.state;

        const selectedSchemaTenant = _.get(node.params, 'tenant', selectedTenant.url);
        const availableTenants = _.get(functionType, 'params.definitions.availableTenants.enum', []);
        const extendedTenants = availableTenants.concat({ name: 'Custom', url: '' });

        // NB: If the selectedSchemaTenant URL doesn't exist in extendedTenants
        // the user has already specified a custom tenant URL
        let tenant = extendedTenants.filter((t) => t.url === selectedSchemaTenant)[0] || {
            name: 'Custom',
            url: selectedSchemaTenant.url,
        };
        // NB: If user has entered an empty URL, we disregard it and use the empty state
        if (!selectedSchemaTenant) {
            tenant = { name: '', url: '' };
        }

        this.setState({
            selectedTenant: tenant,
            extendedTenants,
        });
    }

    i18n = (id: string, values?: any, prefix: string = 'function_config.generic.zuora') => {
        const { intl } = this.props;
        return intl.formatMessage({ id: `${prefix}.${id}` }, values);
    };

    updateSelectedTenant = (endpoint: string) => {
        const { node, onUpdate } = this.props;
        const { extendedTenants } = this.state;
        const selectedTenant = extendedTenants.filter((ep) => ep.name === endpoint)[0] || { name: '', url: '' };

        this.setState({ selectedTenant });
        if (selectedTenant.url) {
            set(node.params, 'tenant', selectedTenant.url);
        } else {
            unset(node.params, 'tenant');
        }
        onUpdate(node);
    };

    render() {
        const { node, onUpdate } = this.props;
        const { selectedTenant, extendedTenants } = this.state;
        return (
            <fieldset data-testid={`${TEST_ID}_main_container`}>
                <legend>{this.i18n('tenant_legend')}</legend>
                <>
                    <Form.Item label={this.i18n('tenant_label')} required>
                        <Select
                            size="large"
                            showSearch
                            placeholder=""
                            getPopupContainer={({ parentNode }) => parentNode}
                            onChange={(endpoint) => this.updateSelectedTenant(endpoint)}
                            value={selectedTenant.name || undefined}
                            data-testid={`${TEST_ID}_tenant_select`}
                        >
                            {extendedTenants.map((tenant) => (
                                <Select.Option value={tenant.name} key={`${tenant.name}`}>
                                    {tenant.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {selectedTenant.name && (
                        <TextInputItem
                            label={this.i18n('tenant_endpoint')}
                            path="tenant"
                            required
                            node={node}
                            onUpdate={onUpdate}
                            placeholder=""
                            disabled={selectedTenant.name !== 'Custom'}
                            data-testid={`${TEST_ID}_tenant_endpoint`}
                        />
                    )}
                </>
            </fieldset>
        );
    }
}

export default injectIntl(ZuoraEnvironmentItem);
