import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { get, isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import type { FunctionType, Node } from '../../../../../dazzler-types';
import TextInputItem from '../../configurator-fields/TextInputItem';

const TEST_ID = 'gotransverse_env_item';

type Tenant = {
    name: string;
    url: string;
};

export type Props = {
    node: Node;
    functionType: FunctionType;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
};

const GoTransverseEnvironmentItem = ({ node, onUpdate, functionType, i18nPrefix }: Props) => {
    const [selectedTenant, setSelectedTenant] = useState<Tenant>({
        name: '',
        url: '',
    });
    const [curSelectedTenant, setCurSelectedTenant] = useState(node.params.selectedTenant);
    const intl = useIntl();
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });
    const [extendedTenants, setExtendedTenants] = useState<Array<Tenant>>([]);

    const updateSelectedTenant = (value: string) => {
        const updateTenant = extendedTenants.find((ep, idx) => idx.toString() === value) || { name: '', url: '' };
        setCurSelectedTenant(value);
        setSelectedTenant(updateTenant);
        const n = {
            ...node,
            params: {
                ...node.params,
                tenant: updateTenant.url,
                selectedTenant: value,
            },
        };

        onUpdate(n);
    };

    useEffect(() => {
        const selectedSchemaTenant = get(node.params, 'tenant', selectedTenant);
        const availableTenants = get(functionType, 'params.definitions.availableTenants.enum', []);
        const getExtendedTenants = availableTenants.concat({ name: 'Custom', url: '' });
        let tenant = getExtendedTenants.find((t) => isEqual(t.url, selectedSchemaTenant)) || {
            name: 'Custom',
            url: selectedSchemaTenant.url,
        };
        if (!selectedSchemaTenant) {
            tenant = { name: '', url: '' };
        }
        setSelectedTenant(tenant);
        setExtendedTenants(getExtendedTenants);
    }, []);

    return (
        <fieldset>
            <legend>{i18n('tenant_legend')}</legend>
            <>
                <Form.Item label={i18n('tenant_label')} required>
                    {extendedTenants && (
                        <Select
                            size="large"
                            showSearch
                            placeholder={i18n('tenant_placeholder')}
                            getPopupContainer={({ parentNode }) => parentNode}
                            onChange={(value) => updateSelectedTenant(value)}
                            value={curSelectedTenant}
                            data-testid={`${TEST_ID}_tenant_select`}
                        >
                            {extendedTenants.map((tenant, index) => (
                                <Select.Option value={index.toString()} key={tenant.name}>
                                    {tenant.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <TextInputItem
                    label={i18n('tenant_endpoint')}
                    path="tenant"
                    required
                    node={node}
                    onUpdate={onUpdate}
                    placeholder={i18n('tenant_endpoint_placeholder')}
                    disabled={curSelectedTenant !== '2'}
                    defaultValue={selectedTenant.url}
                    data-testid={`${TEST_ID}_tenant_endpoint`}
                />
            </>
        </fieldset>
    );
};

export default GoTransverseEnvironmentItem;
