import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createIntl, createIntlCache, IntlConfig, IntlShape } from 'react-intl';
import { FeedbackPopup as notification } from '../packages/components';
import { API_URL, ErrorResponse, SUPPORTED_ERROR_CODES } from '../packages/helpers';
import type { RootState } from './store';
import { sec } from './security';

export function showErrorNotification(data: ErrorResponse, status: string | number, intl: IntlShape) {
    const { message, errorCode } = data;
    let errorMessage = message || intl.formatMessage({ id: `general.error.message.${status}` });
    if (errorCode) {
        const translationExists = !!intl.messages[`api.error.${errorCode}`];
        if (translationExists) {
            errorMessage = intl.formatMessage({ id: `api.error.${errorCode}` });
        } else {
            errorMessage = intl.formatMessage({ id: `general.error.message.${status}` });
        }
    }
    notification('error', errorMessage);
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${API_URL}/`,
    prepareHeaders: async (headers) => {
        const accessToken = await sec.getAccessTokenSilently();
        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        }
        headers.set('Content-Type', 'application/json');
        return headers;
    },
});

const extendedBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        const { router } = api.getState() as RootState;
        const { location } = router;
        const regex = /\?next/;

        if (!regex.test(location.search)) {
            sec.logout();
        }
    }

    if (result.error?.status === 404) {
        window.location.replace('/404');
    }

    if (SUPPORTED_ERROR_CODES.includes(result.error?.status as number)) {
        const state = api.getState() as RootState;
        const { locale, messages } = state.intl as Pick<IntlConfig, 'locale' | 'messages'>;
        const intl = createIntl({ locale, messages }, createIntlCache());
        const { status, data } = result.error;
        showErrorNotification(data as ErrorResponse, status, intl);
    }

    return result;
};

export const api = createApi({
    baseQuery: extendedBaseQuery,
    reducerPath: 'api',
    tagTypes: [
        'Solution',
        'Stream',
        'SharedStore',
        'SecretWallet',
        'NotificationConfig',
        'NotificationGroup',
        'User',
        'UsageMetering',
        'Application',
        'Credential',
        'ApplicationExport',
    ],
    endpoints: () => ({}),
});
