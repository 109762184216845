import React, { MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { Collapse, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import QueueAnim from 'rc-queue-anim';
import { icons } from '../../../../../../../resources';
import { DataObject, Property } from '../types';
import PropertyDetails from './PropertyDetails';
import { useValidatorState } from '../context';

const styles = (theme) => ({
    container: {
        marginBottom: theme.marginXs,
        '&:last-child': {
            marginBottom: theme.marginLg,
        },
    },
    addPropIcon: {
        marginRight: theme.marginXs,
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'start',
        marginBottom: theme.marginXs,
        '& > button': {
            position: 'static',
        },
    },
    closeBtn: {
        cursor: 'pointer',
        position: 'relative',
        float: 'right',
    },
    roundBorder: {
        backgroundColor: theme.white,
        height: 16,
        margin: 0,
        padding: '6px 7px',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: theme.brand02,
        color: theme.neutral04,
        borderRadius: 50,
        alignItems: 'center',
        '&button': {
            width: 32,
            height: 32,
            boxShadow: 'unset',
        },
        '&:disabled, &:disabled:hover': {
            cursor: 'not-allowed',
            borderColor: 'transparent',
        },
        '&:disabled *, &:disabled:hover *': {
            color: theme.drFontColorA04,
        },
        '&:hover *': {
            color: theme.brand05,
            borderColor: theme.brand05,
        },
    },
});

const useStyles = createUseStyles(styles);

const i18n = (id: string) => useIntl().formatMessage({ id: `function_config.transforms.validator.${id}` });

type ObjectProps = { isObject: true; objectKey: DataObject['key'] } | { isObject?: false; objectKey?: never };

type Props = {
    properties: Property[];
    onAdd: () => void;
    onDelete: (propertyKey: Property['key']) => void;
    onCollapse: (keys: Property['key'][]) => void;
    disabled?: boolean;
} & ObjectProps;

const PropertiesCollapsable = ({
    properties,
    onAdd,
    onDelete,
    onCollapse,
    objectKey,
    isObject = false,
    disabled = false,
}: Props) => {
    const classes = useStyles();
    const { form } = useValidatorState();
    const panelsToExpand = properties.filter((p) => p.expand).map((p) => p.key);
    const deleteProperty = (key: Property['key']) => (e: MouseEvent<SVGElement>) => {
        e.stopPropagation();
        form.validateFields();

        onDelete(key);
    };

    const renderDetails = properties.map((property) => (
        <div key={property.key} className={classes.container}>
            <Collapse activeKey={panelsToExpand} onChange={onCollapse}>
                <Collapse.Panel
                    key={property.key}
                    header={property.propertyValue}
                    extra={
                        !disabled && (
                            <FontAwesomeIcon
                                icon={icons.regular.faTrash}
                                onClick={deleteProperty(property.key)}
                                className={`${classes.closeBtn} ${classes.roundBorder}`}
                            />
                        )
                    }
                >
                    <PropertyDetails
                        key={property.key}
                        property={property}
                        isObject={isObject}
                        objectKey={objectKey}
                        disabled={disabled}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    ));

    return (
        <fieldset>
            <legend>{i18n('properties')}</legend>
            {properties.length > 0 && (
                <QueueAnim type={['right', 'left']} leaveReverse>
                    {renderDetails}
                </QueueAnim>
            )}
            <div className={classes.buttonDiv}>
                <Button type="dashed" onClick={onAdd} disabled={disabled}>
                    <FontAwesomeIcon className={classes.addPropIcon} icon={icons.regular.faPlus} />
                    {i18n('add_property')}
                </Button>
            </div>
        </fieldset>
    );
};

export default PropertiesCollapsable;
