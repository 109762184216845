import React from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { get, unset } from 'lodash';
import SecretsWalletConfigurator from '../../common/secrets-wallet/SecretsWalletConfigurator';
import { SECRETS_WALLET_TYPE_PATH } from '../../../../../dazzler-constants';
import { ParametersProps } from '../../../../../dazzler-types';
import GoTransverseEnviromentItem from '../../common/goTransverse/GoTransverseEnviromentItem';

const I18N_PREFIX = 'function_config.transform.goTransverse';

const GoTransverseQueryParameters = ({ node, onUpdate, functionType }: ParametersProps) => {
    const intl = useIntl();
    const i18n = (id: string, values?: any, prefix: string = I18N_PREFIX) =>
        intl.formatMessage({ id: `${prefix}.${id}` }, values);
    const secretType = get(functionType, SECRETS_WALLET_TYPE_PATH, null);

    const onSecretsWalletToggle = (secretsWalletToggle: boolean) => {
        if (secretsWalletToggle) {
            unset(node.params, 'apiSecretAccessKey');
        } else {
            unset(node.params, 'secretsWalletEntry');
        }
        onUpdate(node);
    };

    return (
        <Form layout="vertical">
            <GoTransverseEnviromentItem
                node={node}
                functionType={functionType}
                onUpdate={onUpdate}
                i18nPrefix={I18N_PREFIX}
            />
            <SecretsWalletConfigurator
                legend={i18n('secrets_wallet.legend')}
                node={node}
                onUpdate={onUpdate}
                type={secretType}
                i18nPrefix={I18N_PREFIX}
                onToggle={onSecretsWalletToggle}
            />
        </Form>
    );
};

export default GoTransverseQueryParameters;
