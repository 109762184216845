import _ from 'lodash';
import { Node } from '../../dazzler-types';
import readerConfigs from './readers';
import transformConfigs from './transforms';
import writerConfigs from './writers';

export const defaultConfigs = {
    ...readerConfigs,
    ...transformConfigs,
    ...writerConfigs,
};

export function applyDefaultConfig(node: Node): Node {
    const { params } = node;

    if (
        _.isEmpty(params) ||
        (typeof params === 'object' && Object.keys(params).length < 1) ||
        defaultConfigs[node.type]
    ) {
        const defaultParams = { ...params, ...defaultConfigs[node.type] };

        if (defaultParams) {
            // Allow default params to be a function (for generating uuid etc.):
            if (typeof defaultParams === 'function') return { ...node, params: defaultParams() };

            return { ...node, params: defaultParams };
        }
    }

    return { ...node };
}
