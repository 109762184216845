import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Radio, RadioChangeEvent, Select } from 'antd';
import { SecretInput, TextInputItem, RadioPairItem } from '../../configurator-fields';
import {
    PERIOD_1_DAY,
    PERIOD_1_HOUR,
    PERIOD_1_MONTH,
    PERIOD_1_WEEK,
    RECORD_EXPIRY_CUSTOM_PERIOD,
    RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING,
    RECORD_EXPIRY_FOREVER,
    STORE_RECORD_HISTORY_NO,
    STORE_RECORD_HISTORY_YES,
} from '../../../../../dazzler-constants';
import type { ParametersProps } from '../../../../../dazzler-types';

const { Option } = Select;

const styles = (theme: Record<string, any>) => ({
    verticalRadio: {
        display: 'block',
        paddingBottom: theme.paddingXs,
    },
});

const useStyles = createUseStyles(styles);

type RecordExpiration = 'default' | 'forever' | 'custom';

const PubNubParameters = ({ node, onUpdate }: ParametersProps): JSX.Element => {
    const { storeInHistory, ttl } = node.params;
    const intl = useIntl();
    const classes = useStyles();
    const [showCustomPeriod, setShowCustomPeriod] = useState<boolean>(storeInHistory && ttl > 0);
    const [showRecordExpiryOptions, setShowRecordExpiryOptions] = useState<boolean>(storeInHistory ?? false);
    const [recordExpiration, setRecordExpiration] = useState<RecordExpiration>(() => {
        if (storeInHistory) {
            return ttl === 0 ? RECORD_EXPIRY_FOREVER : RECORD_EXPIRY_CUSTOM_PERIOD;
        }

        return RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING;
    });

    const i18n = (id: string) => intl.formatMessage({ id: `function_config.writer.pubnub.${id}` });

    const toggleRecordHistoryPersistence = (
        value: typeof STORE_RECORD_HISTORY_YES | typeof STORE_RECORD_HISTORY_NO,
    ) => {
        setShowRecordExpiryOptions(value === STORE_RECORD_HISTORY_YES);
        setRecordExpiration(STORE_RECORD_HISTORY_YES ? RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING : null);

        const params = { ...node.params };
        if (value === STORE_RECORD_HISTORY_NO) {
            delete params.ttl;
        }

        onUpdate({
            ...node,
            params: {
                ...params,
                storeInHistory: value === STORE_RECORD_HISTORY_YES,
            },
        });
    };

    const changeRecordExpiryOption = ({ target: { value } }: RadioChangeEvent) => {
        setShowCustomPeriod(value === RECORD_EXPIRY_CUSTOM_PERIOD);
        setRecordExpiration(value);

        const params = { ...node.params };
        if (value === RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING) {
            delete params.ttl;
        }

        const newTtl = value === RECORD_EXPIRY_CUSTOM_PERIOD ? PERIOD_1_DAY : 0;

        onUpdate({
            ...node,
            params: {
                ...params,
                ...(value !== RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING && { ttl: newTtl }),
            },
        });
    };

    const setPeriod = (newTtl: number) => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                ttl: newTtl,
            },
        });
    };

    return (
        <Form layout="vertical">
            <fieldset>
                <legend>
                    <FormattedMessage id="function_config.writer.pubnub.subscribe_settings" />
                </legend>
                <Form.Item label={<FormattedMessage id="function_config.writer.pubnub.subscribe_key" />} required>
                    <SecretInput
                        node={node}
                        path="subKey"
                        onUpdate={onUpdate}
                        placeholder={i18n('subscribe_key_placeholder')}
                    />
                </Form.Item>
                <TextInputItem
                    required
                    label={<FormattedMessage id="function_config.writer.pubnub.channel" />}
                    path="channel"
                    node={node}
                    onUpdate={onUpdate}
                    placeholder={i18n('channel_placeholder')}
                />
            </fieldset>
            <fieldset>
                <legend>
                    <FormattedMessage id="function_config.writer.pubnub.publish_settings" />
                </legend>
                <Form.Item label={<FormattedMessage id="function_config.writer.pubnub.publish_key" />} required>
                    <SecretInput
                        node={node}
                        path="pubKey"
                        onUpdate={onUpdate}
                        placeholder={i18n('publish_key_placeholder')}
                    />
                </Form.Item>
                <TextInputItem
                    label={<FormattedMessage id="function_config.writer.pubnub.uuid" />}
                    path="uuid"
                    node={node}
                    onUpdate={onUpdate}
                    placeholder={i18n('uuid_placeholder')}
                />
                <RadioPairItem
                    label={<FormattedMessage id="function_config.writer.pubnub.store_record_history" />}
                    firstOption={<FormattedMessage id="function_config.writer.pubnub.store_record_history_yes" />}
                    secondOption={<FormattedMessage id="function_config.writer.pubnub.store_record_history_no" />}
                    onChange={toggleRecordHistoryPersistence}
                    value={showRecordExpiryOptions ? STORE_RECORD_HISTORY_YES : STORE_RECORD_HISTORY_NO}
                />
                {showRecordExpiryOptions && (
                    <Form.Item label={<FormattedMessage id="function_config.writer.pubnub.record_expiry_options" />}>
                        <Radio.Group onChange={changeRecordExpiryOption} value={recordExpiration}>
                            <Radio value={RECORD_EXPIRY_DEFAULT_CHANNEL_SETTING} className={classes.verticalRadio}>
                                <FormattedMessage id="function_config.writer.pubnub.record_expiry_default_channel_setting" />
                            </Radio>
                            <Radio value={RECORD_EXPIRY_FOREVER} className={classes.verticalRadio}>
                                <FormattedMessage id="function_config.writer.pubnub.record_expiry_forever" />
                            </Radio>
                            <Radio value={RECORD_EXPIRY_CUSTOM_PERIOD} className={classes.verticalRadio}>
                                <FormattedMessage id="function_config.writer.pubnub.record_expiry_custom_period" />
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                )}
                {showRecordExpiryOptions && showCustomPeriod && (
                    <Select
                        getPopupContainer={({ parentNode }) => parentNode}
                        defaultValue={ttl ?? PERIOD_1_DAY}
                        style={{ width: '50%' }}
                        size="large"
                        onChange={setPeriod}
                    >
                        <Option value={PERIOD_1_MONTH}>
                            <FormattedMessage id="function_config.writer.pubnub.record_history_one_month" />
                        </Option>
                        <Option value={PERIOD_1_WEEK}>
                            <FormattedMessage id="function_config.writer.pubnub.record_history_one_week" />
                        </Option>
                        <Option value={PERIOD_1_DAY}>
                            <FormattedMessage id="function_config.writer.pubnub.record_history_one_day" />
                        </Option>
                        <Option value={PERIOD_1_HOUR}>
                            <FormattedMessage id="function_config.writer.pubnub.record_history_one_hour" />
                        </Option>
                    </Select>
                )}
            </fieldset>
        </Form>
    );
};

export default PubNubParameters;
