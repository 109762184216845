import * as React from 'react';
import { Row, Col, Form, Radio } from 'antd';
import { createUseStyles } from 'react-jss';

/*
    This abstracts a recurring pattern of having three radio buttons rendered vertically.
    Note that this does not take a `path` argument. Instead you need to manually wire
    it to your configuration.
*/

const styles = {
    radioPair: {
        '& .ant-radio-group': {
            display: 'block !important',
        },
        '& .ant-row': {
            width: '50%',
            display: 'grid',
            lineHeight: '37px',
        },
        '& .ant-radio-wrapper': {
            width: '300px',
        },
    },
};

const useStyles = createUseStyles(styles);

export const FIRST_VALUE = 'first';
export const SECOND_VALUE = 'second';
export const THIRD_VALUE = 'third';
export const FOURTH_VALUE = 'fourth';

type Value = typeof FIRST_VALUE | typeof SECOND_VALUE | typeof THIRD_VALUE;

type Props = {
    label?: string;
    onChange: (value: Value) => void;
    firstOption: string;
    secondOption: string;
    thirdOption: string;
    fourthOption?: string;
    value: string;
    spanSize?: number;
};

const RadioTrioItem = ({
    label,
    firstOption,
    secondOption,
    thirdOption,
    fourthOption,
    value,
    onChange,
    spanSize = 1,
}: Props) => {
    const classes = useStyles();

    return (
        <Form.Item label={label} className={classes.radioPair}>
            <Radio.Group onChange={(ev) => onChange(ev.target.value)} value={value}>
                <Row>
                    <Col span={spanSize}>
                        <Radio value={FIRST_VALUE}>{firstOption}</Radio>
                    </Col>
                    <Col span={spanSize}>
                        <Radio value={SECOND_VALUE}>{secondOption}</Radio>
                    </Col>
                    <Col span={spanSize}>
                        <Radio value={THIRD_VALUE}>{thirdOption}</Radio>
                    </Col>
                    {fourthOption && (
                        <Col span={spanSize}>
                            <Radio value={FOURTH_VALUE}>{fourthOption}</Radio>
                        </Col>
                    )}
                </Row>
            </Radio.Group>
        </Form.Item>
    );
};

export default RadioTrioItem;
