import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Form, FormItem, Button, Divider, useFormHooks, CheckboxItem } from '@digitalroute-internal/dazzlerjs-react-ui';
import { createUseStyles } from 'react-jss';
import { isEqual } from 'lodash';
import { icons } from '../../resources';
import { getDisplayOptions, setDisplayOptions, defaultSummary } from '../localStore';

const styles = (theme) => ({
    sectionHeader: {
        height: theme.drawerHeaderCloseSize,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: theme.marginLg,
        fontSize: theme.fontSizeXl,
    },
    marginZero: {
        margin: 0,
    },
    sectionTitle: {
        align: 1,
    },
    grid: {
        padding: `${theme.paddingMd} 0px ${theme.paddingXs} ${theme.paddingLg}`,
        fontSize: theme.fontSizeLg,
        fontWeight: 'bold',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
    switchText: {
        fontWeight: 'normal',
        marginLeft: theme.marginXs,
    },
    filterForm: {
        '& .ant-form': {
            margin: `0px ${theme.marginLg}`,
        },
    },
    noMargin: {
        '& .ant-row': {
            margin: 0,
        },
    },
    dateMarginFrom: {
        '& .ant-row': {
            marginLeft: theme.marginLg,
            marginRight: theme.marginXs,
        },
    },
    dateMarginTo: {
        '& .ant-row': {
            marginLeft: theme.marginXs,
            marginRight: theme.marginLg,
        },
    },
    sider: {
        boxShadow: theme.shadow1Left,
        background: theme.white,
        height: 'auto',
        '& .ant-layout-sider-zero-width': {
            display: 'none',
        },
        '& .ant-layout-sider-children': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    siderFooter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flex: 1,
        width: '360px',
        bottom: '0',
        position: 'fixed',
        background: theme.white,
        [`@media (max-width: ${theme.screenMd})`]: {
            width: '100%',
        },
    },
    filterFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .ant-btn': {
            margin: `${theme.marginXs} ${theme.marginMd}`,
        },
    },
    dividerMargin: {
        marginBottom: `${theme.marginXs}`,
    },
    dividerSecond: {
        margin: `${theme.marginMd} 0 ${theme.marginSm} 0`,
    },
    showAll: {
        margin: '10px 0 5px 0',
    },
    labelText: {
        marginTop: theme.marginLg,
    },
});
const useStyles = createUseStyles(styles);

const LogDisplayOption = ({
    isDisplayOptionVisible,
    setIsDisplayOptionVisible,
    onDisplayOptionChange,
    isDeploymentReplicasEnabled,
    isVersionControlEnabled,
    displayOptions,
    currentColumns,
    i18n,
}): JSX.Element => {
    const collapsed = !isDisplayOptionVisible;
    const siderWidth = window.innerWidth < 768 ? '100%' : 360;
    const width = collapsed ? 0 : siderWidth;

    const [form] = useFormHooks();
    const classes = useStyles();
    const [checkAll, setCheckAll] = useState(Object.values(displayOptions).every((i) => i === true));
    const [indeterminate, setIndeterminate] = useState(false);
    const [curDisplayOptions, setCurDisplayOptions] = useState(getDisplayOptions());

    useEffect(() => {
        if (isEqual(displayOptions, defaultSummary)) {
            form.setFieldsValue({
                ...displayOptions,
                ...currentColumns.reduce((acc, c) => {
                    acc[c] = true;
                    return acc;
                }, {}),
            });
            form.setFieldsValue({ timestamp: true, message: true });
            setCheckAll(Object.values(displayOptions).every((i) => i === true));
        } else {
            form.setFieldsValue({
                ...currentColumns.reduce((acc, c) => {
                    acc[c] = true;
                    return acc;
                }, {}),
                ...displayOptions,
            });
            form.setFieldsValue({ timestamp: true, message: true });
            setCheckAll(Object.values(displayOptions).every((i) => i === true));
        }
    }, [isDisplayOptionVisible, currentColumns]);

    useEffect(() => {
        const checkedAll = Object.values(curDisplayOptions).every((i) => i === true);
        setCheckAll(checkedAll);
        setIndeterminate(!checkedAll && Object.values(curDisplayOptions).some((i) => i === true));
    }, [curDisplayOptions]);

    const onChangeDisplayOption = (ev) => {
        setDisplayOptions({
            timestamp: true,
            severity: ev.severity,
            versionTag: ev.versionTag,
            origin: ev.origin,
            executionId: ev.executionId,
            replicaId: ev.replicaId,
            message: true,
        });
        onDisplayOptionChange(getDisplayOptions());
    };

    const onCheckAll = (ev) => {
        setIndeterminate(!ev.target.checked);
        setCheckAll(ev.target.checked);

        form.setFieldsValue({
            timestamp: true,
            severity: ev.target.checked,
            versionTag: ev.target.checked,
            origin: ev.target.checked,
            executionId: ev.target.checked,
            replicaId: ev.target.checked,
            message: true,
        });
    };

    const onFormChange = (ev) => {
        setCurDisplayOptions((current) => ({ ...current, [ev[0].name]: ev[0].value }));
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={!isDisplayOptionVisible}
            collapsedWidth="0"
            width={width}
            breakpoint="lg"
            theme="light"
            trigger={null}
            className={classes.sider}
        >
            <div className={classes.sectionHeader}>
                <div className={classes.sectionTitle}> {i18n('display_options.display_options')} </div>
                <Button
                    type="link"
                    icon
                    iconName={icons.regular.faXmark}
                    onClick={() => {
                        setIsDisplayOptionVisible(false);
                    }}
                />
            </div>
            <Divider overrideClass={classes.marginZero} />

            <div className={classes.filterForm}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onChangeDisplayOption}
                    onChange={onFormChange}
                    id="stream-log-display-form"
                >
                    <CheckboxItem
                        overrideClass={classes.showAll}
                        onChange={onCheckAll}
                        checked={checkAll}
                        indeterminate={indeterminate}
                        data-testid="stream-log-display-show-all-columns"
                        label={i18n('display_options.show_all')}
                    />

                    <FormItem name="timestamp" valuePropName="checked">
                        <CheckboxItem
                            data-testid="stream-log-display-timestamp"
                            label={i18n('display_options.timestamp')}
                            disabled
                        />
                    </FormItem>
                    <FormItem name="severity" valuePropName="checked">
                        <CheckboxItem
                            data-testid="stream-log-display-severity"
                            label={i18n('display_options.severity')}
                        />
                    </FormItem>
                    {isVersionControlEnabled && (
                        <FormItem name="versionTag" valuePropName="checked">
                            <CheckboxItem
                                data-testid="stream-log-version-tag"
                                label={i18n('display_options.version_tag')}
                            />
                        </FormItem>
                    )}
                    <FormItem name="origin" valuePropName="checked">
                        <CheckboxItem data-testid="stream-log-display-origin" label={i18n('display_options.origin')} />
                    </FormItem>
                    <FormItem name="executionId" valuePropName="checked">
                        <CheckboxItem
                            data-testid="stream-log-display-execution-id"
                            label={i18n('display_options.execution_id')}
                        />
                    </FormItem>
                    {isDeploymentReplicasEnabled && (
                        <FormItem name="replicaId" valuePropName="checked">
                            <CheckboxItem
                                data-testid="stream-log-display-replica-id"
                                label={i18n('display_options.replica_id')}
                            />
                        </FormItem>
                    )}
                    <FormItem name="message" valuePropName="checked">
                        <CheckboxItem
                            data-testid="stream-log-display-message"
                            label={i18n('display_options.message')}
                            disabled
                        />
                    </FormItem>
                </Form>
            </div>

            <div className={classes.siderFooter}>
                <Divider overrideClass={classes.marginZero} />
                <div className={classes.filterFooter}>
                    <Button form="stream-log-display-form" type="primary" text={i18n('display_options.apply')} />
                </div>
            </div>
        </Layout.Sider>
    );
};

export default LogDisplayOption;
