import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Form, Button, Input } from 'antd';
import { Select, OptionsType, NestedOptionsType } from '@digitalroute-internal/dazzlerjs-react-ui';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUseStyles } from 'react-jss';
import { unset, get } from 'lodash';
import { icons } from '../../../../../resources';
import { FunctionType, Node } from '../../../../../dazzler-types';
import { GoTransverseEnum } from './GoTransverseEnum';
import { getFunctionDocUrl } from '../../../../../docs';
import { writers } from '../../../../../dazzler-constants';

const TEST_ID = 'go_transverse_field_mapping_item_usage';

const TARGET_ENDPOINT = {
    USAGE: {
        properties: 'definitions.usageMappings.properties',
        required: 'definitions.usageMappings.required',
        fieldMappings: 'usageFieldMappings',
    },
    SIMULATE_USAGE: {
        properties: 'definitions.usageMappings.properties',
        required: 'definitions.usageMappings.required',
        fieldMappings: 'usageFieldMappings',
    },
};

const styles = (theme) => ({
    kvpWrapper: {
        display: 'flex',
    },
    kvpInput: {
        maxWidth: '44%',
        minWidth: '44%',
        marginLeft: theme.paddingXs,
    },
    kvpNarrowInput: {
        width: '44%',
        marginLeft: theme.paddingXs,
        '& .ant-legacy-form-explain': {
            position: 'absolute',
            fontSize: theme.fontSizeSm,
        },
    },
    deleteButton: {
        border: 'none',
        cursor: 'pointer',
        background: 'white',
        display: 'flex',
        alignItems: ' flex-start',
    },
    dynamicDeleteButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'relative',
        fontSize: theme.fontSizeLg,
        color: theme.neutral05,
        transition: 'all .3s',
        padding: '10px',
        border: `1px solid ${theme.neutral03}`,
        borderRadius: '2px',
    },
    addRowBtn: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        marginBottom: theme.paddingMd,
        marginTop: '30px',
    },
    arrowIcon: {
        display: 'flex',
        alignItems: 'center',
        bottom: theme.paddingXxs,
        position: 'relative',
    },
    arrowIconCompact: {
        display: 'flex',
        alignItems: 'center',
        bottom: theme.paddingXs,
        position: 'relative',
    },
});

export type Props = {
    updateParams: (arg0: string, arg1: any | string) => void;
    sourceFields: OptionsType[];
    node: Node;
    functionType: FunctionType;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
};

type FieldMapping = 'source' | 'target';

const valuesForCustomDropdown = ['text', 'number', 'date', 'boolean'];
const customValueDropdown: NestedOptionsType[] = valuesForCustomDropdown.map((val) => {
    const dropdownValue = { label: val, options: [] };
    for (let i = 1; i <= 5; i += 1) {
        dropdownValue.options.push({
            title: `${val}0${i}`,
            value: `${val}0${i}`,
            key: `${val}0${i}`,
        });
    }
    return dropdownValue;
});

const CustomErrorHelpMessage = (errorMessage: string, errorLink: string, errorLinkMessage: string) => (
    <>
        {errorMessage}{' '}
        <a href={errorLink} target="_blank" rel="noreferrer">
            {errorLinkMessage}
        </a>
    </>
);

const useStyles = createUseStyles(styles);

const GoTransverseFieldMappingsItemUsage = ({
    node,
    sourceFields,
    updateParams,
    onUpdate,
    functionType,
    i18nPrefix,
}: Props) => {
    const intl = useIntl();
    const classes = useStyles();
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });
    const [existingMappings, setExistingMappings] = useState([]);
    const [existingEndpoint, setExistingEndpoint] = useState('');
    const apiEndpoint = get(node.params, GoTransverseEnum.API_ENDPOINT, GoTransverseEnum.USAGE_ENUM);

    const customFieldsPattern = /^(text|number|boolean|date)([0])([1-9])/g;

    const updateCustomValueDropdown = (value: string, index: number) => {
        const newExistingMappings = existingMappings.map((data, idx) =>
            idx === index ? { ...data, target: value } : data,
        );
        setExistingMappings(newExistingMappings);

        // Refractor this portion
        const existingCustomMappings = newExistingMappings.reduce((acc, curr: any) => {
            if (!curr.required && curr.editable) {
                return { ...acc, [curr.target]: curr.source };
            }
            return acc;
        }, {});

        if (Object.keys(existingCustomMappings).length) {
            const updatedMappings = {
                ...existingCustomMappings,
                [value]: existingMappings[index].source,
            };

            updateParams('customFieldMappings', updatedMappings);
        }
    };

    const addRow = () => {
        setExistingMappings([
            ...existingMappings,
            {
                source: undefined,
                target: undefined,
                required: false,
                editable: true,
            },
        ]);
    };

    const removeRow = (index: number) => {
        const newData = existingMappings.filter((m, i) => i !== index);
        const endpoint = get(node.params, GoTransverseEnum.API_ENDPOINT, node.params.gotransverseApiEndpoint);
        setExistingMappings(newData);

        const data = newData.reduce(
            (result, { required, editable, target, source }) => {
                const key = required || !editable ? 'required' : 'custom';
                return {
                    ...result,
                    [key]: {
                        ...result[key],
                        [target]: source,
                    },
                };
            },
            { required: {}, custom: {} },
        );

        const n = {
            ...node,
            params: {
                ...node.params,
                [TARGET_ENDPOINT[endpoint].fieldMappings]: data.required,
                customFieldMappings: data.custom,
            },
        };

        onUpdate(n);
    };

    const loadMapping = (endpoint: string) => {
        const allTargets = Object.keys(get(functionType.params, TARGET_ENDPOINT[endpoint].properties, {}));
        const requiredTargets = get(functionType.params, TARGET_ENDPOINT[endpoint].required, []);
        const optionalTargets = allTargets.filter((target) => !requiredTargets.includes(target));

        const fieldMappings = get(node.params, TARGET_ENDPOINT[endpoint].fieldMappings, {});
        const customFieldMappings = get(node.params, 'customFieldMappings', {});

        const required = requiredTargets.map((target) => ({
            source: fieldMappings[target],
            target,
            required: true,
            editable: false,
        }));

        const optional = optionalTargets.map((target) => ({
            target,
            source: fieldMappings[target],
            required: false,
            editable: false,
        }));

        const custom = Object.keys(customFieldMappings).map((key) => ({
            source: customFieldMappings[key],
            target: key,
            required: false,
            editable: true,
        }));

        const newMappings = [...required, ...optional, ...custom];
        setExistingMappings(newMappings);
        setExistingEndpoint(endpoint);
    };

    const updateSourceMapping = (index: number, value: string, source: FieldMapping) => {
        const fieldMappings = get(node.params, TARGET_ENDPOINT[apiEndpoint].fieldMappings, {});
        const customFieldMappings = get(node.params, 'customFieldMappings', {});
        const newData = existingMappings.map((m, i) => (i === index ? { ...m, [source]: value } : m));
        const isRequired = newData[index]?.required || false;
        const isEditable = newData[index]?.editable || false;
        let updatedMappings: [];
        if (!isRequired && !isEditable && !value && updatedMappings) {
            delete updatedMappings[newData[index].target];
        }
        if (isRequired || !isEditable) {
            updatedMappings = {
                ...fieldMappings,
                [newData[index].target]: value,
            };
        } else if (source === 'source') {
            updatedMappings = {
                ...customFieldMappings,
                [newData[index].target]: value,
            };
        }
        if (updatedMappings) {
            setExistingMappings(newData);
            updateParams(
                isRequired || !isEditable ? TARGET_ENDPOINT[apiEndpoint].fieldMappings : 'customFieldMappings',
                updatedMappings,
            );
        }
    };

    useEffect(() => {
        if (existingEndpoint !== apiEndpoint) {
            if (existingEndpoint !== '') {
                unset(node.params, TARGET_ENDPOINT[existingEndpoint].fieldMappings);
            }
            loadMapping(apiEndpoint);
        }
    }, [node.params?.[GoTransverseEnum.API_ENDPOINT]]);

    const flattenSourceFields = (initSourceFields: OptionsType[], flatSourceField: OptionsType[] = []): any[] => {
        let processedList = [...flatSourceField];
        initSourceFields.forEach((sourceEl: OptionsType) => {
            const sourceKey = sourceEl.key.split('.').pop();
            const sourceValue = (sourceEl.value as string).split('.').pop();
            const keyExists = processedList.find((e) => e.key === sourceKey);
            if (sourceEl.children && sourceEl.children.length) {
                processedList = flattenSourceFields(sourceEl.children, processedList);
            } else if (!keyExists) {
                processedList.push({
                    children: [],
                    key: sourceKey,
                    value: sourceValue,
                    title: sourceValue,
                });
            }
        });
        return processedList;
    };

    const flattenedSourceFields = flattenSourceFields(sourceFields);

    const findValueInTreeData = (valueToFind: string, treeData): boolean => {
        const result = treeData.find((el) => {
            let foundInTreeData = el.value === valueToFind;
            if (!foundInTreeData && el.children.length >= 1) {
                foundInTreeData = findValueInTreeData(valueToFind, el.children);
            }
            return foundInTreeData;
        });
        return result !== undefined && Object.keys(result).length >= 1;
    };

    const addAddonDataTreeData = (valueToFind: string, treeData) => {
        const foundInTreeData = valueToFind === undefined ? true : findValueInTreeData(valueToFind, treeData);
        return foundInTreeData
            ? treeData
            : [
                  ...treeData,
                  {
                      children: [],
                      key: valueToFind,
                      title: valueToFind,
                      value: valueToFind,
                  },
              ];
    };

    return (
        <fieldset>
            <legend>{i18n('mappings_legend')}</legend>
            {existingMappings.map((field, idx) => (
                //  eslint-disable-next-line
                <div key={`em${idx}`}>
                    <div className={classes.kvpWrapper}>
                        <Form.Item className={classes.kvpInput} label={idx === 0 && i18n('mappings_source_field')}>
                            {flattenedSourceFields.length ? (
                                <Select
                                    isTree={false}
                                    optionData={addAddonDataTreeData(field.source, flattenedSourceFields)}
                                    onChange={(val: string) => updateSourceMapping(idx, val, 'source')}
                                    value={field.source}
                                    allowClear
                                    allowAddon
                                    placeholder={i18n('mappings_source_field_search')}
                                />
                            ) : (
                                <Input
                                    placeholder={i18n('mappings_source_field_search')}
                                    size="large"
                                    data-testid={`${TEST_ID}_source_input_${idx}`}
                                    value={field.source}
                                    onChange={(ev) => updateSourceMapping(idx, ev.target.value, 'source')}
                                />
                            )}
                        </Form.Item>
                        <div className={idx === 0 ? classes.arrowIcon : classes.arrowIconCompact}>
                            <FontAwesomeIcon icon={icons.solid.faArrowCircleRight} />
                        </div>
                        <Form.Item
                            className={field.editable ? classes.kvpNarrowInput : classes.kvpInput}
                            label={idx === 0 && i18n('mappings_target_field')}
                            help={
                                !field.required &&
                                field.editable &&
                                field.target !== undefined &&
                                !field.target.match(customFieldsPattern) &&
                                !field.hasFeedback &&
                                CustomErrorHelpMessage(
                                    i18n('custom_target_error_message'),
                                    getFunctionDocUrl(writers.gotransverseUsage),
                                    i18n('custom_target_error_link_message'),
                                )
                            }
                            validateStatus={
                                !field.required &&
                                field.editable &&
                                field.target !== undefined &&
                                !field.target.match(customFieldsPattern)
                                    ? 'error'
                                    : 'validating'
                            }
                        >
                            {!field.editable ? (
                                <Input
                                    value={field.required ? `*${field.target}` : field.target}
                                    disabled
                                    size="large"
                                    type="text"
                                    placeholder={i18n('mappings_target_field_placeholder')}
                                    data-testid={`${TEST_ID}_target_input_${idx}`}
                                />
                            ) : (
                                <Select
                                    value={field.target}
                                    placeholder={i18n('mappings_target_field_placeholder')}
                                    onChange={(val: string) => updateCustomValueDropdown(val, idx)}
                                    isTree={false}
                                    optionData={customValueDropdown}
                                />
                            )}
                        </Form.Item>
                        {field.editable && (
                            <button
                                aria-label="Delete"
                                type="button"
                                className={classes.deleteButton}
                                onClick={() => removeRow(idx)}
                            >
                                <div className={classes.dynamicDeleteButton}>
                                    <FontAwesomeIcon icon={icons.solid.faTrashAlt} />
                                </div>
                            </button>
                        )}
                    </div>
                </div>
            ))}
            <Button className={classes.addRowBtn} onClick={addRow} type="dashed">
                <PlusOutlined />
                {i18n('mappings_add_row_title')}
            </Button>
        </fieldset>
    );
};

export default GoTransverseFieldMappingsItemUsage;
