import React, { useEffect, useState } from 'react';
import { get, set } from 'lodash';
import {
    FormItem,
    Select,
    CollapsiblePanel,
    Button,
    icons,
    Divider,
    FieldMapping,
    OptionProp,
    OptionsType,
} from '@digitalroute-internal/dazzlerjs-react-ui';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';
import { FunctionType, Node } from '../../../../../dazzler-types';
import { getFunctionValidationMessage } from '../../../../../dazzler-intl';

enum CustomReferenceAction {
    Add = 'add',
    Remove = 'remove',
    Change = 'change',
}

const { Panel } = Collapse;

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    treeNodeData: any;
    classes: any;
    i18n: any;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => OptionsType[];
    isPreviewClicked: boolean;
};

const TESTID_PREFIX = 'SapSubscriptionItem';
const SapSubscriptionItem = ({
    node,
    onUpdate,
    functionType,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
    isPreviewClicked,
}: Props) => {
    const itemsRequired: string[] = get(
        functionType,
        'params.definitions.items.items.properties.fields.items.required',
        [],
    );
    const itemsProperties: {} = get(
        functionType,
        'params.definitions.items.items.properties.fields.items.properties',
        {},
    );
    const itemsDependencies: {} = get(
        functionType,
        'params.definitions.items.items.properties.fields.items.dependencies',
        {},
    );
    const intl = useIntl();
    const itemsParam = get(node, 'params.items', []);
    const [items, setItems] = useState(itemsParam);

    const onClickAddItem = () => {
        setItems((arr) => [...arr, { fields: [{}], customReferences: [] }]);
    };
    useEffect(() => {
        set(node.params, 'items', items);
        onUpdate(node);
    }, [items]);

    const onChangeItemDropdown = (selectedValue: string, itemValue: string, idx: number) => {
        items[idx].fields[0] = set(items[idx].fields[0], itemValue, selectedValue);
        setItems([...items]);
    };
    const drawExtraDeleteButton = (idx: number) => (
        <div className={classes.buttonParentDiv}>
            <Button
                iconName={icons.regular.faTrashAlt}
                icon
                onlyIcon
                onClick={() => setItems((currentItems) => currentItems.filter((curItem, i) => i !== idx))}
            />
        </div>
    );

    const customReferenceAction = (
        action: CustomReferenceAction,
        itemIdx: number,
        custRefIdx?: number,
        val?: string,
        paramName?: string,
    ) => {
        const newItems = items;
        switch (action) {
            case CustomReferenceAction.Add:
                newItems[itemIdx].customReferences.push({});
                break;
            case CustomReferenceAction.Remove:
                newItems[itemIdx].customReferences = newItems[itemIdx].customReferences.filter(
                    (_curItem, i) => i !== custRefIdx,
                );
                break;
            case CustomReferenceAction.Change:
                newItems[itemIdx].customReferences[custRefIdx][paramName] = val;
                break;
            default:
                return;
        }
        setItems([...newItems]);
    };

    const checkItemDependencies = (itemValue: string, curItemField): string => {
        const listOfRelatedFields = [];
        Object.keys(curItemField).forEach((key) => {
            if (itemsDependencies[key] && curItemField[key] !== undefined) {
                if (itemsDependencies[key].includes(itemValue)) {
                    listOfRelatedFields.push(key);
                }
            }
        });
        return listOfRelatedFields.join(', ');
    };
    const itemsDependenciesErrorMessage = (itemIdx: number, itemValue: string): JSX.Element => {
        const curItemField = items[itemIdx].fields[0];
        let result: JSX.Element = <></>;
        if (curItemField[itemValue] === undefined) {
            const requiredItem = checkItemDependencies(itemValue, curItemField);
            if (requiredItem) {
                result = (
                    <p className={classes.errorText}>
                        {intl.formatMessage(
                            { id: 'function_config.writer.sap_sb_query.item_error_message' },
                            { value: itemValue, required: requiredItem },
                        )}
                    </p>
                );
            }
        }

        return result;
    };

    const onAddonChange = (newValue: string, itemValue: string, itemIdx: number) => {
        if (newValue) {
            const newItems = items;
            newItems[itemIdx].fields[0] = set(newItems[itemIdx].fields[0], itemValue, newValue);
            setItems([...newItems]);
        }
    };

    return (
        <div className={classes.formItemContainer}>
            <FormItem>
                <legend>{i18n('items')}</legend>

                {items.map((arrVal, itemIdx: number) => {
                    const itemFields = arrVal.fields[0];
                    const itemCustomReferences = arrVal.customReferences;
                    return (
                        <div
                            className={classes.collapsibleContainer}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`itemContainerIdx_${itemIdx}`}
                            data-testid={`${TESTID_PREFIX}__CollapsiblePanel-${itemIdx}`}
                        >
                            <CollapsiblePanel onChange={() => {}}>
                                <Panel
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`itemid_${itemIdx}`}
                                    header={itemFields.fieldCode ? itemFields.fieldCode : i18n('new_item')}
                                    extra={drawExtraDeleteButton(itemIdx)}
                                >
                                    {Object.keys(itemsProperties).map((itemValue) => {
                                        const selectedValue = itemFields[itemValue] ? itemFields[itemValue] : undefined;
                                        const itemIsRequired = itemsRequired.indexOf(itemValue) > -1;
                                        const errorStatus =
                                            isPreviewClicked && itemIsRequired && !selectedValue ? 'error' : undefined;
                                        const errorText = getFunctionValidationMessage(intl, 'required');
                                        return (
                                            <FormItem
                                                key={`itemIdx_${itemValue}`}
                                                label={
                                                    <div className={classes.formLabel}>
                                                        {itemIsRequired
                                                            ? `* ${i18n(`item_param_${itemValue}`)}`
                                                            : i18n(`item_param_${itemValue}`)}
                                                    </div>
                                                }
                                            >
                                                <Select
                                                    isTree
                                                    optionData={addAddonDataTreeData(selectedValue, treeNodeData)}
                                                    allowClear
                                                    onChange={(value: string) =>
                                                        onChangeItemDropdown(value, itemValue, itemIdx)
                                                    }
                                                    value={selectedValue}
                                                    allowAddon
                                                    inputAddonProps={{
                                                        isAppend: false,
                                                        checkDuplicateBeforeAppend: false,
                                                        checkDuplicateValue: true,
                                                    }}
                                                    onOptionsChange={(_newOptions, newValue) =>
                                                        onAddonChange(newValue, itemValue, itemIdx)
                                                    }
                                                    status={errorStatus}
                                                    errorText={errorText}
                                                    data-testid={`${TESTID_PREFIX}__Select-${itemValue}`}
                                                />

                                                {items && itemsDependenciesErrorMessage(itemIdx, itemValue)}
                                            </FormItem>
                                        );
                                    })}

                                    {itemCustomReferences.map((custRefArr, custRefIdx) => {
                                        // eslint-disable-next-line consistent-return
                                        const validateInput = (isTarget: boolean, currentValue, relatedValue) => {
                                            // only validate when no current value
                                            if (isPreviewClicked && !currentValue) {
                                                // related value is not set, show required message
                                                if (!relatedValue) {
                                                    return getFunctionValidationMessage(intl, 'required');
                                                }
                                                // related value IS set, show related message
                                                const validationObj = {
                                                    currentFieldName: isTarget
                                                        ? i18n('item_param_typeCode')
                                                        : i18n('custom_reference_id'),
                                                    relatedFieldName: isTarget
                                                        ? i18n('custom_reference_id')
                                                        : i18n('item_param_typeCode'),
                                                };
                                                return getFunctionValidationMessage(intl, 'related', validationObj);
                                            }
                                        };
                                        const options: OptionProp[] = [
                                            {
                                                name: 'custom_reference_typecode',
                                                label: `* ${i18n('item_param_typeCode')}`,
                                                isSelect: true,
                                                selectOption: {
                                                    isTree: true,
                                                    optionData: addAddonDataTreeData(custRefArr.typeCode, treeNodeData),
                                                    value: custRefArr.typeCode ? custRefArr.typeCode : undefined,
                                                    allowAddon: true,
                                                    inputAddonProps: {
                                                        isAppend: false,
                                                        checkDuplicateBeforeAppend: false,
                                                        checkDuplicateValue: true,
                                                    },
                                                    onChange: (val: string) =>
                                                        customReferenceAction(
                                                            CustomReferenceAction.Change,
                                                            itemIdx,
                                                            custRefIdx,
                                                            val,
                                                            'typeCode',
                                                        ),
                                                    onOptionsChange: (_newOptions, newValue) => {
                                                        if (newValue) {
                                                            customReferenceAction(
                                                                CustomReferenceAction.Change,
                                                                itemIdx,
                                                                custRefIdx,
                                                                newValue,
                                                                'typeCode',
                                                            );
                                                        }
                                                    },
                                                },
                                                hasError: !!validateInput(true, custRefArr.typeCode, custRefArr.id),
                                                errorMessage: validateInput(true, custRefArr.typeCode, custRefArr.id),
                                            },
                                            {
                                                name: 'custom_reference_id',
                                                label: `* ${i18n('custom_reference_id')}`,
                                                isSelect: true,
                                                selectOption: {
                                                    isTree: true,
                                                    optionData: addAddonDataTreeData(custRefArr.id, treeNodeData),
                                                    value: custRefArr.id ? custRefArr.id : undefined,
                                                    allowAddon: true,
                                                    inputAddonProps: {
                                                        isAppend: false,
                                                        checkDuplicateBeforeAppend: false,
                                                        checkDuplicateValue: true,
                                                    },
                                                    onChange: (val: string) =>
                                                        customReferenceAction(
                                                            CustomReferenceAction.Change,
                                                            itemIdx,
                                                            custRefIdx,
                                                            val,
                                                            'id',
                                                        ),
                                                    onOptionsChange: (_newOptions, newValue) => {
                                                        if (newValue) {
                                                            customReferenceAction(
                                                                CustomReferenceAction.Change,
                                                                itemIdx,
                                                                custRefIdx,
                                                                newValue,
                                                                'id',
                                                            );
                                                        }
                                                    },
                                                },
                                                hasError: !!validateInput(false, custRefArr.id, custRefArr.typeCode),
                                                errorMessage: validateInput(false, custRefArr.id, custRefArr.typeCode),
                                            },
                                        ];
                                        return (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <div className={classes.spacingTop} key={`itemCustRefIdx_${custRefIdx}`}>
                                                <FieldMapping
                                                    options={options}
                                                    onDelete={() =>
                                                        customReferenceAction(
                                                            CustomReferenceAction.Remove,
                                                            itemIdx,
                                                            custRefIdx,
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className={classes.spacingTop}>
                                        <Button
                                            type="dashed"
                                            text={i18n('add_custom_reference')}
                                            icon
                                            iconName={icons.regular.faPlus}
                                            onClick={() => customReferenceAction(CustomReferenceAction.Add, itemIdx)}
                                        />
                                    </div>
                                </Panel>
                            </CollapsiblePanel>
                        </div>
                    );
                })}

                <div className={classes.spacingTop}>
                    <Button
                        type="dashed"
                        text={i18n('add_item')}
                        icon
                        iconName={icons.regular.faPlus}
                        onClick={onClickAddItem}
                        data-testid={`${TESTID_PREFIX}__Button-add-item`}
                    />
                </div>
            </FormItem>

            <Divider />
        </div>
    );
};

export default SapSubscriptionItem;
