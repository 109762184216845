import { v4 as uuidv4 } from 'uuid';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import validationSchema from './validation-meta.json';
import {
    DataObject,
    Property,
    ValidationRule,
    CreateValidationRuleProps,
    ValidationGroupsType,
    CreateObjectProps,
    CreatePropertyProps,
} from './types';

export const defaultValues = {
    maxLength: 0,
    minLength: 0,
    maxItems: 0,
    minItems: 0,
    maxProperties: 0,
    maxContains: 0,
    multipleOf: 1,
    minProperties: 1,
    minContains: 1,
    uniqueItems: 'no',
    required: 'yes',
};

export const createObject = ({
    objectName = 'New Object',
    expand = true,
    properties = [],
}: CreateObjectProps = {}): DataObject => ({
    objectName,
    properties,
    expand,
    key: uuidv4(),
});

export const createProperty = ({
    objectKey,
    description,
    propertyValue = 'newField',
    expand = true,
    validationRules = [],
}: CreatePropertyProps = {}): Property => ({
    objectKey,
    expand,
    description,
    propertyValue,
    validationRules,
    key: uuidv4(),
});

export const createValidationRule = ({
    objectKey,
    propertyKey,
    validationRuleKey,
    validationRuleValue,
}: CreateValidationRuleProps): ValidationRule => ({
    propertyKey,
    validationRuleKey,
    validationRuleValue,
    key: uuidv4(),
    ...(objectKey ? { objectKey } : {}),
});

export const getValidationGroups = (schemaProperties: string[]) => {
    const groups: ValidationGroupsType = {
        strings: { title: 'validation_group.string', values: [] },
        numbers: { title: 'validation_group.number', values: [] },
        instanceType: { title: 'validation_group.type', values: [] },
        arrays: { title: 'validation_group.array', values: [] },
        objects: { title: 'validation_group.object', values: [] },
        custom: { title: 'validation_group.custom', values: [] },
    };

    const propertyGroups = {
        strings: ['maxLength', 'minLength', 'pattern'],
        numbers: ['multipleOf', 'maximum', 'minimum', 'exclusiveMaximum', 'exclusiveMinimum'],
        instanceType: ['type', 'enum', 'const'],
        arrays: ['maxItems', 'minItems', 'uniqueItems'],
        objects: ['maxProperties', 'minProperties', 'required', 'dependentRequired'],
        custom: ['nestedObject'],
    };

    schemaProperties.forEach((property) => {
        Object.entries(propertyGroups).forEach(([group, values]) => {
            if (values.includes(property)) {
                groups[group].values.push(property);
            }
        });
    });

    return groups;
};

export const getPropertyDescription = (property: string) => {
    const propertyDescription = [
        'maxLength',
        'minLength',
        'pattern',
        'multipleOf',
        'maximum',
        'minimum',
        'exclusiveMaximum',
        'exclusiveMinimum',
        'type',
        'enum',
        'const',
        'maxItems',
        'minItems',
        'uniqueItems',
        'contains',
        'maxContains',
        'minContains',
        'maxProperties',
        'minProperties',
        'required',
        'number',
        'integer',
        'array',
        'null',
        'boolean',
        'string',
        'object',
        'nestedObject',
    ];

    if (propertyDescription.includes(property)) {
        return `property_description.${property}`;
    }

    return '';
};

// @ts-ignore
export const dereferenceSchema = () => $RefParser.dereference(validationSchema);
