import React from 'react';
import { Form, Popover, Switch } from 'antd';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import { unset } from 'lodash';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInputItem from '../../configurator-fields/TextInputItem';
import { SecretInput } from '../../configurator-fields';
import { icons } from '../../../../../resources';
import type { Node } from '../../../../../dazzler-types';

const styles = (theme) => ({
    rightElement: {
        marginRight: theme.marginXs,
    },
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
});

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    i18nPrefix: string;
};

const useStyles = createUseStyles(styles);

const GoTransverseS3Writer = ({ node, onUpdate, i18nPrefix }: Props) => {
    const intl = useIntl();
    const i18n = (values: string) => intl.formatMessage({ id: `${i18nPrefix}.${values}` });
    const i18nS3 = (values: string) => intl.formatMessage({ id: `function_config.generic.s3.${values}` });
    const classes = useStyles();

    const onChangeS3Upload = (s3UploadToggle: boolean) => {
        if (!s3UploadToggle) {
            unset(node.params, 'accessKeyId');
            unset(node.params, 'secretAccessKey');
            unset(node.params, 'bucket');
            unset(node.params, 'folder');
            unset(node.params, 'fileName');
        }
        const n = {
            ...node,
            params: {
                ...node.params,
                s3Upload: s3UploadToggle,
            },
        };
        onUpdate(n);
    };
    return (
        <>
            <div className={classes.grid}>
                <legend>{i18n('api_response_collection')}</legend>
                <div className={classes.rightSection}>
                    <Switch
                        className={classes.rightElement}
                        onChange={onChangeS3Upload}
                        checked={node.params.s3Upload}
                    />
                </div>
            </div>
            {node.params.s3Upload && (
                <fieldset>
                    <legend>{i18n('aws_credential_legend')}</legend>
                    <TextInputItem
                        label={i18nS3('accessKeyId')}
                        path="accessKeyId"
                        required
                        node={node}
                        onUpdate={onUpdate}
                        placeholder={i18nS3('accessKeyId_placeholder')}
                    />
                    <Form.Item label={i18nS3('secretAccessKey')} required>
                        <SecretInput
                            node={node}
                            path="secretAccessKey"
                            onUpdate={onUpdate}
                            placeholder={i18nS3('secretAccessKey_placeholder')}
                        />
                    </Form.Item>

                    <legend>{i18nS3('file_location')}</legend>
                    <TextInputItem
                        label={i18nS3('bucket')}
                        path="bucket"
                        required
                        node={node}
                        onUpdate={onUpdate}
                        placeholder={i18nS3('bucket_placeholder')}
                    />

                    <TextInputItem
                        label={i18nS3('folder')}
                        path="folder"
                        node={node}
                        onUpdate={onUpdate}
                        placeholder={i18nS3('prefix_placeholder')}
                        rules={[
                            {
                                message: i18nS3('folder_pattern'),
                                pattern: new RegExp('^([^/]+/)*([^/]+)$'),
                            },
                        ]}
                    />
                    <TextInputItem
                        label={
                            <div>
                                <span className={classes.rightElement}>{i18n('filename_input_label')}</span>
                                <Popover
                                    content={<ReactMarkdown>{i18n('filename_input_tooltip')}</ReactMarkdown>}
                                    placement="bottomLeft"
                                >
                                    <FontAwesomeIcon icon={icons.solid.faInfoCircle} />
                                </Popover>
                            </div>
                        }
                        path="fileName"
                        node={node}
                        required
                        onUpdate={onUpdate}
                        placeholder={i18n('filename_input_placeholder')}
                    />
                </fieldset>
            )}
        </>
    );
};

export default GoTransverseS3Writer;
