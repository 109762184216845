import React, { ChangeEvent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import QueueAnim from 'rc-queue-anim';
import { icons } from '../../../../../../../resources';
import { Property, DataObject, SourceFields } from '../types';
import FieldSelect from '../common/FieldSelect';
import ValidationRules from '../validation-rules/ValidationRules';
import { useValidatorState } from '../context';
import RadioPairItem, { FIRST_VALUE, SECOND_VALUE } from '../../../../configurator-fields/RadioPairItem';
import { getNodeInputFieldsTreeData, getStreamConfiguration, StreamsPreviewState } from '../../../../../../../streams';

const styles = (theme) => ({
    buttonDiv: {
        display: 'flex',
        justifyContent: 'start',
        marginTop: theme.marginLg,
        marginBottom: theme.marginXs,
    },
    topMargin: {
        marginTop: theme.marginXs,
    },
    addIcon: {
        marginRight: theme.marginXs,
    },
    selectOverwrite: {
        '&.ant-select, &.ant-input': {
            marginTop: theme.marginXs,
        },
    },
    radioPair: {
        marginBottom: 0,
    },
});

const useStyles = createUseStyles(styles);

const i18n = (id: string) => useIntl().formatMessage({ id: `function_config.transforms.validator.${id}` });

type Props = {
    isObject?: boolean;
    objectKey?: DataObject['key'];
    property: Property;
    disabled?: boolean;
};

const PropertyDetails = ({
    property,
    objectKey,
    property: { key, description, validationRules, propertyValue },
    isObject = false,
    disabled = false,
}: Props) => {
    const { dispatch, node } = useValidatorState();
    const classes = useStyles();

    const stream = useSelector((state: any) => getStreamConfiguration(state));
    const inputFieldsTree = useSelector((state: StreamsPreviewState) =>
        getNodeInputFieldsTreeData(state, node, stream),
    );

    const isExistingField =
        propertyValue === 'newField' || inputFieldsTree.some((field) => field.value === propertyValue);
    const [existingField, setExistingField] = useState(isExistingField);

    const addValidationRule = () => {
        dispatch({
            type: isObject ? 'ADD_OBJECT_VALIDATION' : 'ADD_PROPERTY_VALIDATION',
            payload: property,
        });
    };

    const descriptionChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isObject) {
            dispatch({
                type: 'UPDATE_OBJECT_PROPERTY',
                payload: {
                    key: objectKey,
                    value,
                    field: 'description',
                    propertyKey: key,
                },
            });
        } else {
            dispatch({
                type: 'UPDATE_PROPERTY',
                payload: {
                    key,
                    value,
                    field: 'description',
                },
            });
        }
    };

    const onFieldChange = (value: string) => {
        if (isObject) {
            dispatch({
                type: 'UPDATE_OBJECT_PROPERTY',
                payload: {
                    key: objectKey,
                    value,
                    field: 'propertyValue',
                    propertyKey: key,
                },
            });
        } else {
            dispatch({
                type: 'UPDATE_PROPERTY',
                payload: {
                    key,
                    value,
                    field: 'propertyValue',
                },
            });
        }
    };

    const onInputChange = ({ target: { value } }) => {
        onFieldChange(value);
    };

    const onFieldTypeChange = (value: 'first' | 'second') => {
        setExistingField(value === 'first');
        onFieldChange('');
    };
    const renderValidationRules = validationRules.map((rule, i) => (
        <div key={rule.key}>
            <ValidationRules key={rule.key} rule={rule} isObject={isObject} isFirst={i === 0} disabled={disabled} />
        </div>
    ));

    return (
        <>
            <RadioPairItem
                label={`${i18n('property')}:`}
                firstOption={`${i18n('field_first_option')}`}
                secondOption={`${i18n('field_second_option')}`}
                onChange={onFieldTypeChange}
                value={existingField ? FIRST_VALUE : SECOND_VALUE}
                disabled={disabled}
            />
            <FieldSelect
                fieldName={property.key}
                value={propertyValue}
                onChange={existingField ? onFieldChange : onInputChange}
                existingField={existingField}
                inputFieldsTree={existingField ? (inputFieldsTree as Array<SourceFields>) : null}
                disabled={disabled}
            />
            <div className={classes.topMargin}>
                {`${i18n('description')}:`}
                <Input
                    className={classes.selectOverwrite}
                    size="middle"
                    type="text"
                    onChange={descriptionChange}
                    value={description}
                    placeholder={i18n('description_placeholder')}
                    autoComplete="off"
                    disabled={disabled}
                />
            </div>
            <QueueAnim type={['right', 'left']} leaveReverse>
                {renderValidationRules}
            </QueueAnim>
            <div className={classes.buttonDiv}>
                <Button type="dashed" onClick={addValidationRule} disabled={disabled}>
                    <FontAwesomeIcon className={classes.addIcon} icon={icons.regular.faPlus} />
                    {i18n('add_validation_rule')}
                </Button>
            </div>
        </>
    );
};

export default PropertyDetails;
