import { handleActions } from 'redux-actions';
import { setActionStream, setActionType } from '../actions/action';
import { StreamInstance } from '../../../../../packages/dazzler-types';
import type { ActionType } from '../../types';

export type State = {
    type: ActionType;
    stream: StreamInstance;
};

export const initialState = {
    type: '',
    stream: {},
} as unknown as State;

export const reducer = handleActions<State>(
    {
        [setActionType.toString()]: (state: State, { payload }): State => ({
            ...state,
            type: payload,
        }),
        [setActionStream.toString()]: (state: State, { payload }): State => ({
            ...state,
            stream: payload,
        }),
    },
    initialState,
);
