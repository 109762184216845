import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import TextInputItem from '../../configurator-fields/TextInputItem';
import SalesforceFieldMappings from '../../common/SalesforceFieldMappings';
import { getNodeInputFieldsTreeData, getSourceNodes, StreamsPreviewState } from '../../../../../streams';
import RadioPairItem, { FIRST_VALUE, SECOND_VALUE } from '../../configurator-fields/RadioPairItem';
import { FeatureToggleFn, ConfiguratorProps, Node } from '../../../../../dazzler-types';
import { isFeatureEnabled as isFeatureToggleEnabled } from '../../../../../feature-toggles';
import { SalesforceBulkParameters } from '../../parameters/transforms';
import { SourceFields } from './validatev2/types';

enum ObjectType {
    USAGE = 'usageObject',
    CUSTOM = 'customObject',
}

const I18N_PREFIX = 'function_config.transform.salesforce_bulk';

const SalesforceBulkConfigurator = ({ node, onUpdate, functionType, stream }: ConfiguratorProps) => {
    const intl = useIntl();
    const currentObject = get(node.params, 'objectType', ObjectType.USAGE);
    const [selectedObj, setSelectedObj] = useState(currentObject);
    const isFeatureEnabled: FeatureToggleFn = useSelector(isFeatureToggleEnabled);
    const sourceFields = useSelector((state: StreamsPreviewState) =>
        getNodeInputFieldsTreeData(state, node, stream),
    ) as Array<SourceFields>;
    const isVersionControlPostPhase2Enabled: boolean = isFeatureEnabled('versionControlPostPhase2');
    const isDefaultObject = selectedObj === ObjectType.USAGE;
    const sourceNodes: Node[] = useSelector((state: any) => getSourceNodes(state, node.id));

    useEffect(() => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                objectType: selectedObj,
                ...(selectedObj === ObjectType.USAGE && { object: 'blng__Usage__c' }),
            },
        });
    }, []);

    const i18n = (id: string, values?: any, prefix: string = I18N_PREFIX) =>
        intl.formatMessage({ id: `${prefix}.${id}` }, values);

    const update = (type: string, newData: any | string) => {
        onUpdate({
            ...node,
            params: {
                ...node.params,
                [type]: newData,
            },
        });
    };

    const updateParams = (type: string, newData: any | string) => update(type, newData);

    const onObjectUpdate = (value: ObjectType, isInitialLoading: boolean) => {
        const customObject = isInitialLoading ? node.params.object : '';
        onUpdate({
            ...node,
            params: {
                ...node.params,
                objectType: value,
                object: value === ObjectType.USAGE ? 'blng__Usage__c' : customObject,
            },
        });
    };

    const onSwitchObj = (value: string) => {
        let objectToUpdate;
        switch (value) {
            case 'first':
                objectToUpdate = ObjectType.USAGE;
                break;
            case 'second':
                objectToUpdate = ObjectType.CUSTOM;
                break;
            default:
                objectToUpdate = ObjectType.USAGE;
        }

        setSelectedObj(objectToUpdate);
        onObjectUpdate(objectToUpdate, false);
    };

    return (
        <>
            {!isVersionControlPostPhase2Enabled && (
                <SalesforceBulkParameters node={node} onUpdate={onUpdate} functionType={functionType} />
            )}
            <Form layout="vertical">
                <legend>{i18n('object_config_legend')}</legend>
                <RadioPairItem
                    label={i18n('object_select_radio_label')}
                    firstOption={i18n('object_type_usage')}
                    secondOption={i18n('object_type_other')}
                    onChange={onSwitchObj}
                    value={isDefaultObject ? FIRST_VALUE : SECOND_VALUE}
                />
                {!isDefaultObject && (
                    <TextInputItem
                        label={i18n('object')}
                        path="object"
                        required
                        node={node}
                        onUpdate={onUpdate}
                        placeholder={i18n('object_placeholder')}
                    />
                )}
                <SalesforceFieldMappings
                    node={node}
                    onUpdate={onUpdate}
                    functionType={functionType}
                    updateParams={updateParams}
                    sourceFields={sourceFields}
                    renderDefaultFields={isDefaultObject}
                    stream={stream}
                    sourceNodes={sourceNodes}
                />
            </Form>
        </>
    );
};

export default SalesforceBulkConfigurator;
