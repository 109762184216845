import React, { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { clsx } from 'clsx';
import { Form, Input, InputNumber, Radio, Tooltip, Alert, Space, RadioChangeEvent } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Banner } from '@digitalroute-internal/dazzlerjs-react-ui';
import { isFeatureEnabled } from '../../../../../feature-toggles';
import { connectOrDiscardInterconnect, getInterconnect, getStreamConfiguration } from '../../../../../streams';
import { useStreamListState } from '../../../../../../containers/solutions/stream-list/state/hooks';
import { SetParametersType } from '../../../../../../containers/solutions/stream-list/types';
import { getActiveAccountId, selectInterconnectMetaData } from '../../../../../accounts';
import { getDocUrl } from '../../../../../docs';
import type {
    Account,
    FeatureToggleFn,
    InterconnectMetaData,
    MappedInterconnect,
    ParametersProps,
    Stream,
} from '../../../../../dazzler-types';

const styles = (theme) => ({
    connectOrDiscard: {
        marginLeft: theme.marginXs,
    },
    warning: {
        marginRight: theme.marginXs,
    },
    marginTop: {
        marginTop: 20,
    },
    successCopy: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: theme.paddingXs,
    },
    batchInput: {
        width: '100%',
    },
    description: {
        lineHeight: '150%',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: theme.fontSizeMd,
        marginLeft: theme.paddingXs,
        marginRight: theme.paddingXs,
    },
});

const useStyles = createUseStyles(styles);

const INTERCONNECT_READER_I18N_PREFIX = 'function_config.reader.interconnect';
const GENERIC_I18N_PREFIX = 'function_config.generic';

const InterconnectParameters = ({ node, onUpdate }: ParametersProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const classes = useStyles();
    const intl = useIntl();
    const [form] = Form.useForm();
    const isInStreamEditor = matchPath('/streams/:streamId', location.pathname);
    const streamListState = useStreamListState();
    const [showCopied, setShowCopied] = useState<boolean>(false);
    const accountId: Account['id'] = useSelector(getActiveAccountId);
    const stream: Stream = useSelector(getStreamConfiguration);
    const interconnectName = `${stream.name}/${node.name}`;
    const { connectOrDiscard, interconnectId }: MappedInterconnect = useSelector(getInterconnect(node.id, 'source'));
    const interconnectMetaData: InterconnectMetaData[] = useSelector(
        selectInterconnectMetaData(accountId, interconnectId),
    );
    const isFeatureToggleEnabled: FeatureToggleFn = useSelector(isFeatureEnabled);
    const batchSize: number = node.params.batchSize ?? 1;

    const i18n = (id: string, values?: Record<string, any>): string => intl.formatMessage({ id }, values);

    const onCopy = () => setShowCopied(true);

    const onInterconnectChange = ({ target }: RadioChangeEvent): void =>
        dispatch(connectOrDiscardInterconnect(node.id, target.value));

    const onBatchSizeChange = (value: number): void =>
        onUpdate({
            ...node,
            params: {
                ...node.params,
                batchSize: value,
            },
        });

    useEffect(() => {
        let id = null;

        if (showCopied) {
            navigator.clipboard.writeText(interconnectName);

            id = setTimeout(() => setShowCopied(false), 4000);
        }

        return () => clearTimeout(id);
    }, [showCopied, interconnectName]);

    return (
        <Form form={form} initialValues={{ interconnectName }} layout="vertical">
            <fieldset>
                <legend>
                    <FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.title`} />
                </legend>
                <Form.Item
                    name="interconnectName"
                    label={<FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.name`} />}
                    help={
                        <FormattedMessage
                            id={`${INTERCONNECT_READER_I18N_PREFIX}.info`}
                            values={{
                                newLine: <br />,
                            }}
                        />
                    }
                >
                    <Input
                        readOnly
                        value={interconnectName}
                        addonAfter={
                            <Tooltip
                                arrowPointAtCenter
                                trigger={['hover']}
                                placement="top"
                                title={
                                    <FormattedMessage
                                        id={`${INTERCONNECT_READER_I18N_PREFIX}.tooltip_copy_to_clipboard`}
                                    />
                                }
                            >
                                <CopyOutlined onClick={onCopy} />
                            </Tooltip>
                        }
                    />
                </Form.Item>
                {showCopied && (
                    <div className={classes.successCopy}>
                        <Alert
                            showIcon
                            type="success"
                            message={<FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.copied_success`} />}
                        />
                    </div>
                )}
            </fieldset>
            {isFeatureToggleEnabled('interconnectTransactionBatch') && (
                <fieldset>
                    <legend>
                        <FormattedMessage id={`${GENERIC_I18N_PREFIX}.transaction_batches`} />
                    </legend>
                    <Form.Item label={<FormattedMessage id={`${GENERIC_I18N_PREFIX}.transaction_batches_label`} />}>
                        <InputNumber
                            className={classes.batchInput}
                            size="large"
                            min={1}
                            max={99999}
                            onChange={onBatchSizeChange}
                            value={batchSize}
                            type="number"
                        />
                    </Form.Item>
                    <div className={classes.description}>
                        <FormattedMessage
                            id={`${GENERIC_I18N_PREFIX}.transaction_batches_description`}
                            values={{
                                link: (
                                    <a
                                        aria-labelledby="link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={getDocUrl(
                                            'performance_and_scalability_batching_multiple_files_per_transaction',
                                        )}
                                    >
                                        <FormattedMessage
                                            id="link"
                                            defaultMessage={i18n(
                                                `${GENERIC_I18N_PREFIX}.transaction_batches_description_link`,
                                            )}
                                        />
                                    </a>
                                ),
                            }}
                        />
                    </div>
                </fieldset>
            )}
            {!isInStreamEditor &&
            streamListState?.importStream.type === SetParametersType.REPLACE &&
            interconnectMetaData.length ? (
                <div className={classes.connectOrDiscard}>
                    <h3 className={classes.marginTop}>
                        <FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.collection`} />
                    </h3>
                    <p>
                        <FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.data_info`} />
                    </p>
                    <Radio.Group onChange={onInterconnectChange} value={connectOrDiscard}>
                        <Space direction="vertical">
                            <Radio value="connect">
                                <FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.data_connect`} />
                            </Radio>
                            <Radio value="discard">
                                <FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.data_discard`} />
                            </Radio>
                        </Space>
                    </Radio.Group>
                    <div className={clsx(classes.marginTop, classes.warning)}>
                        <Banner
                            type="warning"
                            message={<FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.warning`} />}
                            description={<FormattedMessage id={`${INTERCONNECT_READER_I18N_PREFIX}.data_warning`} />}
                        />
                    </div>
                </div>
            ) : null}
        </Form>
    );
};

export default InterconnectParameters;
