import React from 'react';
import { get, set } from 'lodash';
import { IntlShape } from 'react-intl';
import { OptionsType, Divider, FormItem, Select } from '@digitalroute-internal/dazzlerjs-react-ui';
import { FunctionType, Node } from '../../../../../dazzler-types';
import { getFunctionValidationMessage } from '../../../../../dazzler-intl';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    treeNodeData: any;
    classes: any;
    i18n: any;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => OptionsType[];
    intl: IntlShape;
    isPreviewClicked: boolean;
};

const SapSubscriptionRequiredField = ({
    node,
    onUpdate,
    functionType,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
    intl,
    isPreviewClicked,
}: Props) => {
    const requiredFields: string[] = get(functionType, 'params.definitions.mapping.required', []);
    const fieldMappings = get(node, 'params.fieldMappings', {});

    const onChangeSelect = (value: string, paramName: string) => {
        if (Object.keys(fieldMappings).length === 0) {
            set(node.params, 'fieldMappings', {});
        }
        set(node.params.fieldMappings, paramName, value);
        onUpdate(node);
    };

    const onAddonChange = (newValue: string, paramName: string) => {
        if (newValue) {
            set(node.params.fieldMappings, paramName, newValue);
            onUpdate(node);
        }
    };

    return (
        <>
            <fieldset className={classes.fieldMappings}>
                <legend>{i18n('required_fields')}</legend>

                {requiredFields.length >= 1 &&
                    requiredFields.map((requiredValue, idx) => {
                        const selectedValue = fieldMappings[requiredValue] ? fieldMappings[requiredValue] : undefined;

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className={classes.formMargin} key={`requiredFieldIdx_${idx}`}>
                                <FormItem label={<div className={classes.formLabel}>{`* ${requiredValue}`}</div>}>
                                    <Select
                                        value={selectedValue}
                                        onChange={(selectValue: string) => onChangeSelect(selectValue, requiredValue)}
                                        size="large"
                                        isTree
                                        optionData={addAddonDataTreeData(selectedValue, treeNodeData)}
                                        allowClear
                                        allowAddon
                                        inputAddonProps={{
                                            isAppend: false,
                                            checkDuplicateBeforeAppend: false,
                                            checkDuplicateValue: true,
                                        }}
                                        onOptionsChange={(_newOptions, newValue) =>
                                            onAddonChange(newValue, requiredValue)
                                        }
                                        status={isPreviewClicked && !selectedValue ? 'error' : undefined}
                                        errorText={
                                            isPreviewClicked && !selectedValue
                                                ? getFunctionValidationMessage(intl, 'required')
                                                : undefined
                                        }
                                        data-testid={`SapSubscriptionRequiredField__Input-${requiredValue}`}
                                    />
                                </FormItem>
                            </div>
                        );
                    })}
            </fieldset>

            <div className={classes.formMargin}>
                <Divider />
            </div>
        </>
    );
};

export default SapSubscriptionRequiredField;
