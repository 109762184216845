import { api } from '../app/api';
import { overrideExisting } from '../app/utils';
import type { Stream } from '../packages/dazzler-types';

export const streamApi = api.injectEndpoints({
    overrideExisting: overrideExisting(),
    endpoints: (builder) => ({
        getStreamById: builder.query<Stream, string>({
            query: (id) => `streams/${id}`,
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { endpoints, useGetStreamByIdQuery } = streamApi;
