import React from 'react';
import { useIntl } from 'react-intl';
import { Node } from '../../../../../dazzler-types';
import TextInputItem from '../../configurator-fields/TextInputItem';

type Props = {
    node: Node;
    onUpdate: (Node: Node) => void;
};

function ZuoraMultiEntityItem({ node, onUpdate }: Props) {
    const intl = useIntl();
    const i18n = (id: string, values?: any, prefix: string = 'function_config.generic.zuora') =>
        intl.formatMessage({ id: `${prefix}.${id}` }, values);

    return (
        <fieldset>
            <legend>{i18n('entity_legend')}</legend>
            <TextInputItem
                label={i18n('entity_label')}
                path="multiEntity"
                node={node}
                onUpdate={onUpdate}
                placeholder=""
                data-testid="zuora_multi_entity_input"
            />
        </fieldset>
    );
}

export default ZuoraMultiEntityItem;
