import React from 'react';
import { get, set, unset } from 'lodash';
import { Checkbox, Form } from 'antd';
import { Divider, RadioGroup, RadioOptionType, Paragraph } from '@digitalroute-internal/dazzlerjs-react-ui';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Node } from '../../../../../dazzler-types';
import { FIRST_VALUE, NumberInputItem, SECOND_VALUE } from '../../configurator-fields';

type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    classes: any;
    i18n: (id: string, value?: any) => any;
};

const IGNORE = FIRST_VALUE;
const STOP_THE_STREAM = SECOND_VALUE;
const TEST_ID = 'ZuoraUsageRecordOperationalSettings';
const errorModeParam = 'errorMode';

function ZuoraUsageRecordOperationalSettings({ node, i18n, onUpdate, classes }: Props) {
    const { shouldRetry, retries = 1 } = node.params;
    const totalDelay = retries === 1 ? 1 : 2 ** retries;

    const radioOptions: Array<RadioOptionType> = [
        {
            label: i18n('advanced_error_first'),
            value: IGNORE,
        },
        {
            label: i18n('advanced_error_second'),
            value: STOP_THE_STREAM,
        },
    ];

    const boldText = (text: string) => <b>{text}</b>;

    const onShouldRetryChange = (e: CheckboxChangeEvent): void => {
        set(node.params, 'shouldRetry', e.target.checked);
        if (!e.target.checked) {
            unset(node.params, 'retries');
            unset(node.params, errorModeParam);
        } else {
            set(node.params, errorModeParam, 'emit');
        }
        onUpdate(node);
    };

    const onUpdatePath = (path: string) => (updatedNode: Node) => {
        const value = get(updatedNode.params, path);
        if (!value) {
            unset(updatedNode.params, path);
        }

        onUpdate(updatedNode);
    };

    const toggleActionOnError = (val: string): void => {
        set(node.params, errorModeParam, val === IGNORE ? 'emit' : 'defer');
        onUpdate(node);
    };

    return (
        <>
            <legend>{i18n('operational_settings_legend')}</legend>
            <div className={classes.retryContainer}>
                <Checkbox
                    checked={shouldRetry}
                    onChange={onShouldRetryChange}
                    className={classes.checkbox}
                    data-testid={`${TEST_ID}-CheckBox`}
                >
                    <Paragraph isDescription isBold={false} text={i18n('retry_on_fail')} />
                </Checkbox>
                {shouldRetry && (
                    <div className={classes.flexColumn}>
                        <span className={classes.bannerContainer}>
                            {i18n('operational_options.description', { bold: boldText })}
                        </span>
                        <div className={classes.fontSizeContainer}>
                            <NumberInputItem
                                required
                                label={i18n('retries_number')}
                                min={1}
                                max={12}
                                node={node}
                                path="retries"
                                suffix={i18n('retry_times', { retries })}
                                onUpdate={onUpdatePath('retries')}
                                data-testid={`${TEST_ID}-RetryNumber`}
                            />
                        </div>
                        <div className={classes.bottomFieldPadding}>
                            <span className={classes.retryDescription} data-testid={`${TEST_ID}-RetryDescription`}>
                                {totalDelay &&
                                    i18n('retry_description', {
                                        totalDelay,
                                        bold: boldText,
                                    })}
                            </span>
                        </div>

                        <Form.Item label={i18n('advanced_error_label')}>
                            <div className={classes.radioGroupContainer}>
                                <RadioGroup
                                    onChange={toggleActionOnError}
                                    options={radioOptions}
                                    selectedValue={node.params.errorMode === 'defer' ? STOP_THE_STREAM : IGNORE}
                                    defaultValue={IGNORE}
                                    data-testid={`${TEST_ID}-ErrorHandlingRadio`}
                                    isVertical
                                />
                            </div>
                        </Form.Item>
                    </div>
                )}
            </div>
            <div className={classes.formMargin}>
                <Divider />
            </div>
        </>
    );
}

export default ZuoraUsageRecordOperationalSettings;
