import React from 'react';
import { Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { createUseStyles } from 'react-jss';
import { set, get, unset } from 'lodash';
import { Switch } from '@digitalroute-internal/dazzlerjs-react-ui';

import { Node } from '../../../../../dazzler-types';
import { CodeConfigurator } from '../../../../../generic-configurator';
import TextInputItem from '../../configurator-fields/TextInputItem';
import ActionOnError from './ZuoraEnum';

const I18N_GENERIC_PREFIX = 'function_config.generic.zuora';
const I18N_ZUORA_QUERY_PREFIX = 'function_config.transforms.zuora_query';

const DATA_QUERY_ENABLED_PATH = 'useDataQuery';
const TEST_ID = 'zuora_data_query_item';

const styles = (theme: Record<string, any>) => ({
    grid: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    rightSection: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginRight: theme.marginXs,
    },
    rightElement: {
        marginRight: theme.marginXs,
    },
    genericFormItem: {
        marginLeft: theme.marginXs,
        marginBottom: theme.marginXs,
        fontSize: '14px',
        color: theme.neutral04,
        marginTop: -10,
    },
    required: {
        color: theme.brand05,
        marginLeft: theme.marginXss,
    },
    queryBody: {
        marginLeft: theme.marginXss,
    },
    queryBodyWrapper: {
        marginTop: -2,
        marginLeft: theme.marginXss,
        marginBottom: theme.marginXss,
    },
});

const useStyles = createUseStyles(styles);

type Props = {
    node: Node;
    inputData?: Array<any>;
    onUpdate: (node: Node) => void;
    i18n: (id: string, prefix?: string, values?: any) => string;
};

function ZuoraDataQueryItem({ node, inputData, onUpdate, i18n }: Props) {
    const classes = useStyles();

    const { enableResultHeaders, resultKey, resultHeadersKey } = node.params;

    const enableResultHeadersChange = (e: CheckboxChangeEvent) => {
        set(node.params, 'enableResultHeaders', e.target.checked);

        if (!e.target.checked) unset(node.params, 'resultHeadersKey');

        onUpdate(node);
    };

    const setDataQuery = (value: boolean) => {
        set(node.params, DATA_QUERY_ENABLED_PATH, value);

        if (!value) {
            unset(node.params, 'maxWaitTimeoutSeconds');
        } else {
            set(node.params, 'actionOnError', ActionOnError.IGNORE_ERROR);
        }

        onUpdate(node);
    };

    const onUpdatePath = (path: string) => () => {
        const value = get(node.params, path);
        if (!value) {
            unset(node.params, path);
        }
        onUpdate(node);
    };

    const isDataQueryEnabled = get(node.params, DATA_QUERY_ENABLED_PATH, true);

    return (
        <>
            <fieldset>
                <div className={classes.grid}>
                    <legend>{i18n('query', I18N_GENERIC_PREFIX)}</legend>
                    <div className={classes.rightSection}>
                        <Switch
                            onChange={setDataQuery}
                            checked={isDataQueryEnabled}
                            overrideClass={classes.rightElement}
                            data-testid={`${TEST_ID}_data_query_switch`}
                        />
                        {i18n('use_data_query', I18N_GENERIC_PREFIX)}
                    </div>
                </div>
                <div className={classes.queryBodyWrapper}>
                    <span className={classes.required}>*</span>
                    <span className={classes.queryBody}>{i18n('query_body_label', I18N_ZUORA_QUERY_PREFIX)}</span>
                </div>
                <CodeConfigurator
                    height="250px"
                    language="zoql"
                    inputData={inputData}
                    node={node}
                    path="query"
                    themeColor="light"
                    onUpdate={onUpdate}
                    options={{
                        glyphMargin: false,
                        guides: {
                            indentation: false,
                        },
                        lineDecorationsWidth: 0,
                        lineNumbersMinChars: 3,
                        minimap: { enabled: false },
                    }}
                    data-testid={`${TEST_ID}_query_input`}
                />
                <TextInputItem
                    label={i18n('result_key_label', I18N_GENERIC_PREFIX)}
                    path="resultKey"
                    node={node}
                    onUpdate={onUpdatePath('resultKey')}
                    data-testid={`${TEST_ID}_query_result_input`}
                />
                {!resultKey && (
                    <div className={classes.genericFormItem}>{i18n('query_result_info', I18N_ZUORA_QUERY_PREFIX)}</div>
                )}
                <Form.Item>
                    <Checkbox defaultChecked={false} checked={enableResultHeaders} onChange={enableResultHeadersChange}>
                        {i18n('enable_result_headers_label', I18N_ZUORA_QUERY_PREFIX)}
                    </Checkbox>
                </Form.Item>
                {enableResultHeaders && (
                    <>
                        <TextInputItem
                            label="Result headers key"
                            path="resultHeadersKey"
                            node={node}
                            onUpdate={onUpdatePath('resultHeadersKey')}
                            data-testid={`${TEST_ID}_query_result_headers_input`}
                        />
                        <div hidden={resultHeadersKey} className={classes.genericFormItem}>
                            {i18n('query_result_headers_info', I18N_ZUORA_QUERY_PREFIX)}
                        </div>
                    </>
                )}
            </fieldset>
        </>
    );
}

export default ZuoraDataQueryItem;
