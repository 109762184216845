import React, { useEffect, useState } from 'react';
import { get, set } from 'lodash';
import { IntlShape } from 'react-intl';
import {
    Button,
    FormItem,
    icons,
    FieldMapping,
    OptionProp,
    OptionsType,
} from '@digitalroute-internal/dazzlerjs-react-ui';
import { Node } from '../../../../../dazzler-types';
import { getFunctionValidationMessage } from '../../../../../dazzler-intl';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    treeNodeData: any;
    classes: any;
    i18n: any;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => OptionsType[];
    intl: IntlShape;
    isPreviewClicked: boolean;
};

const TESTID_PREFIX = 'SapSubscriptionCustomReference';

const SapSubscriptionCustomReference = ({
    node,
    onUpdate,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
    intl,
    isPreviewClicked,
}: Props) => {
    const initCustomReference = get(node, 'params.customReferences', []);
    const [customReference, setCustomReference] = useState(initCustomReference);

    useEffect(() => {
        set(node.params, 'customReferences', customReference);
        onUpdate(node);
    }, [customReference]);

    const onClickAddCustomReference = () => {
        setCustomReference((prevCustomRef) => [...prevCustomRef, {}]);
    };

    const onChangeSelectCustomReference = (idx: number, val: string, paramName: string) => {
        const newCustRef = customReference;
        newCustRef[idx][paramName] = val;
        setCustomReference([...newCustRef]);
    };

    const onClickDeleteCustomReference = (idx: number) => {
        setCustomReference((prevCustomRef) => prevCustomRef.filter((custRef, i) => i !== idx));
    };

    return (
        <div className={classes.formItemContainer}>
            <FormItem>
                <legend>{i18n('custom_reference_fields')}</legend>

                {customReference.map((arrVal, idx) => {
                    // eslint-disable-next-line consistent-return
                    const validateInput = (isTarget: boolean, currentValue, relatedValue) => {
                        // only validate when no current value
                        if (isPreviewClicked && !currentValue) {
                            // related value is not set, show required message
                            if (!relatedValue) {
                                return getFunctionValidationMessage(intl, 'required');
                            }

                            // related value IS set, show related message
                            const validationObj = {
                                currentFieldName: isTarget
                                    ? i18n('custom_reference_typecode')
                                    : i18n('custom_reference_id'),
                                relatedFieldName: isTarget
                                    ? i18n('custom_reference_id')
                                    : i18n('custom_reference_typecode'),
                            };
                            return getFunctionValidationMessage(intl, 'related', validationObj);
                        }
                    };
                    const options: OptionProp[] = [
                        {
                            name: 'custom_reference_typecode',
                            label: `* ${i18n('custom_reference_typecode')}`,
                            isSelect: true,
                            selectOption: {
                                isTree: true,
                                optionData: addAddonDataTreeData(arrVal.typeCode, treeNodeData),
                                value: arrVal.typeCode,
                                allowAddon: true,
                                allowClear: true,
                                inputAddonProps: {
                                    isAppend: false,
                                    checkDuplicateBeforeAppend: false,
                                    checkDuplicateValue: true,
                                },
                                onChange: (val: string) => onChangeSelectCustomReference(idx, val, 'typeCode'),
                                onOptionsChange: (_newOptions, newValue) => {
                                    if (newValue) onChangeSelectCustomReference(idx, newValue, 'typeCode');
                                },
                                'data-testid': `${TESTID_PREFIX}__Select-customReferenceTypeCode-${idx}`,
                            },
                            hasError: !!validateInput(true, arrVal.typeCode, arrVal.id),
                            errorMessage: validateInput(true, arrVal.typeCode, arrVal.id),
                        },
                        {
                            name: 'custom_reference_id',
                            label: `* ${i18n('custom_reference_id')}`,
                            isSelect: true,
                            selectOption: {
                                isTree: true,
                                optionData: addAddonDataTreeData(arrVal.id, treeNodeData),
                                value: arrVal.id,
                                allowAddon: true,
                                allowClear: true,
                                inputAddonProps: {
                                    isAppend: false,
                                    checkDuplicateBeforeAppend: false,
                                    checkDuplicateValue: true,
                                },
                                onChange: (val: string) => onChangeSelectCustomReference(idx, val, 'id'),
                                onOptionsChange: (_newOptions, newValue) => {
                                    if (newValue) onChangeSelectCustomReference(idx, newValue, 'id');
                                },
                                'data-testid': `${TESTID_PREFIX}__Select-customReferenceId-${idx}`,
                            },
                            hasError: !!validateInput(false, arrVal.id, arrVal.typeCode),
                            errorMessage: validateInput(false, arrVal.id, arrVal.typeCode),
                        },
                    ];
                    return (
                        <FieldMapping
                            // eslint-disable-next-line react/no-array-index-key
                            key={`custRefIdx_${idx}`}
                            options={options}
                            onDelete={() => onClickDeleteCustomReference(idx)}
                        />
                    );
                })}

                <div className={classes.spacingTop}>
                    <Button
                        type="dashed"
                        text={i18n('add_custom_reference')}
                        icon
                        iconName={icons.regular.faPlus}
                        onClick={() => onClickAddCustomReference()}
                        data-testid={`${TESTID_PREFIX}__Button-addCustomReference`}
                    />
                </div>
            </FormItem>
        </div>
    );
};

export default SapSubscriptionCustomReference;
