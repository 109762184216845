const TAG_COLOR_MAPPING = {
    '0': 'blue',
    '1': 'green',
    '2': 'yellow',
    '3': 'purple',
    '4': 'red',
};

const colorCounterFunc = () => {
    let count = 0;
    const categoryStore = {};
    return {
        getColor: (category) => {
            if (categoryStore[category] || categoryStore[category] === 0) {
                return TAG_COLOR_MAPPING[categoryStore[category]];
            }
            if (count > 4) {
                categoryStore[category] = Number(0);
                count = 0;
                return TAG_COLOR_MAPPING[count];
            }
            categoryStore[category] = Number(count);
            return TAG_COLOR_MAPPING[count++]; // eslint-disable-line
        },
    };
};

export const colorCounter = colorCounterFunc();
