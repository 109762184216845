import { createAction } from 'redux-actions';
import type { ModalPayload } from '../../types';

export const actions = [
    'delete',
    'notifications',
    'schedule',
    'abortSchedule',
    'rename',
    'create',
    'import',
    'importReplace',
    'createGroup',
    'export',
    'chooseReplicas',
    'setParameters',
] as const;

export const toggleModal = createAction<ModalPayload>('toggleModal');
