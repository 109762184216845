import { createHeaders } from './fetch';
import { API_URL, SUPPORTED_ERROR_CODES } from '../api/settings';
import { unauthorized } from './actions';
import { sec } from '../../../app/security';
import { showErrorNotification } from '../../../app/api';

type Method = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

// eslint-disable-next-line
export async function fetchWrapper({
    uri,
    intl,
    method = 'GET',
    functionDispatch,
    classDispatch,
    body,
}: {
    uri: string;
    intl: any;
    method?: Method;
    body?: any;
    classDispatch?: any;
    functionDispatch?: any;
}): Promise<any> {
    if (uri[0] !== '/') {
        uri = `/${uri}`;
    }
    const userSession = await sec.getUserSession();

    if (functionDispatch) {
        if (!userSession) {
            functionDispatch({ type: unauthorized.toString() });
            return '';
        }
    } else if (!userSession) {
        classDispatch(unauthorized.toString());
        return '';
    }

    const res = await fetch(`${API_URL}${uri}`, {
        headers: await createHeaders(),
        method,
        ...(body && { body: JSON.stringify(body) }),
    });

    if (res.status !== 204) {
        const jsonBody = await res.json();
        if (SUPPORTED_ERROR_CODES.includes(res.status)) {
            showErrorNotification(jsonBody, res.status, intl);
            throw Error();
        }
        return jsonBody;
    }
    return '';
}
