// This file contains default function configs for writer functions.
import { writers } from '../../dazzler-constants';
import { ZuoraActiveRatingEnum } from '../registry/functions/common/zuora/ZuoraEnums';
import {
    KafkaProducerTopicSettingEnum,
    KafkaProducerPartitionSettingEnum,
    DEFAULT_PORT,
} from '../registry/functions/common/kafka/KafkaEnum';

const defaultConfig = {
    [writers.azureBlobStorage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'azure',
        },
    },
    /*
    [writers.email2]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'default'
        }
    },
    */
    [writers.database]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'database',
        },
    },
    [writers.googleCloudStorage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'google',
        },
    },
    [writers.gotransverseUsage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'gotransverse',
        },
    },
    [writers.revenueCloud]: {
        region: 'eu10',
    },
    [writers.s3]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'aws',
        },
    },
    [writers.sftp]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'sftp',
        },
    },
    [writers.zuoraPostUsage]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'zuora',
        },
        zuoraApiEndpoint: ZuoraActiveRatingEnum.DISABLED,
        useInterconnect: true,
    },
    [writers.zuoraUsageFile]: {
        secretsWalletEntry: {
            secretId: undefined,
            secretType: 'zuora',
        },
        useInterconnect: false,
    },
    [writers.kafkaProducer]: {
        topicSetting: KafkaProducerTopicSettingEnum.LIST_TOPICS,
        partitionSetting: KafkaProducerPartitionSettingEnum.ROUND_ROBIN,
        brokers: [{ port: DEFAULT_PORT }],
    },
    [writers.sapHana]: {
        port: '30015',
    },
};

export default defaultConfig;
