import React from 'react';
import { get, set } from 'lodash';
import { Divider, FormItem, Select, OptionsType } from '@digitalroute-internal/dazzlerjs-react-ui';
import { FunctionType, Node } from '../../../../../dazzler-types';

export type Props = {
    node: Node;
    onUpdate: (node: Node) => void;
    functionType: FunctionType;
    treeNodeData: any;
    classes: any;
    i18n: (id: string, values?: string) => string;
    addAddonDataTreeData: (valueToFind: string, treeNodeData: OptionsType[]) => any;
    pathMapping: any;
};

const TEST_ID = 'ZuoraUsageRecordRequiredField';

const ZuoraUsageRecordRequiredField = ({
    node,
    onUpdate,
    functionType,
    treeNodeData,
    classes,
    i18n,
    addAddonDataTreeData,
    pathMapping,
}: Props) => {
    const requiredFields: string[] = get(functionType, pathMapping[node.params.zuoraApiEndpoint].required, []);
    let fieldMappings = get(node, pathMapping[node.params.zuoraApiEndpoint].fieldMappings, {});
    const onChangeSelect = (value: string, paramName: string) => {
        if (Object.keys(fieldMappings).length === 0) {
            set(node, pathMapping[node.params.zuoraApiEndpoint].fieldMappings, {});
            fieldMappings = get(node, pathMapping[node.params.zuoraApiEndpoint].fieldMappings);
        }
        set(fieldMappings, paramName, value);
        onUpdate(node);
    };

    const onAddonChange = (newValue: string, paramName: string) => {
        if (newValue) {
            set(node.params.fieldMappings, paramName, newValue);
            onUpdate(node);
        }
    };

    return (
        <>
            <legend className={classes.legendMargin}>{i18n('required_fields')}</legend>

            <div className={classes.bottomFieldPadding} data-testid={`${TEST_ID}-Container`}>
                {requiredFields.length >= 1 &&
                    requiredFields.map((requiredValue) => {
                        const selectedValue = fieldMappings[requiredValue] ? fieldMappings[requiredValue] : undefined;
                        return (
                            <div className={classes.formMargin} key={`requiredFieldIdx_${requiredValue}`}>
                                <FormItem label={requiredValue} required>
                                    <Select
                                        value={selectedValue}
                                        onChange={(selectValue: string) => onChangeSelect(selectValue, requiredValue)}
                                        size="large"
                                        isTree
                                        allowClear
                                        allowAddon
                                        allowDelete
                                        placeholder=""
                                        optionData={addAddonDataTreeData(selectedValue, treeNodeData)}
                                        onOptionsChange={(_newOptions, newValue) =>
                                            onAddonChange(newValue, requiredValue)
                                        }
                                        data-testid={`${TEST_ID}-Select-${requiredValue}`}
                                    />
                                </FormItem>
                            </div>
                        );
                    })}
            </div>

            <Divider overrideClass={classes.dividerOverride} />
        </>
    );
};

export default ZuoraUsageRecordRequiredField;
