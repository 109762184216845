export default async function digestMessage(message: string): Promise<string> {
    const { hostname, protocol } = document.location;

    if (`${protocol}//${hostname}` !== 'http://dazzler.minikube') {
        // crypto.subtle.digest only avalible in https
        const msgUint8 = new TextEncoder().encode(message); // encode as utf-8
        const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    }

    return message;
}
